<template>
  <div>
    <section class="py-5">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 col-lg-8 col-xxl-7 text-center mx-auto mb-4">
            <h2 class="display-5 fw-bold mt-2 mb-3">FAQs</h2>
            <p class="lead text-muted">
              Below are the most common questions we receive. If you have a
              question not listed below, please email
              <a href="mailto:support@browncowtravel.com"
                >support@browncowtravel.com</a
              >
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mx-auto">
            <div class="accordion accordion-flush" id="faq01">
              <div class="accordion-item">
                <h2 class="accordion-header" id="faq01-header1">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq01-answer1"
                    aria-expanded="false"
                    aria-controls="faq01-answer1"
                  >
                    COVID INFORMATION
                  </button>
                </h2>
                <div
                  class="accordion-collapse collapse"
                  id="faq01-answer1"
                  aria-labelledby="faq01-header1"
                  data-bs-parent="#faq01"
                >
                  <div class="accordion-body">
                    Due to the changing world situation caused by the
                    coronavirus (COVID-19) pandemic, many countries have varying
                    requirements for entry and many airlines continue to offer
                    free changes on future flights so travelers can book with
                    confidence. Before booking and regularly before traveling,
                    you should always verify facts with the airline, insurance
                    provider, or a reputable source like the
                    <a
                      href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/question-and-answers-hub/q-a-detail/coronavirus-disease-covid-19"
                      target="_blank"
                      >World Health Organization</a
                    >.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq01-answer2"
                    aria-expanded="false"
                    aria-controls="faq01-answer2"
                  >
                    What is Brown Cow Travel?
                  </button>
                </h2>
                <div
                  class="accordion-collapse collapse"
                  id="faq01-answer2"
                  aria-labelledby="faq01-header2"
                  data-bs-parent="#faq01"
                >
                  <div class="accordion-body">
                    Brown Cow Travel is your 24x7 travel agent working round the
                    clock for you and constantly checking the price of flights
                    to your favorite destinations in the months you want to go
                    there. Unless you want to never sleep or eat or go to a job
                    or school, etc. then it is impossible to catch all these
                    incredible fare deals. These deals pop up when unique
                    conditions (and sometimes pricing mistakes) occur on
                    everyday flights. When we find an incredible flight price,
                    we send it directly to you for you to consider and book if
                    you like. In many cases, you’ll save hundreds of dollars
                    over the normal flight cost.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq01-answer3"
                    aria-expanded="false"
                    aria-controls="faq01-answer3"
                  >
                    Why do you have a membership fee?
                  </button>
                </h2>
                <div
                  class="accordion-collapse collapse"
                  id="faq01-answer3"
                  aria-labelledby="faq01-header3"
                  data-bs-parent="#faq01"
                >
                  <div class="accordion-body">
                    To feed the cows, of course! Seriously though, our
                    sophisticated software runs 24 hours a day 365 days a year
                    checking prices for your specific routes and that costs us
                    big money. So that we can provide you with ONLY the
                    information you are interested in, we don’t collect money
                    from advertisers and spam your inbox with unrelated offers.
                    Everything we send you is based on your specific searches
                    and related flights. We have done everything we can to make
                    the price of our service affordable and we hope that the
                    savings you make on your very first flight will more than
                    pay for the full year of Brown Cow Travel.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq01-answer4"
                    aria-expanded="false"
                    aria-controls="faq01-answer4"
                  >
                    What membership plans are available?
                  </button>
                </h2>
                <div
                  class="accordion-collapse collapse"
                  id="faq01-answer4"
                  aria-labelledby="faq01-header4"
                  data-bs-parent="#faq01"
                >
                  <div class="accordion-body">
                    We offer two membership plans for you to choose from based
                    on how many places you like to travel and your seat
                    preferences. Both plans are very affordable, offer flat
                    monthly fees, and our goal is that your savings will more
                    than pay for these fees. The details of our plans are on the home page.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq01-answer6"
                    aria-expanded="false"
                    aria-controls="faq01-answer5"
                  >
                    How do I setup my favorite routes to tell you what deals I
                    want to see?
                  </button>
                </h2>
                <div
                  class="accordion-collapse collapse"
                  id="faq01-answer6"
                  aria-labelledby="faq01-header5"
                  data-bs-parent="#faq01"
                >
                  <div class="accordion-body">
                    During the signup process you will tell us the departure
                    airport, destination airport, month(s) you would like to
                    travel, and max price you want to pay for as many trips as 
                    your membership plan allows.  We call these entries Saved Routes. If
                    you wish to monitor more routes, you can simply upgrade your
                    plan to watch more. At any time during your membership, you
                    can adjust your Saved Routes to add new ones and/or delete
                    any you are no longer interested in on the Saved Routes page.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq01-answer7"
                    aria-expanded="false"
                    aria-controls="faq01-answer5"
                  >
                    What types of deals will you send me?
                  </button>
                </h2>
                <div
                  class="accordion-collapse collapse"
                  id="faq01-answer7"
                  aria-labelledby="faq01-header5"
                  data-bs-parent="#faq01"
                >
                  <div class="accordion-body">
                    For each Saved Route, we will send you flights that are at or below 
                    the max price that you want to pay.  We search across almost every major
                    airline including "budget" airlines.  Since you only specify the month(s) of travel,
                    we search across numerous flight dates within the month and show you the best price.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq01-answer8"
                    aria-expanded="false"
                    aria-controls="faq01-answer5"
                  >
                    How do I book a deal you send me?
                  </button>
                </h2>
                <div
                  class="accordion-collapse collapse"
                  id="faq01-answer8"
                  aria-labelledby="faq01-header5"
                  data-bs-parent="#faq01"
                >
                  <div class="accordion-body">
                    To book any deal sent to you, you’ll want to click
                    the “Book This Flight Now” button on the deal detail page. This
                    button will take you to a page that shows you various booking agents, 
                    their individual prices for that flight and you can book with your preferred
                    agent.  It is important to keep in mind that prices do change quickly so the 
                    price we show you is available at the time we post it but we cannot guarantee 
                    how long it will be available.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq01-answer9"
                    aria-expanded="false"
                    aria-controls="faq01-answer5"
                  >
                    Do you send Business and First-Class seat deals as well?
                  </button>
                </h2>
                <div
                  class="accordion-collapse collapse"
                  id="faq01-answer9"
                  aria-labelledby="faq01-header5"
                  data-bs-parent="#faq01"
                >
                  <div class="accordion-body">
                    Some of our plans, but not all of them, monitor Business and
                    First-Class seat prices for you and send you those deals.
                    Check the details of our plans for this feature on our home
                    page.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq01-answer10"
                    aria-expanded="false"
                    aria-controls="faq01-answer5"
                  >
                    How do I reset my password?
                  </button>
                </h2>
                <div
                  class="accordion-collapse collapse"
                  id="faq01-answer10"
                  aria-labelledby="faq01-header5"
                  data-bs-parent="#faq01"
                >
                  <div class="accordion-body">
                    To reset a lost password, go to the Login page and just below the password box click the link for Forgot Password.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq01-answer11"
                    aria-expanded="false"
                    aria-controls="faq01-answer5"
                  >
                    How do I change plans, change my payment information or cancel my subscription?
                  </button>
                </h2>
                <div
                  class="accordion-collapse collapse"
                  id="faq01-answer11"
                  aria-labelledby="faq01-header5"
                  data-bs-parent="#faq01"
                >
                  <div class="accordion-body">
                    To change your plan or cancel your subscription, log in and use the 
                    menu to go to the Billing page.  On the billing page, it will show your 
                    selected plan.  You can click on another available plan to change plans or 
                    you can click the Subscription Portal button at the bottom of the page 
                    to change your payment method or cancel your plan renewal.  Since we bill up 
                    front for the year, your subscription will stay active until the anniversary date 
                    but once cancelled it will not auto-renew your subscription.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq01-answer12"
                    aria-expanded="false"
                    aria-controls="faq01-answer5"
                  >
                    What is your refund policy?
                  </button>
                </h2>
                <div
                  class="accordion-collapse collapse"
                  id="faq01-answer12"
                  aria-labelledby="faq01-header5"
                  data-bs-parent="#faq01"
                >
                  <div class="accordion-body">
                    To change your plan or cancel your subscription, log in and use the 
                    menu to go to the Billing page.  On the billing page, it will show your 
                    selected plan.  You can click on another available plan to change plans or 
                    you can click the Subscription Portal button at the bottom of the page 
                    to change your payment method or cancel your plan renewal.  Since we bill up 
                    front for the month, your subscription will stay active until the monthly anniversary date 
                    but once cancelled it will not auto-renew your subscription.
                    date.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
$primary: #000000;
$secondary: #ff3647;
.accordion-button {
  background: rgba($color: $secondary, $alpha: 0.5) !important;
  color: rgba($color: $primary, $alpha: 0.8) !important;
  font-weight: 600;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%5f4635'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-button[aria-expanded="true"] {
  background: rgba($color: $secondary, $alpha: 1) !important;
  color: white !important;
}
.accordion-button[aria-expanded="true"]::after {
  background-image: url("../assets/images/chevron-down.png") !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}
</style>
