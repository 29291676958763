<template>
  <div class="container mx-auto py-3 py-sm-4">
    <div class="row mx-auto">
      <div class="col-lg-6 col-12 mx-auto">
        <form id="payment-form" @submit.prevent="completeCheckout">
          <div id="payment-element">
            <!-- Elements will create form elements here -->
          </div>
          <!-- <button  ></button> -->
          <button id="submit" type="submit" class="mt-3 px-4 btn btn-primary">
            Subscribe
          </button>
          <div id="error-message">
            {{ errorMessage }}
          </div>
        </form>
        <p>{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { loadScript } from "vue-plugin-load-script";
export default {
  data() {
    return {
      stripekey: "",
      stripe: {},
      elements: {},
      errorMessage: "",
      message: "",
    };
  },

  created() {
    this.stripekey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
  },

  async mounted() {
    await loadScript("https://js.stripe.com/v3/");
    const options = {
      clientSecret: this.$store.state.clientSecret,
    };

    // eslint-disable-next-line no-undef
    this.stripe = Stripe(this.stripekey);
    this.elements = this.stripe.elements(options);

    // Create and mount the Payment Element
    const paymentElement = this.elements.create("payment");
    paymentElement.mount("#payment-element");

    if (this.$route.query.clientSecret) {
      this.stripe
        .retrievePaymentIntent(this.$route.query.clientSecret)
        .then(({ paymentIntent }) => {
          switch (paymentIntent.status) {
            case "succeeded":
              this.message = "Success! Payment received.";
              break;
          }
        });
    }
  },
  methods: {
    async completeCheckout() {
      const data = await this.stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements: this.elements,
        confirmParams: {
          return_url: `${process.env.VUE_APP_RETURN_URL}?subscriptionId=${this.$store.state.subscriptionId}&step=4&package=${this.$route.query.package}`,
        },
      });

      if (data.error) {
        this.errorMessage = data.error.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-btn {
  color: rgba(121, 52, 25, 0.74) !important;
}
.select-btn:hover {
  color: rgba(189, 81, 38, 0.74) !important;
}
#error-message {
  padding-top: 10px;
  color: rgba(189, 81, 38, 1) !important;
  font-weight: bold;
}
</style>
