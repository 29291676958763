<template>
  <div
    class="userprofile container-fluid"
    style="center"
    v-if="$auth.isAuthenticated"
  >
    <div class="row">
      <div class="col-lg-3 col-12 d-none d-md-block">
        <div class="sidebar">
          <div
            v-for="(link, i) in links"
            :key="i"
            :class="[link.mainclass, { active: link.component == current }]"
            @click="changeComponent(link.component)"
          >
            <div
              class="d-flex align-items-baseline"
              :class="[link.icontextcontainerclass]"
            >
              <div class="sidebar__iconContainer">
                <font-awesome-icon
                  class="sidebar__icon"
                  :icon="['fas', link.icon]"
                />
              </div>
              <p class="m-0 sidebar__text">{{ link.text }}</p>
            </div>
            <div class="sidebar__statusContainer" v-if="link.status">
              <p
                class="m-0 sidebar__statusContainer__status"
                v-if="link.status"
              >
                {{ link.status }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-12">
        <component :is="current"></component>
      </div>
    </div>
  </div>
</template>

<script>
// import { getInstance } from "@/auth/authWrapper.js";
// import axios from "axios";
import Profile from "@/components/user/profile.vue";
import Routes from "@/components/user/routes.vue";
import Notifications from "@/components/user/notifications.vue";
import Billing from "@/components/user/billing.vue";
import Share from "@/components/user/share.vue";
import Faq from "@/components/user/faq.vue";

export default {
  components: { Profile, Routes, Notifications, Billing, Share, Faq },
  data() {
    return {
      current: "Routes",
      links: [
        {
          icon: "user-circle",
          text: "Your Profile",
          mainclass: "sidebar__linkContainer",
          component: "Profile",
        },
        {
          icon: "plane",
          text: "Saved Routes",
          mainclass: "sidebar__linkContainer",
          component: "Routes",
        },

        {
          icon: "bell",
          text: "Notifications",
          mainclass: "sidebar__linkContainer",
          component: "Notifications",
        },
        {
          icon: "credit-card",
          text: "Billing",
          mainclass: "sidebar__linkContainer",
          component: "Billing",
        },
        {
          icon: "heart",
          text: "Share the Love",
          mainclass: "sidebar__linkContainer",
          component: "Share",
        },

        {
          icon: "info-circle",
          text: "Support",
          mainclass: "sidebar__linkContainer",
          component: "Faq",
        },

        {
          icon: "sign-out-alt",
          text: "Logout",
          mainclass: "sidebar__linkContainer",
          component: null,
        },
      ],
    };
  },
  mounted() {
    if (
      this.$route.query &&
      this.$route.query.name &&
      this.$route.query.name.includes("savedroutes")
    ) {
      this.current = "Routes";
    } else if (
      this.$route.query &&
      this.$route.query.name &&
      this.$route.query.name.includes("share")
    ) {
      this.current = "Share";
    } else if (
      this.$route.query &&
      this.$route.query.name &&
      this.$route.query.name.includes("profile")
    ) {
      this.current = "Profile";
    } else if (
      this.$route.query &&
      this.$route.query.name &&
      this.$route.query.name.includes("notifications")
    ) {
      this.current = "Notifications";
    } else if (
      this.$route.query &&
      this.$route.query.name &&
      this.$route.query.name.includes("billing")
    ) {
      this.current = "Billing";
    } else if (
      this.$route.query &&
      this.$route.query.name &&
      this.$route.query.name.includes("faq")
    ) {
      this.current = "Faq";
    }

    let nameQuery = this.$route.query.name;

    this.$router.replace({
      path: "/user",
      query: {
        name: nameQuery,
        subMax: this.$auth.user["https://app.browncowtravel.com/SubMax"],
      },
    });

    console.log();
  },
  watch: {
    "$store.state.current": {
      deep: true,
      handler(value) {
        this.current = value;
      },
    },
    "$route.query"(value) {
      if (value) {
        if (value.name) {
          if (value.name.includes("savedroutes")) {
            this.current = "Routes";
          } else if (value.name.includes("share")) {
            this.current = "Share";
          } else if (value.name.includes("profile")) {
            this.current = "Profile";
          } else if (value.name.includes("notifications")) {
            this.current = "Notifications";
          } else if (value.name.includes("billing")) {
            this.current = "Billing";
          } else if (value.name.includes("faq")) {
            this.current = "Faq";
          }
        }
        let nameQuery = this.$route.query.name;
        this.$router.replace({
          path: "/user",
          query: {
            name: nameQuery,
            subMax: this.$auth.user["https://app.browncowtravel.com/SubMax"],
          },
        });
      }
    },
  },
  methods: {
    changeComponent(comp) {
      if (comp == null) {
        localStorage.removeItem("step");
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("vuex");
        localStorage.removeItem("_secure__ls__metadata");
        localStorage.removeItem("allowedsaves");
        this.$auth.logout();
        this.$router.push({ path: "/" });
      }
      if (comp) {
        this.current = comp;
        this.$store.commit("setCurrent", comp);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$color1: #ff3647;
// .userprofile {
//     background: #dfe3ec;
// }
.sidebar {
  border-radius: 8px;
  padding: 30px 30px;
  box-shadow: 0px 0px 3px 1px #dfe3ec;
  margin: 30px 0;
  &__text {
    color: #5f4635;
    font-size: 1.2rem;
    padding-left: 10px;
    font-weight: 600;
    @media (max-width: 1199px) {
      font-size: 1.1rem;
      padding-left: 16px;
    }
  }
  &__icon {
    color: #5f4635;
    font-size: 1.2rem;
  }

  &__linkContainer {
    padding-bottom: 20px;
    cursor: pointer;
    position: relative;
    transition: all 0.5s;
  }
  &__linkContainer:hover &__text,
  &__linkContainer:hover &__icon {
    color: $color1 !important;
  }
  &__iconTextContainer {
    flex-basis: 90%;
    max-width: 90%;
  }

  &__iconTextContainer.active {
    color: $color1;
    transition: all 0.5s;
  }
  &__iconContainer {
    flex-basis: 9%;
    max-width: 9%;

    @media (max-width: 991px) {
      flex-basis: 4%;
      max-width: 4%;
    }
  }

  &__linkContainer.active::after {
    content: "";
    position: absolute;
    right: -31px;
    top: 20%;
    transform: translateY(-50%);
    width: 3px;
    height: 26px;

    background: $color1;
  }

  &__linkContainer.active &__text,
  &__linkContainer.active &__icon {
    color: $color1;
  }
}
</style>
