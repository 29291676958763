import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import { authGuard } from "../auth";
// import BetaPromo from "../views/BetaPromo.vue";
import UserProfile from "../views/userprofile.vue";
import Registration from "../views/Registration.vue";
import Trips from "../views/Trips.vue";
import Deals from "../views/Deals.vue";
import SignUp from "../views/Signup.vue";
import Faq from "../views/Faq.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import Terms from "../views/Terms.vue";
import { getInstance } from "../auth/authWrapper";
// import test from "../views/test.vue";
Vue.use(Router);

import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);
VueHammer.config.swipe = {
  threshold: 200,
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      beforeEnter: (to, from, next) => {
        var instance = getInstance();

        instance.$watch("loading", async (loading) => {
          if (loading === false) {
            if (
              instance.user.isAuthenticated &&
              instance.user["https://app.browncowtravel.com/SubMax"] &&
              instance.user["https://app.browncowtravel.com/SubMax"] > 0
            ) {
              next("/deals");
            } else {
              next();
            }
          }
        });
        next();
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: PrivacyPolicy,
    },

    {
      path: "/termsandconditions",
      name: "termsandconditions",
      component: Terms,
    },
    // {
    //   path: "/home",
    //   name: "home",
    //   component: Home,
    // },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      beforeEnter: authGuard,
    },
    {
      path: "/user",
      name: "userprofile",
      component: UserProfile,
      beforeEnter: authGuard,
    },

    {
      path: "/registration",
      name: "Registration",
      component: Registration,
    },
    {
      path: "/trips/:id",
      name: "Trips",
      component: Trips,
      // beforeEnter: authGuard,
    },
    {
      path: "/deals",
      name: "Deals",
      component: Deals,
      beforeEnter: authGuard,
    },
    {
      path: "/register",
      name: "Register",
      component: SignUp,
      beforeEnter: (to, from, next) => {
        var instance = getInstance();

        instance.$watch("loading", async (loading) => {
          if (loading === false) {
            if (
              instance.user["https://app.browncowtravel.com/SubMax"] &&
              instance.user["https://app.browncowtravel.com/SubMax"] > 0
            ) {
              if (to.query.step == 4 || to.query.step == "4") {
                console.log("QUERY", to.query);
                let subMax =
                  instance.user["https://app.browncowtravel.com/SubMax"];

                let url = `/register?package=${to.query.pkg}&step=${to.query.step}&subscriptionId=${to.query.subscriptionId}&subMax=${subMax}`;

                next(url);
              } else {
                next({ path: "/user" });
              }
              console.log(to);
              // router.push({ path: "/user" });
            }
          }
        });
        next();
      },
    },
    {
      path: "/faq",
      name: "Faq",
      component: Faq,
    },
  ],
  scrollBehavior() {
    // always scroll to top
    return { x: 0, y: 0 };
  },
});

export default router;
