<template>
  <div class="routes">
    <h1 class="routes__heading">
      Saved Routes - {{ activitiesLength }} of {{ allowedSaves }} Routes Saved
    </h1>
    <div
      :class="{ routes__alternate: !showAddRoutes }"
      v-if="activitiesLength < allowedSaves"
    >
      <div class="routes__container" v-if="showAddRoutes">
        <div
          class="routes__selectContainer d-flex align-items-center justify-content-between"
        >
          <p class="m-0 routes__selectText">Where do you want to fly from?</p>

          <div
            class="input-group routes__select"
            :class="{
              departure__err: error_departure && error_departure.length > 0,
            }"
          >
            <v-select
              :class="{
                'departure-selected': departures.length > 0,
                'departure-closed': tempDep == null,
              }"
              transition=""
              label="combined"
              v-model="tempDep"
              :options="airports"
              placeholder="Airport Selection"
              @input="addDeparture"
            >
              <template #selected-option="{ city, name, IATA }">
                <span class="input__selected"
                  >{{ city }} | {{ name }} ({{ IATA }})</span
                >
              </template>
              <template #option="{ city, name, IATA }">
                {{ city }} | {{ name }} ({{ IATA }})
              </template>
            </v-select>
            <p class="warning pt-1" v-if="DepError">{{ DepError }}</p>
          </div>
        </div>
        <div class="error">{{ error_departure }}</div>

        <div
          class="routes__selectContainer d-flex align-items-center justify-content-between my-3"
        >
          <p class="m-0 routes__selectText">Where do you want to fly to?</p>

          <div
            class="input-group"
            :class="{
              arrivals__err: error_arrivals && error_arrivals.length > 0,
            }"
          >
            <v-select
              :class="{
                'arrival-selected': arrivals.length > 0,
                'arrival-closed': tempArr == null,
              }"
              transition=""
              v-model="tempArr"
              label="combined"
              :options="airports"
              placeholder="Airport Selection"
              @input="addArrival"
            >
              <template #selected-option="{ city, name, IATA }">
                <span class="input__selected"
                  >{{ city }} | {{ name }} ({{ IATA }})</span
                >
              </template>
              <template #option="{ city, name, IATA }">
                {{ city }} | {{ name }} ({{ IATA }})
              </template>
            </v-select>
            <p class="warning pt-1" v-if="ArrError">{{ ArrError }}</p>
          </div>
        </div>
        <div class="error">{{ error_arrivals }}</div>

        <!-- range-slider -->
        <div
          class="rangeSlider__container d-flex flex-md-row flex-column justify-content-md-between"
        >
          <h2 class="routes__heading2 range w-100 text-start">
            What's the most you're willing to pay?
          </h2>

          <div>
            <div class="range-slider">
              <div
                id="rs-bullet"
                class="d-flex flex-column align-items-center justify-content-center rs-label"
                :class="{ max: minRange, min: !minRange }"
                :style="{ left: (priceRange / maxSlideRange) * 100 + `%` }"
              >
                <span>{{ priceRange }}</span>
              </div>
              <input
                id="rs-range-line"
                class="rs-range"
                type="range"
                v-model="priceRange"
                :min="minSlideRange"
                :max="maxSlideRange"
                step="50"
              />
            </div>

            <div class="box-minmax"><span>$100</span><span>$2000+</span></div>
          </div>
        </div>

        <p class="warning" v-if="monthsError">{{ monthsError }}</p>
        <div class="d-flex flex-md-row flex-column align-items-xl-center">
          <h2 class="routes__heading2 m-0 monthsheading w-100">
            When do you want to go here?
            <p class="m-0 d-md-block d-inline monthsheading__subheading">
              (Click as many as you want)
            </p>
          </h2>
          <div
            class="routes__months d-flex justify-content-md-around justify-content-start flex-wrap align-items-baseline"
          >
            <div
              class="form-check routes__monthInputContainer mx-2"
              v-for="month in months"
              :key="month"
            >
              <input
                class="form-check-input routes__months__input"
                type="checkbox"
                :id="month"
                v-model="selectedmonths"
                :value="month"
              />
              <label
                class="form-check-label m-0 ml-lg-1 ml-2 routes__months__label"
                :for="month"
              >
                {{ month }}
              </label>
            </div>
          </div>
        </div>
        <div class="text-center w-100 my-3">
          <p class="routes__card__limitText" v-if="showLimitError">
            {{ showLimitError }}
          </p>
          <div
            class="d-flex flex-sm-row flex-column align-items-center justify-content-center"
          >
            <button
              class="btn btn-primary addroute routes__btn me-sm-3 mb-sm-0 mb-3"
              @click="
                addItem(
                  departures,
                  arrivals,
                  $auth.user,
                  selectedmonths,

                  priceRange
                )
              "
            >
              Add Route
            </button>
            <button
              class="btn btn-primary routes__btn doneadd"
              @click="doneAddingSearches"
            >
              Done Adding Searches
            </button>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-sm-row flex-column align-items-center justify-content-center mt-1"
        v-else
      >
        <button
          class="btn btn-primary mx-0"
          @click="showAddRoutes = !showAddRoutes"
        >
          Add New Saved Route
        </button>
        <button
          class="btn btn-primary ms-sm-2 ms-0 mt-3 mt-sm-0"
          @click="doneAddingSearches"
        >
          Done Adding Searches
        </button>
      </div>
    </div>

    <div class="routes__container" v-else>
      <h1
        class="routes__heading alternateHeading text-center"
        v-if="allowedSaves < 10"
      >
        All of your Save Route slots are full. You can
        <span class="routes__card__addmore__link" @click="show">upgrade</span>
        to get more or delete existing routes.
      </h1>
      <h1
        class="routes__heading alternateHeading text-center"
        v-else-if="allowedSaves == 10"
      >
        All of your Save Route slots are full. Delete some old ones to add new
        routes.
      </h1>
    </div>
    <div
      class="d-flex justify-content-sm-between justify-content-xl-start justify-content-center flex-wrap mt-3 align-items-center routesContainer"
    >
      <!-- mobile -->
      <div
        class="w-100"
        v-for="(activities, i) in allActivities"
        :key="i + 'mobile'"
      >
        <div
          v-if="activities.id"
          :class="{
            filled: activities.id,
          }"
          class="routes__card position-relative routesCard w-100 mb-3 d-sm-none d-block"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="routes__card__deleteiconContainer">
              <font-awesome-icon
                @click="showDeleteRouteModal(activities.sk)"
                class="routes__card__deleteicon d-block d-sm-none"
                :icon="['fas', 'trash-alt']"
              />
            </div>
            <div class="routes__card__contentContainer">
              <!-- Route & MaxPrice -->
              <div
                class="cardRow d-flex flex-wrap justify-content-between mb-2 align-items-center"
              >
                <div class="">
                  <span class="cardHeader routes__card__header">Route: </span
                  ><span class="cardValue routes__card__value">
                    {{ activities.depcity }} to {{ activities.arrcity }}
                  </span>
                </div>
                <div class="cardRow">
                  <span class="cardHeader routes__card__header m-0"
                    >Max Price:</span
                  >
                  <span class="cardValue routes__card__value price">
                    ${{ activities.maxRequestedPrice }}
                  </span>
                </div>
              </div>
              <!-- months -->
              <div class="cardRow">
                <div class="d-flex flex-wrap routes__card__monthsContainer">
                  <p class="cardHeader routes__card__header text-left m-0 pe-1">
                    Months:
                  </p>
                  <p
                    class="cardValue routes__card__value me-2 my-0"
                    :key="month + index"
                    v-for="(month, index) in activities.months"
                  >
                    <span v-if="index == activities.months.length - 1">
                      {{ month }}</span
                    >
                    <span v-else> {{ month }},</span>
                  </p>
                </div>
              </div>
            </div>

            <font-awesome-icon
              @click="showDeleteRouteModal(activities.sk)"
              class="routes__card__deleteicon d-none d-sm-block"
              :icon="['fas', 'trash-alt']"
            />
          </div>
        </div>
      </div>
      <!-- desktop -->
      <div
        class="w-100 d-sm-block d-none"
        v-for="(activities, i) in allActivities"
        :key="i + 'desktop'"
      >
        <div
          v-if="activities.id"
          :class="{ filled: activities.id }"
          class="routes__card routesCard w-100 mb-3"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="routes__card__contentContainer">
              <!-- Route & MaxPrice -->
              <div
                class="cardRow d-flex flex-wrap justify-content-between mb-2 align-items-center"
              >
                <div class="">
                  <span class="cardHeader routes__card__header">Route: </span
                  ><span class="cardValue routes__card__value">
                    {{ activities.depcity }} to {{ activities.arrcity }}
                  </span>
                </div>
                <div class="cardRow">
                  <span class="cardHeader routes__card__header m-0"
                    >Max Price:</span
                  >
                  <span class="cardValue routes__card__value price">
                    ${{ activities.maxRequestedPrice }}
                  </span>
                </div>
              </div>
              <!-- months -->
              <div class="cardRow">
                <div class="d-flex flex-wrap routes__card__monthsContainer">
                  <p class="cardHeader routes__card__header text-left m-0 pe-1">
                    Months:
                  </p>
                  <p
                    class="cardValue routes__card__value me-2 my-0"
                    :key="month + index"
                    v-for="(month, index) in activities.months"
                  >
                    <span v-if="index == activities.months.length - 1">
                      {{ month }}</span
                    >
                    <span v-else> {{ month }},</span>
                  </p>
                </div>
              </div>
            </div>

            <font-awesome-icon
              @click="showDeleteRouteModal(activities.sk)"
              class="routes__card__deleteicon"
              :icon="['fas', 'trash-alt']"
            />
          </div>
        </div>
      </div>
      <div
        @click="show"
        class="w-100 routes__card routes__card__addmore text-center mx-2 my-3"
        v-if="allowedSaves <= 5"
      >
        <p class="routes__card__addmore__text1 my-1">
          Want to Save More Routes?
        </p>
        <p class="routes__card__addmore__text2 my-1">
          Upgrade to Platinum Now!
        </p>
      </div>
    </div>
    <!-- addmoresearches or go to deals page modal -->
    <modal
      classes="routes__modalContainer"
      name="donesearching"
      :width="'320px'"
      :height="'270px'"
      :adaptive="true"
    >
      <div
        class="position-relative d-flex justify-content-center pt-5 px-4 text-center w-100"
      >
        <font-awesome-icon
          class="routes__modal__close"
          @click="hideAddingModal"
          :icon="['fas', 'times']"
        />
        <div class="routes__modal">
          <h4 class="routes__modal__heading">
            You have more searches available, do you want to enter more or see
            your deals?
          </h4>
          <div
            class="d-flex flex-column justify-content-center align-items-center mt-4"
          >
            <button
              @click="hideAddingModal"
              class="btn btn-primary routes__modal__btn moreSearches"
            >
              Add more searches
            </button>
            <button
              @click="$router.push('/deals')"
              class="btn btn-primary routes__modal__btn mydeals"
            >
              See my deals
            </button>
          </div>
        </div>
      </div>
    </modal>
    <!-- update plan modal -->
    <modal
      classes="routes__modalContainer"
      name="planmodal"
      :width="'400px'"
      :height="'650px'"
      :adaptive="true"
    >
      <div
        class="position-relative d-flex justify-content-center p-5 text-center w-100"
      >
        <font-awesome-icon
          class="routes__modal__close"
          @click="hide"
          :icon="['fas', 'times']"
        />
        <div class="px-3 py-5 text-center text-black bg-white rounded">
          <h3 class="mb-2 fs-1 fw-bold">Platinum</h3>
          <span class="fs-1 fw-bold">$3 per month</span>
          <p class="mt-1">billed monthly after FREE trial</p>
          <div class="my-4 py-2 d-flex justify-content-center">
            <ul class="list-unstyled text-start mb-0">
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>7 Day Free Trial</span>
              </li>
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>10 Saved Flight Searches</span>
              </li>
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>Economy, Business and First-Class Seats</span>
              </li>
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>Domestic and International Flights</span>
              </li>
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>Special Deals from Home Airports</span>
              </li>
            </ul>
          </div>
          <div>
            <button
              class="btn btn-primary px-5 d-block mt-4 ml-0 mr-0"
              @click.prevent="selectedPackage('platinum')"
            >
              Select
            </button>
          </div>
        </div>
      </div>
    </modal>

    <!-- messageModal -->
    <modal
      classes="routes__modalContainer"
      name="routesavedModal"
      :width="'400px'"
      :height="'auto'"
      :adaptive="true"
    >
      <div class="card">
        <h1 class="routes__savedModal__heading">Route Saved</h1>
        <p
          v-if="allowedSaves - remainingSaves == 0"
          class="routes__savedModal__text"
        >
          You have filled all your available routes. To add more routes delete
          existing ones.
        </p>
        <p v-else class="routes__savedModal__text">
          You can add
          <span class="routes__savedModal__text alternate">{{
            allowedSaves - remainingSaves
          }}</span>
          more!
        </p>
      </div>
    </modal>
    <!-- confirmDelete Modal -->
    <modal
      classes="routes__modalContainer"
      name="confirmdelete"
      :width="'300px'"
      :height="'auto'"
      :adaptive="true"
    >
      <div
        class="position-relative d-flex justify-content-center py-3 px-4 text-center w-100"
      >
        <font-awesome-icon
          class="routes__modal__close"
          @click="$modal.hide('confirmdelete')"
          :icon="['fas', 'times']"
        />
        <div class="routes__modal">
          <h4 class="routes__modal__heading delete">Delete this route ?</h4>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <button
              :style="{ width: '80px' }"
              @click="confirmDeleteRoute"
              class="btn btn-primary"
            >
              Yes
            </button>
            <button
              :style="{ width: '80px' }"
              @click="$modal.hide('confirmdelete')"
              class="btn btn-primary ms-2"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { getInstance } from "@/auth/authWrapper.js";
export default {
  props: ["step"],
  data() {
    return {
      movePosition: 0,
      showAddRoutes: false,
      getLabel: "city",
      priceRange: 2000,
      maxSlideRange: 2000,
      minSlideRange: 100,
      minRange: false,
      from: null,
      to: null,
      allowedSaves: 5,
      remainingSaves: 0,
      selectors: [
        {
          text: "Where do you want to fly from?",

          options: [
            { text: "Airport Selection", value: null },
            { text: "value1", value: "value1" },
            { text: "value2", value: "value2" },
          ],
          model: "from",
        },
        {
          text: "Where do you want to fly to?",

          options: [
            { text: "Airport Selection", value: null },
            { text: "value1", value: "value1" },
            { text: "value2", value: "value2" },
          ],
          model: "to",
        },
      ],
      selectedmonths: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      airports: [],
      departures: [],
      depCity: [],
      tempDep: "",
      arrivals: [],
      arrCity: [],
      tempArr: "",
      activities: [],
      activitiesLength: 0,

      error_save: null,
      error_departure: null,
      error_arrivals: null,
      showLimitError: null,
      ArrError: null,
      DepError: null,
      monthsError: null,
      deleteid: null,
    };
  },
  created() {
    const instance = getInstance();
    instance.$watch("loading", (loading) => {
      console.log("loaded");
      if (loading === false) {
        //  let user = encodeURIComponent(this.$auth.user.sub.toString());
        // this.loadTokenIntoStore(instance);
      }
    });
    if (
      localStorage.getItem("isAuthenticated") &&
      localStorage.getItem("allowedsaves")
    ) {
      this.allowedSaves = +localStorage.getItem("allowedsaves");
    }
    // let user = this.$auth.user.sub.toString();
    var user = encodeURIComponent(this.$auth.user.sub.toString());
    this.gettrips(user);
  },
  async mounted() {
    this.showAddRoutes = true;
    if (this.activitiesLength == 0) {
      this.showAddRoutes = true;
    }
    try {
      let response = await axios.get(process.env.VUE_APP_AIRPORTS_JSON_URL);
      this.airports = response.data;
      this.airports = this.airports.map((airport) => {
        return {
          ...airport,
          combined: `${airport.city} ${airport.name} ${airport.IATA}`,
        };
      });
    } catch (error) {
      console.log("oh no! Axios error for Getting Airports:", error);
    }
  },
  watch: {
    priceRange(value) {
      if (value <= 100) {
        this.minRange = true;
      } else if (value >= 2000) {
        this.minRange = false;
      }
    },

    departures(value) {
      if (value.length >= 0) {
        this.DepError = null;
      }
    },
    arrivals(value) {
      if (value.length >= 0) {
        this.ArrError = null;
      }
    },
    activitiesLength(value) {
      if (value == 0) {
        this.showAddRoutes = true;
      }
    },

    selectedmonths(value) {
      if (value.length >= 0) {
        this.monthsError = null;
      }
    },
    $route: {
      deep: true,
      handler(route) {
        // console.log("query from watcher routes.vue", route);
        if (route.query.subscriptionId && route.query.package) {
          if (
            localStorage.getItem("isAuthenticated") &&
            localStorage.getItem("allowedsaves")
          ) {
            // console.log("if condition passed", route);
            this.allowedSaves = +localStorage.getItem("allowedsaves");
            let user = this.$auth.user.sub.toString();
            this.gettrips(user);
          }
          console.log(route);
        }
      },
    },
    movePosition: {
      deep: true,
      handler: (value) => {
        console.log(value);
      },
    },
  },

  methods: {
    doneAddingSearches() {
      if (this.activitiesLength < this.allowedSaves) {
        this.$modal.show("donesearching");
      } else {
        this.$router.push("/deals");
      }
    },
    hideAddingModal() {
      this.$modal.hide("donesearching");
    },
    showDeleteRouteModal(id) {
      this.deleteid = null;
      this.deleteid = id;
      this.$modal.show("confirmdelete");
    },
    confirmDeleteRoute() {
      this.deleteItem(this.deleteid);
      this.$modal.hide("confirmdelete");
    },
    hideRouteSavedModal() {
      this.$modal.hide("routesavedModal");
    },
    showRouteSavedModal() {
      this.$modal.show("routesavedModal");
    },

    selectedPackage(pkg) {
      let data = {
        pkg,
      };
      this.checkout(data);
    },
    async checkout(pkgData) {
      let priceId = process.env.VUE_APP_PRICEID_PLATINUM;

      let data = {
        email: this.$auth.user.email,
        priceId,
        id: this.$auth.user.sub,
        package: pkgData.pkg,
        action: "update",
        stripeid:
          this.$auth.user["https://app.browncowtravel.com/SubStripeCust"],
      };
      const res = await axios.post(
        `${process.env.VUE_APP_PAYMENT_API}/payment`,
        data
      );
      if (res.data.subscriptionId) {
        this.$store.commit("setStripeData", {
          subscriptionId: res.data.subscriptionId,
          clientSecret: res.data.clientSecret ? res.data.clientSecret : "",
        });
        let subMax = this.$auth.user["https://app.browncowtravel.com/SubMax"];
        this.hide();

        window.location.href = `/user?name=savedroutes&subMax=${subMax}&subscriptionId=${res.data.subscriptionId}`;
      }
    },

    show() {
      if (
        localStorage.getItem("allowedsaves") &&
        localStorage.getItem("allowedsaves") >= 10
      ) {
        return;
      }
      this.$modal.show("planmodal");
    },
    hide() {
      this.$modal.hide("planmodal");
    },

    // async loadTokenIntoStore(instance) {
    //   await instance.getTokenSilently();

    // },
    validateAirport(input) {
      let airport = {};

      //Check input string for proper formatting from list entry and not free form
      if (
        input.indexOf("|") > 1 &&
        input.indexOf("(") > 1 &&
        input.indexOf(")") > 1 &&
        input.length > 10
      ) {
        //Extract airport attributes from string

        //Some airports do not have IATA codes and have \N instead.  Set these to proper \N format
        if (input.indexOf("\\N") >= 0) {
          airport.IATA = "\\N";
        } else {
          //extract code from end since some airport names include ()
          let IATA = input.substr(input.length - 6, 5);
          airport.IATA = IATA.substr(IATA.indexOf("(") + 1, 3);
        }
        airport.city = input.substr(0, input.indexOf("|") - 1);
        //end of city name is defined by start of found IATA.  Done this way to be sure to include the name found within ().
        //airport name in JSON also includes trailing space.  Could use trim for compare, but decided to work with it
        airport.name = input.substr(
          input.indexOf("|") + 2,
          input.indexOf(airport.IATA) - 4 - airport.city.length
        );
      }

      return airport;
    },

    addDeparture() {
      //Reset error dialog
      let option;
      let tempvalue;
      this.departures = [];
      this.depCity = [];
      this.error_departure = null;
      if (!this.tempDep) {
        return;
      }
      if (typeof this.tempDep === "string" && this.tempDep.trim().length < 1) {
        return;
      }

      if (!this.tempDep.IATA) {
        return;
      }

      option = this.tempDep;
      tempvalue = `${option.city.trim()} | ${option.name.trim()} (${option.IATA.trim()})`;

      let validairport = false;
      let airport = this.validateAirport(tempvalue);

      //Only allow selection from airport lists

      if ("IATA" in airport && "name" in airport && "city" in airport) {
        if (
          this.airports.some((e) => e.IATA.trim() === airport.IATA.trim()) &&
          this.airports.some((e) => e.city.trim() === airport.city.trim()) &&
          this.airports.some((e) => e.name.trim() === airport.name.trim())
        ) {
          let arrivalIndex = this.arrivals.findIndex((item) => {
            return (
              item.city.trim() == airport.city.trim() &&
              item.name.trim() == airport.name.trim() &&
              item.IATA.trim() == airport.IATA.trim()
            );
          });

          if (arrivalIndex < 0) {
            validairport = true;
            this.departures.push(airport);
            this.depCity.push(airport.city);
            //only clear if valid.  That way customer search is preserved when they receive invalid error
            // this.tempDep = null;
          }
        }
      }
      if (!validairport) {
        this.error_departure =
          "Invalid departing airport. Please select a valid airport from the list and then try to add it again.";
        // this.tempDep = null;
      }
    },
    deleteDeparture(departure) {
      this.departures = this.departures.filter((item) => {
        return departure !== item;
      });
    },

    addArrival() {
      //Reset error dialog
      let option;
      let tempvalue;
      this.arrivals = [];
      this.arrCity = [];
      this.error_arrivals = null;
      if (!this.tempArr) {
        return;
      }
      if (typeof this.tempArr === "string" && this.tempArr.trim().length < 1) {
        return;
      }
      if (!this.tempArr.IATA) {
        return;
      }

      option = this.tempArr;
      tempvalue = `${option.city.trim()} | ${option.name.trim()} (${option.IATA.trim()})`;

      let validairport = false;
      let airport = this.validateAirport(tempvalue);
      //Only allow selection from airport lists
      if ("IATA" in airport && "name" in airport && "city" in airport) {
        if (
          this.airports.some((e) => e.IATA.trim() === airport.IATA.trim()) &&
          this.airports.some((e) => e.city.trim() === airport.city.trim()) &&
          this.airports.some((e) => e.name.trim() === airport.name.trim())
        ) {
          //valid airport entry, only add if not duplicate

          let departuresIndex = this.departures.findIndex((item) => {
            return (
              item.city.trim() == airport.city.trim() &&
              item.name.trim() == airport.name.trim() &&
              item.IATA.trim() == airport.IATA.trim()
            );
          });

          if (departuresIndex < 0) {
            validairport = true;
            this.arrivals.push(airport);
            this.arrCity.push(airport.city);
            //only clear if valid.  That way customer search is preserved when they receive invalid error
            // this.tempArr = null;
          }
        }
      }
      if (!validairport) {
        this.error_arrivals =
          "Invalid arrival airport. Please select a valid airport from the list and then try to add it again.";
        // this.tempArr = null;
      }
    },
    deleteArrival(arrival) {
      this.arrivals = this.arrivals.filter((item) => {
        return arrival !== item;
      });
    },
    deleteItem(id) {
      // media query to check
      var media_query = "screen and (max-width:575px)";

      // matched or not
      var matched = window.matchMedia(media_query).matches;
      if (matched) {
        // let index = this.activities.findIndex((item) => item.id == id);
        // this.activities.splice(index, 1);

        axios
          .delete(process.env.VUE_APP_API_URL + "/activities/" + encodeURIComponent(id))
          .then((res) => {
            console.log(res.data);
            var user = encodeURIComponent(this.$auth.user.sub.toString());
            this.gettrips(user);
          })
          .catch(function (error) {
            console.log("oh no! Axios error:", error);
          });
        return;
      } else {
        axios
          .delete(process.env.VUE_APP_API_URL + "/activities/" + encodeURIComponent(id))
          .then((res) => {
            console.log(res.data);
            var user = encodeURIComponent(this.$auth.user.sub.toString());
            this.gettrips(user);
          })
          .catch(function (error) {
            console.log("oh no! Axios error:", error);
          });
      }
    },
    addItem(departures, arrivals, userId, months, maxRequestedPrice) {
      if (
        departures.length <= 0 ||
        arrivals.length <= 0 ||
        this.selectedmonths.length <= 0
      ) {
        if (departures.length <= 0) {
          this.DepError =
            "Please select the departure airport before adding the route.";
        }
        if (arrivals.length <= 0) {
          this.ArrError =
            "Please select the arrival airport before adding the route.";
        }
        if (this.selectedmonths.length <= 0) {
          this.monthsError = "Please select a month before proceeding";
        }
        return;
      }

      this.showLimitError = null;
      if (this.activitiesLength >= this.allowedSaves) {
        this.priceRange = 2000;
        this.tempArr = "";
        this.selectedmonths = [];
        return (this.showLimitError =
          "Please Upgrade the plan or update the existing slots");
      } else {
        this.showLimitError = null;
      }

      this.error_save = null;

      var allMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      months = months.sort((a, b) => {
        return allMonths.indexOf(a) - allMonths.indexOf(b);
      });

      //Don't allow save if customer did not provide both departure and arrival flights
      const hasArrivals = arrivals.some((e) => e.IATA.length === 3);
      const hasDepartures = departures.some((e) => e.IATA.length === 3);

      if (!hasArrivals || !hasDepartures) {
        this.error_save =
          "Please select a departing and arrival airport before saving your flight preferences.";

        return;
      }
      this.depCity = [];
      this.arrCity = [];
      this.depCity.push(departures[0].city);
      this.arrCity.push(arrivals[0].city);
      let userIdtoArray = userId.sub;
      const jsonObject = new Object();
      jsonObject.departure = departures;
      jsonObject.arrival = arrivals;
      jsonObject.userId = userIdtoArray.toString();
      jsonObject.email = this.$auth.user.email.toString();
      jsonObject.months = months.toString();
      jsonObject.depcity = this.depCity.toString();
      jsonObject.arrcity = this.arrCity.toString();
      jsonObject.maxRequestedPrice = maxRequestedPrice.toString();

      let jsonString = JSON.stringify(jsonObject);

      //declare custom header
      const postHeader = {
        "Content-Type": "application/json",
      };

      axios
        .post(process.env.VUE_APP_API_URL + "/activities", jsonString, {
          headers: postHeader,
        })
        .then(() => {
          var user = encodeURIComponent(this.$auth.user.sub.toString());

          this.gettrips(user);
          this.showRouteSavedModal();
          setTimeout(() => {
            this.hideRouteSavedModal();
          }, 3000);
          this.arrivals = [];
          // this.departures = [];

          this.$forceUpdate();
          this.priceRange = 2000;
          this.tempArr = "";
          this.selectedmonths = [];
          this.showAddRoutes = !this.showAddRoutes;
        })
        .catch(function (error) {
          console.log("oh no! Axios error:", error);
        });
    },

    gettrips(a0user) {
      this.activities = [];

      let limit = this.allowedSaves;

      for (let i = 0; i < limit; i++) {
        this.activities.push({
          swipeNo: 0,
          deletedItem: false,
        });
      }
      //let headers = {'Origin': 'https://app-dev.browncowtravel.com'}
      //let params = {}
      axios
        // .get(process.env.VUE_APP_API_URL + "/activities/" + a0user,params,headers)
        .get(process.env.VUE_APP_API_URL + "/activities/" + a0user)
        .then((response) => {
          let activities = response.data;
          console.log("activities",activities)
          console.log("url",process.env.VUE_APP_API_URL + "/activities/" + a0user);
          this.remainingSaves = activities.length;

          activities = activities.filter((activity) => {
            if (activity.id) {
              activity.months = activity.months.split(",");
              activity.rarity = "uncommon";
            }

            activity.swipeNo = 0;
            activity.deletedItem = false;

            return activity;
          });

          this.activitiesLength = activities.length;

          activities.map((item, index) => {
            if (this.activities[index]) {
              if (item.id == this.activities[index].id) {
                return;
              } else {
                this.activities.splice(index, 1, item);
              }
            }
          });
        })
        .catch(function (error) {
          console.log("oh no! Axios error (gettrips):", error);
        });
    },
  },
  computed: {
    allActivities() {
      return this.activities;
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
$primaryColor: #5f4635;
$secondaryColor: #ff3647;
$thirdColor: #212529;
// media query mixins
// Small devices
.warning {
  color: $secondaryColor !important;
}

@mixin xs {
  @media (max-width: "575px") {
    @content;
  }
}

@mixin sm {
  @media (max-width: "767px") {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: "991px") {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: "1199px") {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: "1440px") {
    @content;
  }
}

@mixin customMin($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

@mixin customMax($screen) {
  @media (max-width: $screen) {
    @content;
  }
}
.routesContainer {
  overflow: hidden !important;
}
.routes {
  overflow: hidden !important;
  margin: 30px 0;
  padding: 20px 4px;
  &__alternate {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  &__savedModal {
    &__heading {
      color: $primaryColor;
    }
    &__text {
      color: $secondaryColor;
      font-weight: bold;
    }
    &__text.alternate {
      color: $primaryColor;
      font-weight: 900;
    }
  }
  &__heading {
    color: $primaryColor;
    font-size: 1.35rem;
  }
  &__heading.alternateHeading {
    @include lg {
      font-size: 1.25rem;
    }
    @include md {
      font-size: 1.2rem;
    }
    @include xs {
      font-size: 1.1rem;
    }
  }
  &__container {
    box-shadow: 0px 0px 3px 1px #dfe3ec;
    padding: 30px 30px 12px;
    @media (max-width: 991px) {
      padding: 20px 20px 15px;
    }
  }
  &__btn.doneadd,
  &__btn.addroute {
    width: 80%;
    margin: 10px auto;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $secondaryColor !important;
    color: white !important;
    margin: auto;
    display: block;
    font-weight: 600 !important;
    height: 40px;

    @include lg {
      font-size: 0.9rem !important;
    }
    @media (max-width: 375px) {
      width: 100%;
    }

    &:hover {
      background: $primaryColor !important;
      color: white !important;
    }
    &:focus {
      border: none !important;
    }
  }

  &__selectContainer {
    @media (max-width: 575px) {
      flex-wrap: wrap;
    }
  }
  &__select {
    @media (max-width: 575px) {
      flex-basis: 100%;
      max-width: 100%;
      margin-top: 5px;
    }
  }
  &__selectText {
    font-size: 1rem;
    flex-basis: 50%;
    max-width: 50%;
    font-weight: bold;
    color: $primaryColor;
    @media (max-width: 1199px) {
      font-size: 0.9rem;
      flex-basis: 57%;
      max-width: 57%;
    }
    @media (max-width: 575px) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  &__heading2 {
    font-size: 0.9rem;
    font-weight: bold;
    color: $primaryColor;

    padding-top: 10px;
  }
  &__heading2.monthsheading {
    flex-basis: 50%;
    max-width: 50%;
    font-size: 1rem;
    padding-top: 0;

    @include lg {
      font-size: 0.9rem;
      flex-basis: 80%;
      max-width: 80%;

      padding-top: 2px;
    }
    @include sm {
      padding-top: 15px;
      padding-bottom: 15px;
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  &__heading2.monthsheading .monthsheading__subheading {
    padding-top: 7px;
    @include sm {
      padding-top: 0px;
    }
  }
  &__heading2.range {
    text-align: left;
    font-size: 1rem;
    flex-basis: 50%;
    max-width: 50%;
    font-weight: bold;
    margin-bottom: 0;
    color: $primaryColor;
    @include lg {
      font-size: 0.9rem;
    }
    @include sm {
      flex-basis: 100%;
      max-width: 100%;
      margin-bottom: 10px;
    }
  }

  &__monthInputContainer {
    flex-basis: 13.33333%;
    max-width: 13.33333%;

    @include lg {
      flex-basis: 20.33333%;
      max-width: 20.33333%;
    }
    @include sm {
      flex-basis: 16.33333%;
      max-width: 16.33333%;
    }
    @include xs {
      flex-basis: 25.33333%;
      max-width: 25.33333%;
    }
    @media (max-width: 350px) {
      flex-basis: 40%;
      max-width: 40%;
    }
  }
  &__months {
    &__input {
      padding: 9px 8px !important;
    }
    &__label {
      font-size: 0.7rem;
      color: $primaryColor;
      @media (max-width: 575px) {
        font-size: 0.6rem;
      }
    }
  }

  &__card {
    position: relative;
    width: 100%;
    height: auto;
    padding: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
    margin-right: 10px;
    margin-top: 10px;
    transition: all 0.9s;

    @include lg {
      width: 320px;
      margin-right: 0;
    }
    @include md {
      // width: 207px;
    }
    @include sm {
      width: 490px;
    }
    @include xs {
      width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      padding: 20px;
    }
    @include customMax(400px) {
      padding: 20px 10px;
    }
    &__img {
      height: 200px !important;
    }
    &__contentContainer {
      flex-basis: 800px;
      max-width: 800px;
      @include customMax(1400px) {
        flex-basis: 700px;
        max-width: 700px;
      }
      @include lg {
        flex-basis: 560px;
        max-width: 560px;
      }
      @include sm {
        flex-basis: 370px;
        max-width: 370px;
      }
      @include xs {
        flex-basis: 100%;
        max-width: 100%;
        transition: 0.5s all;
      }
    }

    &__header {
      color: $primaryColor !important;
      font-weight: bold !important;
      font-size: 0.9rem;
    }
    &__value {
      color: $secondaryColor;
      font-weight: 600;
      font-size: 0.9rem;
      padding-top: 2px;
    }
    &__value.price {
      font-size: 0.9rem;
    }

    &__deleteiconContainer {
      transition: 0.5s all;
      margin-right: 12px;
      transform: translate(-10px, -23px);
      @include customMax(400px) {
        transform: translate(-3px, -30px);
        margin-right: 15px;
      }
    }

    &__deleteicon {
      color: $secondaryColor;
      width: 50px !important;
      height: 50px;
      cursor: pointer;
      @include lg {
        width: 45px !important;
        height: 45px !important;
      }
      @include xs {
        width: 20px !important;
        height: 20px !important;
      }
    }
    &__monthsContainer {
      height: auto;
      align-items: baseline;
      // @media (max-width: 1199px) {
      //   height: 92px;
      // }
    }
    &__limitText {
      color: $secondaryColor;
    }
    &__addmore {
      text-align: center;
      cursor: pointer;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 7px 0px !important;
      @include lg {
        margin-top: 15px;
      }
      @include xs {
        height: auto !important;
      }

      &__text1 {
        font-size: 1.2rem;
        font-weight: bold;
        color: $secondaryColor;
      }
      &__text2 {
        font-size: 1.2rem;
        font-weight: bold;
        color: $primaryColor;
      }
      &__link {
        color: $secondaryColor;
        text-decoration: underline;
        cursor: pointer;
      }
      &__btn {
        font-size: 2.1875rem;
      }
    }
  }
  &__card.routesCard {
    @include xs {
      height: auto !important;
      overflow: hidden;
      transition: all 1.8s;
    }
  }
  &__card.filled {
    border: 2px solid $secondaryColor;
  }

  &__card.filled::after {
    content: "";
    position: absolute;

    top: 0;
    width: 2px;
    height: 100%;
    z-index: 1;
    background: $secondaryColor;
    right: 8%;
    @include customMax(1400px) {
      right: 9%;
    }
    @include lg {
      right: 10%;
    }
    @include sm {
      right: 70px;
    }
    @include xs {
      display: none;
    }
  }
  &__modal {
    &__heading {
      font-size: 1rem;
      margin-top: 20px;
      @include xs {
        font-size: 0.875rem;
      }
    }
    &__heading.delete {
      @include xs {
        font-size: 1rem;
      }
    }
    &__btn.moreSearches,
    &__btn.mydeals {
      line-height: 1;
      font-size: 0.9375rem;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    &__btn.mydeals {
      margin-top: 10px;
    }

    &__close {
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
      font-size: 1.25rem;
    }
  }
}

.routes .form-check {
  @include sm {
    padding-left: 1em;
  }
}
.form-check-input:checked {
  background-color: $secondaryColor !important;
  border-color: $secondaryColor !important;
}

.v-select {
  width: 100%;
}
.departure__err .vs__search,
.arrivals__err .vs__search {
  line-height: 0;
  font-size: 0em;
  margin: 0;
}

.vs--searchable .vs__dropdown-toggle {
  // min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem;

  border-radius: 0.3rem;
  @media (max-width: 767px) {
    padding: 0.3rem;
  }
}

.vs__search,
.vs__search:focus {
  @media (max-width: 1199px) {
    font-size: 0.9rem;
  }
}

.vs__fade-enter-active,
.vs__fade-leave-active {
  transition: none;
}
.input__selected {
  color: #212529;
  max-height: 14em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  @media (max-width: 1199px) {
    font-size: 0.9rem;
  }
}

// range slider

.box-minmax {
  margin-top: 20px;
  width: 540px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: $secondaryColor;
  span:first-child {
    margin-left: 10px;
  }
  @include lg {
    width: 320px;
  }
  @include md {
    width: 300px;
  }
  @include sm {
    width: 100%;
  }
}

.rangeSlider__container {
  margin-bottom: 50px;

  @include sm {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.rs-range {
  margin-top: 23px;
  width: 100%;
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: $secondaryColor;
    border-radius: 0px;
    border: 0px solid #010101;
  }
  &::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: $secondaryColor;
    border-radius: 0px;
    border: 0px solid #010101;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    border: 1px solid $secondaryColor;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 42px;
    width: 22px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
  }
  &::-moz-range-thumb {
    box-shadow: none;
    border: 0px solid $secondaryColor;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 42px;
    width: 22px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
  }
  &::-moz-focus-outer {
    border: 0;
  }
}
.rs-label {
  position: relative;
  transform-origin: center center;
  display: block;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  font-weight: bold;

  box-sizing: border-box;
  border: 2px solid $secondaryColor;

  margin-left: -7%;

  color: $secondaryColor;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 1rem;
  font-weight: 600;
  transition: margin 0.5s;
  @include lg {
    margin-left: -13.5%;
  }
  @include sm {
    // margin-left: -38px;
  }
  @include xs {
    // margin-left: -28px;
    font-size: 1rem;
  }

  &::after {
    content: "USD";
    display: block;
    font-size: 0.75rem;
    letter-spacing: 0.07em;
    margin-top: -2px;
    @include xs {
      // font-size: 19px;
    }
  }
}
.rs-label.min {
  // margin-left: -36px;
  @include lg {
    margin-left: -12%;
  }
  @include md {
    margin-left: -11.5%;
  }
  @include sm {
    margin-left: -8.5%;
  }
  @include xs {
    margin-left: -38px;
  }
}
.rs-label.max {
  margin-left: -7.5%;
  @include lg {
    margin-left: -10.2%;
  }
  @include sm {
    margin-left: -8.2%;
  }
}

.arrival-selected .vs__search,
.arrival-selected .vs__search:focus,
.departure-selected .vs__search,
.departure-selected .vs__search:focus {
  width: 0;
  height: 0;
  margin: 0;
}

.arrival-closed .vs__search,
.arrival-closed .vs__search:focus,
.departure-closed .vs__search,
.departure-closed .vs__search:focus {
  width: 0;
  height: 25px;
  margin: 0;
}
.btn-primary {
  background-color: $secondaryColor !important;
  color: white !important;
  margin: auto;
  display: block;
  font-weight: 600 !important;
  height: 40px;

  @include lg {
    font-size: 0.9rem !important;
  }

  &:hover {
    background: $primaryColor !important;
    color: white !important;
  }
  &:focus {
    border: none !important;
  }
}
.btn {
  border: none !important;
  padding: 0.475rem 0.75rem !important;
}

.btn-outline-primary {
  border-width: 1px !important;
  border-style: solid !important;
}
.btn:focus {
  border: none !important;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}
</style>
