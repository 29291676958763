<template>
  <div class="notifications">
    <h1 class="notifications__heading">Notifications</h1>
    <div class="notifications__container notifications__content">
      <h4>Currently all deals are sent via email. More options coming soon.</h4>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$headingColor: #5f4635;
$color1: #ff3647;

.notifications {
  margin: 30px 0;
  &__heading {
    color: $headingColor;
    font-size: 1.35rem;
  }
  &__content {
    font-size: 1.3rem;
    color: $headingColor;
  }
  &__container {
    box-shadow: 0px 0px 3px 1px #dfe3ec;
    padding: 30px 30px 12px;
    @media (max-width: 991px) {
      padding: 20px 15px 12px;
    }
  }
}
</style>
