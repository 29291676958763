<template>
    <div>
        <div v-if="$auth.isAuthenticated">
            <div class=sub_box>
                <br /><br />
                <span class="sub_header">Pick a sub text </span>
                <br /><br />
                <div class=row>
                    <div class=column>
                        <div class=card>
                            Silver
                            <br/>
                            <br/>
                            <input type="radio" name="subscription" value="10" id="sub_10" v-model="sub_value">
                        </div>
                    </div>
                    <div class=column>
                        <div class=card>
                            Gold
                            <br/>
                            <br/>
                            <input type="radio" name="subscription" value="20" id="sub_20" v-model="sub_value">
                        </div>
                    </div>
                </div>
                <div class=row>
                    <div class=column_button>
                        <input type="button" @click="addSubscription" value="Submit">
                    </div>
                    <br /><br /><br />
                </div>
            </div>
        </div>
        <div v-else>
            <span>Please login in order to start a new subscription.</span>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Subscription",
  props: {
      auth_id: String,
      sub_active: Boolean,
      sub_value: Number
  },
  created(){
      //console.log("Sub: Access Token: ", this.auth_access);
      //console.log("Sub: ID Claim: ", this.auth_id_claim);
  },
  methods: {
      addSubscription(prod_id){

        var jsonString = JSON.stringify("")
        var postHeader = ""
        console.log(prod_id)

        axios
        .post(process.env.VUE_APP_API_URL + "/subscription", jsonString, {
          headers: postHeader,
        })
        .then(
          (response) => this.activities.push(response.data),
          this.$forceUpdate()
        )
        .catch(function (error) {
          console.log("oh no! Axios error:", error);
        });
      }
  }
};
</script>

<style>

* {
  box-sizing: border-box;
}

.sub_box {
    background: rgb(247, 241, 239);
    align-content: center;
    justify-content: center;
}

.sub_header{
    align-content: center;
    justify-content: center;
    width: 100%;
}


body {
  font-family: Arial, Helvetica, sans-serif;
}

.column {
  float: left;
  width: 35%;
  padding: 50px 10px;
}

.column_button {
    float: left;
    width: 100px;
    padding: 0 10px;
}

/* Remove extra left and right margins, due to padding in columns */
.row {margin: 0, -5px; align-content:center; justify-content: center;}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 16px;
  text-align: center;
  background-color: white;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .column-pad {
      width: 0%;
  }
}

</style>