<template>
  <div id="app" class="d-flex flex-column h-100 position-relative">
    <nav-bar />
    <div :class="{ container: $route.path !== '/' }" class="flex-grow-1">
      <error />
      <div class="mt-1">
        <router-view />
      </div>
    </div>
    <footer class="p-0 footer m-0">
      <div class="footer__container p-3 pt-5">
        <div class="container pb-4">
          <div class="row align-items-start">
            <div class="col-md-4 col-12 mb-md-0 mb-4">
              <div class="text-md-start text-center">
                <img
                  width="200"
                  class="img-fluid"
                  src="@/assets/logo.png"
                  alt=""
                />
                <p class="footer__logotext mt-3">Udderly Sweet Travel Deals</p>
              </div>
            </div>

            <div
              class="col-md-8 col-12 d-flex justify-content-lg-start justify-content-md-end align-items-baseline justify-content-center"
            >
              <ul class="list-unstyled text-start mb-0">
                <h4>About</h4>
                <li class="mb-2">
                  <router-link class="text-muted" to="/privacy">
                    Privacy Policy
                  </router-link>
                </li>

                <li class="mb-2">
                  <router-link class="text-muted" to="/termsandconditions"
                    >Terms and Conditions</router-link
                  >
                </li>
              </ul>
              <ul class="list-unstyled text-start mb-0 ps-5">
                <h4>Support</h4>
                <li class="mb-2">
                  <router-link to="/faq" class="text-muted">FAQs</router-link>
                </li>
              </ul>
            </div>
          </div>
          <hr class="footer__hr" />
          <div
            class="text-center d-flex flex-lg-row flex-column justify-content-between align-items-center footer__lowerContainer"
          >
            <p class="mb-0 footer__text">
              Made in CLT with Love - Copyright 2022 Brown Cow Travel
            </p>
            <ul class="d-flex flex-md-row flex-column m-md-0 mt-3 ps-0">
              <li class="">
                <router-link class="text-muted" to="/privacy">
                  Privacy Policy
                </router-link>
              </li>

              <li class="ms-md-3 mx-0">
                <router-link class="text-muted" to="/termsandconditions"
                  >Terms and Conditions</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>

    <div class="cookie_container" v-if="showCookie == true">
      <div class="d-flex justify-content-between">
        <div class="cookie_container_msg">
          We use cookies on our website to give you the most relevant experience
          by remembering your preferences and repeat visits. By clicking
          “Accept”, you consent to the use of ALL the cookies.<br />
        </div>
        <div class="cli-bar-btn_container">
          <button
            @click="setCookieAlert"
            class="btn"
            style="
              display: inline-block;
              color: rgb(255, 255, 255);
              background-color: #ff3647;
            "
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import Error from "./components/Error";
import { getInstance } from "./auth/authWrapper";
//image imports
import favicon from "@/assets/images/BCT-Fav-Icon-512.png";
import opengraphpreview from "@/assets/images/open-graph-preview-image2.png";

// import axios from "axios";
export default {
  components: {
    NavBar,
    Error,
    favicon,
    opengraphpreview
  },
  created() {
    console.log("APP RUNNING ----------");
    var instance = getInstance();
    let auth;
    instance.$watch("loading", async (loading) => {
      if (loading === false) {
        auth = this.$auth;
        if (auth.user && auth.isAuthenticated && auth.user.sub) {
          // this.$store.state.commit()
          console.log("auth", auth);
          localStorage.setItem("isAuthenticated", auth.isAuthenticated);
          this.$store.commit("setAuth", auth.isAuthenticated);
          this.$store.commit("setUserId", auth.user.sub.toString());

          if (
            !auth.user["https://app.browncowtravel.com/SubMax"] ||
            auth.user["https://app.browncowtravel.com/SubMax"] == 0
          ) {
            this.$store.commit("SetShowNavLinks", false);
            this.$router.push({ path: "/register", query: { step: 2 } });
          }

          if (auth.user["https://app.browncowtravel.com/SubMax"]) {
            let submax = auth.user["https://app.browncowtravel.com/SubMax"];

            if (submax == 20) {
              localStorage.setItem("allowedsaves", 10);
              this.$store.commit("SetShowNavLinks", true);
              this.$store.commit("SetSubMax", submax);
              if (this.$route.path == "/") {
                this.$router.push("/deals");
              }

              return;
            } else if (submax == 10) {
              localStorage.setItem("allowedsaves", 5);
              this.$store.commit("SetShowNavLinks", true);
              this.$store.commit("SetSubMax", submax);
              if (this.$route.path == "/") {
                this.$router.push("/deals");
              }
              return;
            }
          }
        }
      }
    });
  },
  mounted() {
    let cookievalue = localStorage.getItem("showcookieAlert");
    if (cookievalue) {
      this.showCookie = cookievalue == "true" ? true : false;
    } else {
      this.showCookie = true;
    }
  },
  data() {
    return {
      showCookie: false,
    };
  },
  methods: {
    setCookieAlert() {
      this.showCookie = false;
      localStorage.setItem("showcookieAlert", this.showCookie);
    },
  },
  metaInfo() {
        return { 
            title: "Brown Cow Travel - Hack. Travel. Live.",
            charset: "utf-8",
            link: [
              {rel: "icon", href: `${favicon}?fit=32%2C32&#038;ssl=1`, sizes: "32x32"},
              {rel: "icon", href: `${favicon}?fit=192%2C192&#038;ssl=1`, sizes: "192x192"},
              {rel: "apple-touch-icon", href: `${favicon}?fit=180%2C180&#038;ssl=1`}
            ],
            meta: [
                { 'http-equiv': "X-UA-Compatible", content: "IE=edge"},
                { name: 'description', content:  'Hack. Travel. Live.'},
                { name: 'viewport', content: 'width=device-width,initial-scale=1.0'},
                { name: 'robots', content: 'index,follow'} ,
                { name: 'msapplication-TileImage', content: `${favicon}?fit=270%2C270&#038;ssl=1`},
                { name: 'twitter:title', content: '"Brown Cow Travel'},
                { name: 'twitter:description', content: 'Hack. Travel. Live.'},
                { name: 'twitter:image', content: `${opengraphpreview}`},
                { name: 'twitter:card', content: 'summary_large_image'},
                { property: 'og:title', content: "Brown Cow Travel - Hack. Travel. Live."},
                { property: 'og:description', content: "Hack. Travel. Live."},
                { property: 'og:site_name', content: 'Brown Cow Travel'},
                { property: 'og:type', content: 'website'},
                { property: 'og:determiner', content: 'the'},
                { property: 'og:locale', content: 'en_US'},
                { property: 'og:url', content: 'https://www.browncowtravel.com/'},
                { property: 'og:updated_time', content: '2022-11-05T11:25:25-05:00'},
                { property: 'og:image', content: `${opengraphpreview}`},
                { property: 'og:image:secure_url', content: `${opengraphpreview}`},
                { property: 'og:image:width', content: '1200'},
                { property: 'og:image:height', content: '481'},
                { property: 'og:image:alt', content: 'Brown Cow Travel'},
                { property: 'og:image:type', content: 'image/png'}
            ]
        }
    }
};
</script>

<style lang="scss">
$primaryColor: #5f4635;
$secondaryColor: #ff3647;
$thirdColor: #212529;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.cookie_container {
  background-color: rgb(255, 255, 255);
  color: rgb(51, 51, 51);
  box-shadow: 1px 2px 5px 0 #cfd6e7;
  font-family: inherit;
  bottom: 0px;
  position: fixed;
  display: block;
  padding: 14px 25px;
  z-index: 1000;
  width: 100%;
  &_msg {
    color: rgb(51, 51, 51);
    flex-basis: 70%;
    max-width: 70%;
    font-weight: 600;
  }
}

@mixin xs {
  @media (max-width: "575px") {
    @content;
  }
}

@mixin sm {
  @media (max-width: "767px") {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: "991px") {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: "1199px") {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: "1440") {
    @content;
  }
}

@mixin customMin($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

@mixin customMax($screen) {
  @media (max-width: $screen) {
    @content;
  }
}
.footer {
  box-shadow: none !important;
  background: none !important;
  &__hr {
    background: #ccc9c9;
  }

  &__logotext {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
  }
  &__text {
    color: #8c8c8c;
    font-family: "Poppins", sans-serif;
    @include xs {
      font-size: 0.95rem;
    }
  }
  &__lowerContainer {
    padding-top: 20px;
  }
}

.footer a {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  text-decoration: none !important;
}

.btn-primary {
  background-color: $secondaryColor !important;
  color: white !important;
  margin: auto;
  display: block;
  font-weight: 600 !important;
  height: 40px;

  @include lg {
    font-size: 0.9rem !important;
  }

  &:hover {
    background: $primaryColor !important;
    color: white !important;
  }
  &:focus {
    border: none !important;
  }
}
.btn {
  border: none !important;
  padding: 0.475rem 0.75rem !important;
}

.btn-outline-primary {
  border-width: 1px !important;
  border-style: solid !important;
}
.btn:focus {
  border: none !important;
}

a {
  text-decoration: none !important;
  color: #4d4d4d !important;
}
</style>
