<template>
  <div class="nav-container mb-3">
    <nav
      class="navbar navbar-expand-md bg-transparent py-0"
      :class="{ show: showMobileMenu }"
    >
      <div class="container pb-2">
        <!-- <div class="navbar-brand logo"></div> -->

        <div class="d-md-none d-block w-100">
          <div class="d-flex align-items-center justify-content-between">
            <img
              @click="$router.push('/')"
              class="img-fluid cursor-pointer"
              src="@/assets/logo.svg"
              alt="Brown Cow Travel Logo"
              width="250"
            />

            <div>
              <!-- BS5 changed from data-*  to data-bs-* -->
              <button
                class="btn navMobileBtn"
                @click="showMobileMenu = !showMobileMenu"
                type="button"
              >
                <font-awesome-icon
                  class="navMobileBtn__icon"
                  :icon="['fas', 'bars']"
                />
              </button>
            </div>
          </div>
          <!-- mobile menu -->
          <ul
            class="navbar-nav d-md-none d-flex mobileAuthNavBar"
            v-if="$auth.isAuthenticated"
          >
            <li class="nav-item">
              <span class="user-info">
                <img
                  :src="$auth.user.picture"
                  alt="User's profile picture"
                  class="nav-user-profile d-inline-block rounded-circle mr-3"
                  width="50"
                />
                <h6 class="d-inline-block mobileAuthNavBar__username">
                  {{ $auth.user.name }}
                </h6>
              </span>
            </li>
            <li class="sidebar__linkContainer" v-if="GetShowNavLinks == true">
              <div class="sidebar__iconContainer">
                <font-awesome-icon icon="tags" class="sidebar__icon" />
              </div>

              <button class="btn p-0 m-0 text-start" @click="goToDeals">
                <span class="sidebar__text">My Deals</span>
              </button>
            </li>

            <li
              class="sidebar__linkContainer"
              @click="show"
              v-if="GetShowNavLinks == true && GetSubMax < 20"
            >
              <div class="sidebar__iconContainer">
                <font-awesome-icon
                  icon="certificate"
                  class="cursor-pointer sidebar__icon"
                />
              </div>
              <span class="sidebar__text">Try Platinum Free</span>
            </li>

            <div
              v-for="(link, i) in links"
              :key="i"
              :class="[{ active: link.component == current }]"
              @click="changeComponent(link.component, link.name)"
            >
              <div
                class="d-flex align-items-baseline sidebar__linkContainer"
                :class="[link.icontextcontainerclass]"
              >
                <div class="sidebar__iconContainer">
                  <font-awesome-icon
                    class="sidebar__icon"
                    :icon="['fas', link.icon]"
                  />
                </div>
                <p class="m-0 sidebar__text">{{ link.text }}</p>
              </div>
              <div class="sidebar__statusContainer" v-if="link.status">
                <p
                  class="m-0 sidebar__statusContainer__status"
                  v-if="link.status"
                >
                  {{ link.status }}
                </p>
              </div>
            </div>
          </ul>
          <ul
            class="navbar-nav d-md-none d-flex mobileAuthNavBar unauthenticated"
            v-if="!$auth.isAuthenticated"
          >
            <button class="btn signinBtn mt-2" @click="signin">Login</button>
            <button class="btn primary-button signupBtn mt-2" @click="register">
              Sign up
            </button>
          </ul>
        </div>

        <div
          class="d-none d-md-flex justify-content-between align-items-center w-100"
        >
          <img
            @click="$router.push('/')"
            class="img-fluid cursor-pointer"
            src="@/assets/logo.svg"
            alt="Brown Cow Travel Logo"
            width="250"
          />
          <div v-if="$auth.isAuthenticated">
            <ul
              v-if="GetShowNavLinks == true"
              class="d-flex align-items-center navbarlinksContainer px-0 mb-0"
            >
              <li class="mx-xl-3 mx-lg-3 mx-0 px-2 px-lg-0">
                <router-link to="/deals">My Deals</router-link>
              </li>
              <li class="mx-xl-3 mx-lg-3 mx-0 px-2 px-lg-0">
                <router-link to="/user?name=savedroutes">Saved Routes</router-link>
              </li>
              <li class="mx-xl-3 mx-lg-3 mx-0 px-2 px-lg-0">
                <router-link
                  :to="{ name: 'userprofile', query: { name: 'share' } }"
                  >Share the love</router-link
                >
              </li>
              <li
                v-if="GetSubMax < 20"
                @click="show"
                class="mx-xl-3 px-2 px-lg-0 mx-lg-3 mx-0 cursor-pointer"
              >
                <span>Try Platinum Free</span>
              </li>
            </ul>
          </div>
          <div v-if="!$auth.isAuthenticated" class="d-flex align-items-center">
            <button class="btn mx-2 signinBtn" @click="signin">Login</button>
            <button class="btn mx-2 signupBtn" @click="register">
              Sign up
            </button>
          </div>
          <div v-if="$auth.isAuthenticated" class="d-flex align-items-center">
            <ul class="navbar-nav">
              <li
                v-if="
                  !$auth.isAuthenticated &&
                  !$auth.loading &&
                  $route.path !== '/'
                "
                class="nav-item"
              >
                <button
                  id="qsLoginBtn"
                  class="btn btn-primary btn-margin"
                  @click.prevent="login"
                >
                  Login
                </button>
              </li>

              <li class="nav-item dropdown" v-if="$auth.isAuthenticated">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="profileDropDown"
                  data-bs-toggle="dropdown"
                  @click="showdesktopDropdown = !showdesktopDropdown"
                >
                  <img
                    :src="$auth.user.picture"
                    alt="User's profile picture"
                    class="nav-user-profile rounded-circle"
                    width="50"
                  />
                </a>
                <ul
                  class="navbar-nav d-flex desktop"
                  v-if="$auth.isAuthenticated"
                  :class="{ show: showdesktopDropdown }"
                >
                  <li class="nav-item">
                    <span class="user-info">
                      <img
                        :src="$auth.user.picture"
                        alt="User's profile picture"
                        class="nav-user-profile d-inline-block rounded-circle mr-3"
                        width="50"
                      />
                      <h6 class="d-inline-block mobileAuthNavBar__username">
                        {{ $auth.user.name }}
                      </h6>
                    </span>
                  </li>

                  <div
                    v-for="(link, i) in desktopLinks"
                    :key="i"
                    :class="[
                      link.mainclass,
                      { active: link.component == current },
                    ]"
                    @click="changeComponentDesktop(link.component, link.name)"
                  >
                    <div
                      class="d-flex w-100 align-items-baseline"
                      :class="[link.icontextcontainerclass]"
                    >
                      <div class="sidebar__iconContainer">
                        <font-awesome-icon
                          class="sidebar__icon"
                          :icon="['fas', link.icon]"
                        />
                      </div>
                      <p class="m-0 sidebar__text">{{ link.text }}</p>
                    </div>
                    <div class="sidebar__statusContainer" v-if="link.status">
                      <p
                        class="m-0 sidebar__statusContainer__status"
                        v-if="link.status"
                      >
                        {{ link.status }}
                      </p>
                    </div>
                  </div>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul
            class="navbar-nav d-md-none"
            v-if="!$auth.isAuthenticated && !$auth.loading"
          >
            <button
              id="qsLoginBtn"
              class="btn btn-primary btn-block"
              @click="login"
            >
              Log in
            </button>
          </ul>
        </div>
      </div>
    </nav>
    <modal
      classes="routes__modalContainer"
      name="navplanmodal"
      :width="'400px'"
      :height="'650px'"
      :adaptive="true"
    >
      <div
        class="position-relative d-flex justify-content-center p-5 text-center w-100"
      >
        <font-awesome-icon
          class="routes__modal__close"
          @click="hide"
          :icon="['fas', 'times']"
        />

        <div class="text-center text-black bg-white rounded">
          <h3 class="mb-2 fs-1 fw-bold">Platinum</h3>
          <span class="fs-1 fw-bold">$3 per month</span>
          <p class="mt-1">billed monthly after FREE trial</p>
          <div class="my-4 py-2 d-flex justify-content-center">
            <ul class="list-unstyled text-start mb-0">
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>7 Day Free Trial</span>
              </li>
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>10 Saved Flight Searches</span>
              </li>
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>Economy, Business and First-Class Seats</span>
              </li>
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>Domestic and International Flights</span>
              </li>
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>Special Deals from Home Airports</span>
              </li>
            </ul>
          </div>
          <div>
            <button
              class="btn btn-primary px-5 d-block mt-4 ml-0 mr-0"
              @click.prevent="selectedPackage('platinum')"
            >
              Select
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "NavBar",

  data() {
    return {
      showdesktopDropdown: false,
      showMobileMenu: false,
      desktopLinks: [],

      links: [
        {
          icon: "user-circle",
          text: "Your Profile",
          mainclass: "sidebar__linkContainer",
          component: "Profile",
          name: "profile",
        },
        {
          icon: "plane",
          text: "Saved Routes",
          mainclass: "sidebar__linkContainer",
          component: "Routes",
          name: "savedroutes",
        },

        {
          icon: "bell",
          text: "Notifications",
          mainclass: "sidebar__linkContainer",
          component: "Notifications",
          name: "notifications",
        },
        {
          icon: "credit-card",
          text: "Billing",
          mainclass: "sidebar__linkContainer",
          component: "Billing",
          name: "billing",
        },
        {
          icon: "heart",
          text: "Share the Love",
          mainclass: "sidebar__linkContainer",
          component: "Share",
          name: "share",
        },

        {
          icon: "info-circle",
          text: "Support",
          mainclass: "sidebar__linkContainer",
          component: "Faq",
          name: "faq",
        },

        {
          icon: "sign-out-alt",
          text: "Logout",
          mainclass: "sidebar__linkContainer",
          component: null,
          name: null,
        },
      ],
    };
  },
  computed: {
    current() {
      return this.$store.state.current;
    },
    GetShowNavLinks() {
      return this.$store.state.showNavLinks;
    },
    GetSubMax() {
      return this.$store.state.subMax;
    },
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
  },
  watch: {
    GetShowNavLinks(value) {
      if (value) {
        this.desktopLinks = [
          {
            icon: "user-circle",
            text: "Your Profile",
            mainclass: "sidebar__linkContainer",
            component: "Profile",
            name: "profile",
          },
          {
            icon: "plane",
            text: "Saved Routes",
            mainclass: "sidebar__linkContainer",
            component: "Routes",
            name: "savedroutes",
          },

          {
            icon: "bell",
            text: "Notifications",
            mainclass: "sidebar__linkContainer",
            component: "Notifications",
            name: "notifications",
          },
          {
            icon: "credit-card",
            text: "Billing",
            mainclass: "sidebar__linkContainer",
            component: "Billing",
            name: "billing",
          },

          {
            icon: "info-circle",
            text: "Support",
            mainclass: "sidebar__linkContainer",
            component: "Faq",
            name: "faq",
          },

          {
            icon: "sign-out-alt",
            text: "Logout",
            mainclass: "sidebar__linkContainer",
            component: null,
            name: null,
          },
        ];
        this.links = [
          {
            icon: "user-circle",
            text: "Your Profile",
            mainclass: "sidebar__linkContainer",
            component: "Profile",
            name: "profile",
          },
          {
            icon: "plane",
            text: "Saved Routes",
            mainclass: "sidebar__linkContainer",
            component: "Routes",
            name: "savedroutes",
          },

          {
            icon: "bell",
            text: "Notifications",
            mainclass: "sidebar__linkContainer",
            component: "Notifications",
            name: "notifications",
          },
          {
            icon: "credit-card",
            text: "Billing",
            mainclass: "sidebar__linkContainer",
            component: "Billing",
            name: "billing",
          },
          {
            icon: "heart",
            text: "Share the Love",
            mainclass: "sidebar__linkContainer",
            component: "Share",
            name: "share",
          },

          {
            icon: "info-circle",
            text: "Support",
            mainclass: "sidebar__linkContainer",
            component: "Faq",
            name: "faq",
          },

          {
            icon: "sign-out-alt",
            text: "Logout",
            mainclass: "sidebar__linkContainer",
            component: null,
            name: null,
          },
        ];
      } else {
        this.desktopLinks = [
          {
            icon: "sign-out-alt",
            text: "Logout",
            mainclass: "sidebar__linkContainer",
            component: null,
            name: null,
          },
        ];
        this.links = [
          {
            icon: "sign-out-alt",
            text: "Logout",
            mainclass: "sidebar__linkContainer",
            component: null,
            name: null,
          },
        ];
      }
    },
  },
  methods: {
    register() {
      localStorage.setItem("step", 1);
      this.$store.commit("setStep", 1);
      this.showMobileMenu = false;
      this.showdesktopDropdown = false;

      this.$router.push("/register?step=1");
    },
    signin() {
      this.$auth.loginWithRedirect();
      // this.$auth.loginWithRedirect({
      //   redirect_uri: process.env.VUE_APP_REDIRECT_URL,
      // });
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.showdesktopDropdown = false;
      }
      if (
        e.target.classList.contains("navbar-nav.desktop") ||
        e.target.classList.contains("nav-container") ||
        e.target.classList.contains("navbar")
      ) {
        this.showdesktopDropdown = false;
      }
    },
    selectedPackage(pkg) {
      let data = {
        pkg,
      };
      this.checkout(data);
    },
    async checkout(pkgData) {
      let priceId = process.env.VUE_APP_PRICEID_PLATINUM;

      let data = {
        email: this.$auth.user.email,
        priceId,
        id: this.$auth.user.sub,
        package: pkgData.pkg,
        action: "update",
        subscription: this.$store.state.subscriptionId,
        stripeid:
          this.$auth.user["https://app.browncowtravel.com/SubStripeCust"],
      };

      const res = await axios.post(
        `${process.env.VUE_APP_PAYMENT_API}/payment`,
        data
      );

      if (res.data.subscriptionId) {
        this.$store.commit("setStripeData", {
          subscriptionId: res.data.subscriptionId,
          clientSecret: res.data.clientSecret ? res.data.clientSecret : "",
        });
        let step = 4;
        this.$store.commit("setStep", step);
        localStorage.setItem("step", step);
        localStorage.setItem("allowedsaves", 10);
        let subMax = this.$auth.user["https://app.browncowtravel.com/SubMax"];

        window.location.href = `/user?name=billing&subMax=${subMax}&subscriptionId=${res.data.subscriptionId}`;
      }
      this.hide();
    },

    goToDeals() {
      if (this.showMobileMenu) {
        this.showMobileMenu = false;
      }
      this.$router.push("/Deals");
    },

    show() {
      if (
        localStorage.getItem("allowedsaves") &&
        localStorage.getItem("allowedsaves") == 10
      ) {
        return;
      }

      this.$modal.show("navplanmodal");
      if (this.showMobileMenu) {
        this.showMobileMenu = false;
      }
    },
    hide() {
      this.$modal.hide("navplanmodal");
    },
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      localStorage.removeItem("step");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("vuex");
      localStorage.removeItem("_secure__ls__metadata");
      localStorage.removeItem("allowedsaves");
      this.$auth.logout();
      this.$router.push({ path: "/" });
    },
    changeComponent(comp, name) {
      if (comp == null) {
        localStorage.removeItem("step");
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("vuex");
        localStorage.removeItem("_secure__ls__metadata");
        localStorage.removeItem("allowedsaves");
        this.$auth.logout();
        this.$router.push({ path: "/" });
      }
      if (comp) {
        this.$store.commit("setCurrent", comp);
        this.$router.push({
          name: "userprofile",
          query: { name },
        });
      }
      this.showMobileMenu = false;
    },

    changeComponentDesktop(comp, name) {
      if (comp == null) {
        localStorage.removeItem("step");
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("vuex");
        localStorage.removeItem("_secure__ls__metadata");
        localStorage.removeItem("allowedsaves");
        this.$auth.logout();
        this.$router.push({ path: "/" });
      }
      if (comp) {
        this.$store.commit("setCurrent", comp);
        this.$router.push({
          name: "userprofile",
          query: { name },
        });
      }
      this.showdesktopDropdown = false;
    },
  },

  mounted() {
    document.addEventListener("click", this.close);

    if (this.GetShowNavLinks) {
      this.desktopLinks = [
        {
          icon: "user-circle",
          text: "Your Profile",
          mainclass: "sidebar__linkContainer",
          component: "Profile",
          name: "profile",
        },
        {
          icon: "plane",
          text: "Saved Routes",
          mainclass: "sidebar__linkContainer",
          component: "Routes",
          name: "savedroutes",
        },

        {
          icon: "bell",
          text: "Notifications",
          mainclass: "sidebar__linkContainer",
          component: "Notifications",
          name: "notifications",
        },
        {
          icon: "credit-card",
          text: "Billing",
          mainclass: "sidebar__linkContainer",
          component: "Billing",
          name: "billing",
        },

        {
          icon: "info-circle",
          text: "Support",
          mainclass: "sidebar__linkContainer",
          component: "Faq",
          name: "faq",
        },

        {
          icon: "sign-out-alt",
          text: "Logout",
          mainclass: "sidebar__linkContainer",
          component: null,
          name: null,
        },
      ];
      this.links = [
        {
          icon: "user-circle",
          text: "Your Profile",
          mainclass: "sidebar__linkContainer",
          component: "Profile",
          name: "profile",
        },
        {
          icon: "plane",
          text: "Saved Routes",
          mainclass: "sidebar__linkContainer",
          component: "Routes",
          name: "savedroutes",
        },

        {
          icon: "bell",
          text: "Notifications",
          mainclass: "sidebar__linkContainer",
          component: "Notifications",
          name: "notifications",
        },
        {
          icon: "credit-card",
          text: "Billing",
          mainclass: "sidebar__linkContainer",
          component: "Billing",
          name: "billing",
        },
        {
          icon: "heart",
          text: "Share the Love",
          mainclass: "sidebar__linkContainer",
          component: "Share",
          name: "share",
        },

        {
          icon: "info-circle",
          text: "Support",
          mainclass: "sidebar__linkContainer",
          component: "Faq",
          name: "faq",
        },

        {
          icon: "sign-out-alt",
          text: "Logout",
          mainclass: "sidebar__linkContainer",
          component: null,
          name: null,
        },
      ];
    } else {
      this.desktopLinks = [
        {
          icon: "sign-out-alt",
          text: "Logout",
          mainclass: "sidebar__linkContainer",
          component: null,
          name: null,
        },
      ];
      this.links = [
        {
          icon: "sign-out-alt",
          text: "Logout",
          mainclass: "sidebar__linkContainer",
          component: null,
          name: null,
        },
      ];
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style lang="scss">
$headingColor: #5f4635;
$linkColor: #4d4d4d;
$secondaryColor: #ff3647;
$mainColor: #ff3647;
$primaryColor: #5f4635;

@mixin xs {
  @media (max-width: "575px") {
    @content;
  }
}

@mixin sm {
  @media (max-width: "767px") {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: "991px") {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: "1199px") {
    @content;
  }
}

.navbar {
  margin-top: 28px;
  box-shadow: none !important;
  &__modal {
    &__close {
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
      font-size: 1.25rem;
    }

    &__btn {
      color: $headingColor;

      border-color: $headingColor;
    }
  }
}

.navbar-nav.desktop {
  position: absolute;
  top: 60px;
  right: -50px;
  background: #fff;
  box-shadow: 1px 2px 5px 0 #cfd6e7 !important;

  margin: 1.5em 0 0 0;
  padding: 2em 2.5rem !important;
  display: flex !important;
  // justify-content: space-between !important;
  flex-direction: column !important;
  z-index: 90000;
  opacity: 0;
  pointer-events: none;
  user-select: none;
  transition: all 0.5s;
  @include lg {
    right: -20px;
  }
}
.navbar-nav.desktop .user-info {
  display: flex;
  align-items: center;
  h6 {
    padding-left: 8px;
  }
}
.navbar-nav.desktop.show {
  opacity: 1;
  pointer-events: auto;
  user-select: auto;
  top: 40px;
}
.navbar-nav.desktop .sidebar {
  &__text {
    color: #5f4635;
    font-size: 1.2rem;
    // padding-left: 10px;
    font-weight: 600;
    @media (max-width: 1199px) {
      font-size: 1.1rem;
      // padding-left: 16px;
    }
  }
  &__icon {
    color: #5f4635;
    font-size: 1.2rem;
  }

  &__linkContainer {
    cursor: pointer;
    position: relative;
    transition: all 0.5s;
    margin: 0 !important;
    margin-top: 1rem !important;
    display: flex;
    align-items: center;
  }
  &__linkContainer:hover {
    &__text,
    &__icon {
      color: $secondaryColor !important;
    }
  }
  &__iconTextContainer {
    flex-basis: 100%;
    max-width: 100%;
  }

  &__iconTextContainer.active {
    color: $secondaryColor;
    transition: all 0.5s;
  }
  &__iconContainer {
    flex-basis: 18%;
    max-width: 18%;
    @media (max-width: 575px) {
      flex-basis: 12%;
      max-width: 12%;
    }
    @media (max-width: 460px) {
      flex-basis: 14%;
      max-width: 14%;
    }
    @media (max-width: 400px) {
      flex-basis: 15%;
      max-width: 15%;
    }
    @media (max-width: 375px) {
      flex-basis: 16%;
      max-width: 16%;
    }
  }

  &__linkContainer.active &__text,
  &__linkContainer.active &__icon {
    color: $secondaryColor;
  }
}

.mobileAuthNavBar .sidebar {
  &__text {
    color: #5f4635;
    font-size: 1.2rem;
    // padding-left: 10px;
    font-weight: 600;
    @media (max-width: 1199px) {
      font-size: 1.1rem;
      // padding-left: 16px;
    }
  }
  &__icon {
    color: #5f4635;
    font-size: 1.2rem;
    width: 19.19px;
  }

  &__linkContainer {
    cursor: pointer;
    position: relative;
    transition: all 0.5s;
    margin: 0 !important;
    margin-top: 0.7rem !important;
    align-items: baseline !important;
  }
  &__linkContainer:hover &__linkContainer__text,
  &__linkContainer:hover &__linkContainer__icon {
    color: $secondaryColor !important;
  }
  &__iconTextContainer {
    flex-basis: 90%;
    max-width: 90%;
  }

  &__iconTextContainer.active {
    color: $secondaryColor;
    transition: all 0.5s;
  }
  &__iconContainer {
    flex-basis: 7%;
    max-width: 7%;
    display: flex;
    align-items: center;
    align-self: center;
    @media (max-width: 575px) {
      flex-basis: 8%;
      max-width: 8%;
    }
    @media (max-width: 460px) {
      flex-basis: 10%;
      max-width: 10%;
    }
    @media (max-width: 400px) {
      flex-basis: 11%;
      max-width: 11%;
    }
    @media (max-width: 375px) {
      flex-basis: 12%;
      max-width: 12%;
    }
  }

  &__linkContainer.active::after {
    content: "";
    position: absolute;

    height: 26px;

    background: $secondaryColor;

    right: -42px;
    top: 11%;
    transform: translateY(-50%);
    width: 4px;
    height: 26px;
    @media (max-width: 400px) {
      right: -23px;
    }
    @media (max-width: 320px) {
      right: -15px;
    }
  }

  &__linkContainer.active &__text,
  &__linkContainer.active &__icon {
    color: $secondaryColor;
  }
}
.navMobileBtn {
  border-radius: 5px !important;
  border: 1px solid $secondaryColor !important;
  // padding: 0.6rem 0.8rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &__icon {
    color: $headingColor;
    font-size: 1.2rem;
  }
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}
.mobileAuthNavBar {
  height: auto;
  justify-content: space-between;
  box-shadow: 0 -1px 5px 0 #dfe3ec !important;
  padding: 2em 2.5rem !important;
  @media (max-width: 400px) {
    padding: 1.5em 1.3rem !important;
  }
  @media (max-width: 320px) {
    padding: 1.5em 0.8rem !important;
  }
  &__username {
    @media (max-width: 500px) {
      font-size: 0.9rem;
    }
  }
}
.nav-user-profile {
  @media (max-width: 575px) {
    max-width: 2.385rem;
  }
}
.navbar {
  max-height: 105px;
  transition: max-height 0.15s ease-out;

  @media (max-width: 767px) {
    overflow: hidden;
  }
}

.navbar.show {
  max-height: 1000px;
  transition: max-height 0.25s ease-in;
}

.navbar-expand-md .navbar-nav .dropdown-menu {
  right: 0;
}
.dropdown-profile {
  display: flex;
  align-items: center;
}
#qsLogoutBtn {
  display: flex;
  align-items: center;
}

.mobileAuthNavBar li {
  display: flex;
  align-items: center;
  color: $headingColor;
}

li {
  list-style-type: none;
}
.navbarlinksContainer {
  margin-right: 70px;

  @include md {
    margin-right: 0px;
  }
}

.router-link-active.router-link-exact-active {
  color: $mainColor !important;
}
.navbarlinksContainer li a,
.navbarlinksContainer li span {
  color: $linkColor !important;
  font-weight: bold !important;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1rem;
}
.navbarlinksContainer li a:hover,
.navbarlinksContainer li span:hover {
  color: $secondaryColor !important;
}
@media (max-width: 991px) {
  .navbarlinksContainer li a,
  .navbarlinksContainer li span {
    font-size: 0.875rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.navbar .router-link-exact-active {
  border: none;
}

.select-btn {
  color: rgba(121, 52, 25, 0.74) !important;
}
.select-btn:hover {
  color: rgba(189, 81, 38, 0.74) !important;
}

.sidebar__linkContainer:hover .sidebar__iconContainer svg {
  color: $secondaryColor !important;
}
.sidebar__linkContainer:hover .sidebar__text {
  color: $secondaryColor !important;
}
.primary-button {
  background-color: $secondaryColor !important;
  color: white !important;
  margin: auto;
  display: block;
  font-weight: 600 !important;
  @include lg {
    font-size: 0.9rem !important;
  }

  &:hover {
    background: $primaryColor !important;
    color: white !important;
  }
}

.signinBtn {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  color: #28344a !important;
}

.signupBtn {
  width: 152px !important;
  height: 56px !important;
  background: $mainColor !important;
  border-radius: 34px !important;
  color: white !important;
  padding: 0 !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 0.875rem;
  text-align: center;
  color: #fff9f8;
}
.signupBtn:hover {
  background: $primaryColor !important;
}
</style>
