<template>
  <div class="home__section4 container">
    <div class="row justify-content-between align-items-center">
      <div
        class="col-lg-6 col-12 d-flex d-sm-block flex-column align-items-sm-start align-items-center text-sm-start text-center"
      >
        <p class="home__section4__headingSmall">Simple Pricing</p>
        <h2 class="home__section4__heading my-0 text-capitalize">
          Choose a plan based on <br class="d-sm-block d-none" />
          your Travel Needs
        </h2>

        <p class="home__section4__text">
          Do you fly economy class, business or first? How many trips are you
          planning this year? These are the only two questions to answer to pick
          a convenient plan to fit your travel needs.
        </p>
        <button
          class="btn home__section4__btn"
          @click="$router.push('/register?step=1')"
        >
          Sign Up Now
        </button>
      </div>
      <div class="col-lg-6 col-12 mt-4">
        <div class="d-flex flex-column flex-sm-row align-items-center">
          <div
            v-for="card in cards"
            :key="card.title"
            class="home__section4__card"
            :class="card.class"
          >
            <h3 class="home__section4__card__title" v-html="card.title"></h3>
            <span
              class="home__section4__card__price"
              v-html="card.price"
            ></span>
            <div
              class="d-flex align-items-baseline mb-2"
              v-for="feature in card.features"
              :key="feature"
            >
              <img
                class="home__section4__card__check"
                src="@/assets/home/check.svg"
                alt=""
              />
              <span v-html="feature" class="home__section4__card__feature">
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          class: "gold",
          title: "Gold <br/> Package",
          price: "$1 per month",
          features: [
            "7 Day Free Trial",
            "5 Saved Flight Searches",
            "Economy Seat Pricing",
            "Domestic Flights",
            "International Flights",
            "Special Deals from <br class='d-xl-block d-none'/> Your Home Airports",
          ],
        },
        {
          class: "platinum",
          title: "Platinum <br/> Package",
          price: "$3 per month",
          features: [
            "7 Day Free Trial",
            "10 Saved Flight Searches",
            "Economy, Business, First",
            "Domestic Flights",
            "International Flights",
            "Special Deals from <br class='d-xl-block d-none'/> Your Home Airports",
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$headingColor: #2e2e2e;
$primaryColor: #5f4635;
$secondaryColor: #ff3647;
$textColor: #676767;
$whiteColor: #fffafa;
$secondaryLightColor: #f86061;
@mixin xs {
  @media (max-width: "575px") {
    @content;
  }
}

@mixin sm {
  @media (max-width: "767px") {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: "991px") {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: "1199px") {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: "1440") {
    @content;
  }
}

@mixin customMin($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

@mixin customMax($screen) {
  @media (max-width: $screen) {
    @content;
  }
}
.home__section4 {
  padding-bottom: 60px;
  font-family: "Poppins", sans-serif;
  // @include xs {
  //   padding-bottom: 122px;
  // }

  &__heading {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    color: $headingColor;
    padding-top: 10px;
    @include lg {
      font-size: 2.3rem;
    }
  }
  &__heading.alternate {
    color: $secondaryColor;
    padding-top: 0px;
  }
  &__headingSmall {
    font-weight: 500;
    font-size: 1.125rem;
    color: #f9ba45;
    margin-bottom: 0;
  }
  &__text {
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    color: $textColor;
    padding-top: 22px;
    padding-bottom: 20px;
  }
  &__btn {
    border-radius: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    text-align: center;
    color: $whiteColor !important;
    width: 201px;
    height: 70px;
    background: $secondaryLightColor;
    border-radius: 12px;
    @include lg {
      width: 191px;
      height: 60px;
    }
    @include xs {
      width: 181px;
      height: 50px;
      font-size: 1.15rem;
    }
  }
  &__btn:hover {
    color: $whiteColor !important;
  }
  &__card {
    background: #ffffff;
    box-shadow: 0px 40px 100px -25px rgba(0, 0, 0, 0.22);
    border-radius: 12px;
    width: 300px;
    height: 400px;
    padding-left: 25px;
    padding-top: 22px;
    padding-bottom: 10px;

    @include customMax(1400px) {
      padding-left: 15px;
    }

    @include lg {
      width: 400px;
      height: 400px;
      // padding-left: 15px;
      padding-right: 10px;
      padding-top: 15px;
    }
    @include md {
      height: 370px;
      padding-left: 18px;
    }
    @include xs {
      width: 285px;
      height: 360px;
    }
    &__title {
      text-align: center;
      font-family: "Inter", sans-serif;
      color: $headingColor;
      font-weight: 700;
      font-size: 2rem;
      @include customMax(1400px) {
        font-size: 1.7rem;
      }
    }
    &__price {
      text-align: center;
      font-family: "Inter", sans-serif;
      color: $secondaryColor;
      font-weight: 700;
      font-size: 2rem;
      display: block;
      @include customMax(1400px) {
        font-size: 1.6rem;
        padding-bottom: 6px;
      }
      @include lg {
        font-size: 1.5rem;
        padding-bottom: 6px;
      }
    }
    &__check {
      flex-basis: 20px;
      max-width: 20px;
      @include lg {
        flex-basis: auto;
        max-width: auto;
      }
    }
    &__feature {
      font-family: "Inter", sans-serif;
      color: $textColor;
      font-size: 1.125rem;
      padding-left: 5px;
      @include lg {
        font-size: 1rem;
      }
    }
  }
  &__card.gold {
    margin-right: 45px;
    @include customMax(1400px) {
      margin-right: 16px;
    }
    @include lg {
      margin-right: 15px;
    }
    @include xs {
      margin-right: 0px;
      margin-bottom: 15px;
    }
  }
}
</style>
