<template>
  <div class="home__section2">
    <div class="container">
      <p class="home__section2__smallheading">What we do</p>
      <h3 class="home__section2__text text-capitalize">
        Finding you the best flight <br class="d-sm-block d-none" />
        prices across all the major <br class="d-sm-block d-none" />
        airlines
      </h3>
      <div class="row">
        <div
          class="col-md-4 col-12 mt-3 mt-md-0"
          v-for="card in cards"
          :key="card.src"
        >
          <div class="position-relative home__section2__cardParent">
            <div
              class="home__section2__card d-flex flex-column justify-content-center align-items-center"
            >
              <img :src="card.src" alt="" />
              <h3 class="home__section2__card__heading">{{ card.heading }}</h3>
              <p class="home__section2__card__text">{{ card.text }}</p>
            </div>
            <div :class="card.class ? card.class : ''"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          src: require("../../assets/home/map.svg"),
          heading: "Multiple Destinations",
          text: "Enter multiple home and destination airport combinations to watch",
        },
        {
          src: require("../../assets/home/moneybag.svg"),
          heading: "7 days free trial",
          text: "Try us for 7 days to see the deals you can get to save $100s per flight",
          class: "home__section2__card alternate",
          classParent: "position-relative",
        },
        {
          src: require("../../assets/home/stamp.svg"),
          heading: "Deals Delivered",
          text: "Flight deals are delivered straight to your inbox and viewable in the app for your instant review",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin xs {
  @media (max-width: "575px") {
    @content;
  }
}

@mixin sm {
  @media (max-width: "767px") {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: "991px") {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: "1199px") {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: "1440") {
    @content;
  }
}

@mixin customMin($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

@mixin customMax($screen) {
  @media (max-width: $screen) {
    @content;
  }
}
$headingColor: #2e2e2e;
$primaryColor: #5f4635;
$secondaryColor: #ff3647;
$textColorLight: #676767;
$textColor: #2e2e2e;
$whiteColor: #fffafa;
$bg: #eff1f5;
.home__section2 {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
  background: $bg;
  font-family: "Poppins", sans-serif;

  &__tag {
    background: #8e562e;
    border-radius: 23px;
    width: 214px;
    height: 46px;
    color: white;
    padding-left: 21px;
    padding-right: 24px;
  }
  &__smallheading {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.125rem;
    color: $secondaryColor;
    // padding-top: 20px;
  }

  &__text {
    font-family: "Poppins", sans-serif;
    font-size: 2.5rem;
    color: $textColor;
    font-weight: 700;
    @include sm {
      font-size: 2.3rem;
    }
    @include xs {
      font-size: 2rem;
    }
  }
  &__cardParent {
    z-index: 100;
  }
  &__card {
    height: 310px;
    width: 300px;
    margin: 0 auto;
    margin-top: 25px;
    background: #ffffff;
    border-radius: 12px;
    padding: 46px 34px 30px 34px;
    z-index: 100;
    @include md {
      width: 220px;
    }
    @include sm {
      width: 260px;
    }
    &__heading {
      font-weight: 600;
      font-size: 1.375rem;
      text-align: center;
      color: $textColor;
      padding-top: 48px;
      padding-bottom: 14px;
      @include md {
        padding-top: 8px;
      }
    }
    &__text {
      font-weight: 400;
      font-size: 0.875rem;
      text-align: center;
      color: $textColorLight;
    }
  }
  &__card.alternate {
    position: absolute;
    width: 310px;
    height: 334.16px;
    left: 66.8px;
    top: -29.78px;
    z-index: -1;
    background: #ff3647;
    border-radius: 12px;
    transform: rotate(-3deg);
    @include lg {
      width: 270px;
      height: 324.16px;
      left: 27.8px;
      top: -29.78px;
      transform: rotate(-4deg);
    }
    @include md {
      width: 210px;
      height: 324.16px;
      left: 10.8px;
      top: -29.78px;
    }
    @include sm {
      width: 250px;
      height: 324.16px;
      left: 139.8px;
      top: -29.78px;
    }
    @include xs {
      left: 52%;
      transform: translateX(-50%) rotate(-4deg);
    }
  }
}
</style>
