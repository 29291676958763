<template>
  <section class="py-3 py-sm-4">
    <div class="container">
      <!--if adding third option, remove "d-flex justify-content-center" and add third option-->
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <div class="px-3 py-5 text-center bg-white rounded shadow-sm">
            <!-- Pulled this image
                <img class="mb-4 img-fluid mx-auto" width="75" height="75" src="metis-assets/illustrations/people.png" alt="" /> 
              -->
            <h3 class="mb-2 fs-1 fw-bold">Gold</h3>
            <span class="fs-1 fw-bold text-primary">$1 per month</span>
            <p class="mt-1 text-muted">billed monthly after FREE trial</p>
            <div class="my-4 py-2 d-flex justify-content-center">
              <ul class="list-unstyled text-start text-muted mb-0">
                <li class="mb-2">
                  <svg
                    class="me-2 text-success"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>7 Day Free Trial</span>
                </li>
                <li class="mb-2">
                  <svg
                    class="me-2 text-success"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>5 Saved Flight Searches</span>
                </li>
                <li class="mb-2">
                  <svg
                    class="me-2 text-success"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>Economy Seats</span>
                </li>
                <li class="mb-2">
                  <svg
                    class="me-2 text-success"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>Domestic and International Flights</span>
                </li>
                <li class="mb-2">
                  <svg
                    class="me-2 text-success"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>Special Deals from Home Airports</span>
                </li>
              </ul>
            </div>
            <input placeholder="COUPON" type="text" v-model="gold" />

            <button
              class="btn btn-primary px-5 d-block mt-4 ml-0 mr-0"
              @click.prevent="selectedPackage('gold')"
            >
              Select
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <div
            class="px-3 py-5 text-center text-black bg-white rounded shadow-sm"
          >
            <h3 class="mb-2 fs-1 fw-bold">Platinum</h3>
            <span class="fs-1 fw-bold">$3 per month</span>
            <p class="mt-1">billed monthly after FREE trial</p>
            <div class="my-4 py-2 d-flex justify-content-center">
              <ul class="list-unstyled text-start mb-0">
                <li class="mb-2">
                  <svg
                    class="me-2 text-success"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>7 Day Free Trial</span>
                </li>
                <li class="mb-2">
                  <svg
                    class="me-2 text-success"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>10 Saved Flight Searches</span>
                </li>
                <li class="mb-2">
                  <svg
                    class="me-2 text-success"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>Economy, Business and First-Class Seats</span>
                </li>
                <li class="mb-2">
                  <svg
                    class="me-2 text-success"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>Domestic and International Flights</span>
                </li>
                <li class="mb-2">
                  <svg
                    class="me-2 text-success"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>Special Deals from Home Airports</span>
                </li>
              </ul>
            </div>
            <input placeholder="COUPON" type="text" v-model="platinum" />
            <button
              class="btn btn-primary px-5 d-block mt-4 ml-0 mr-0"
              @click.prevent="selectedPackage('platinum')"
            >
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      gold: "",
      platinum: "",
      coupon: "",
    };
  },
  methods: {
    selectedPackage(pkg) {
      let data = {
        pkg,
      };
      if (this.gold || this.platinum) {
        if (this.gold) {
          this.coupon = this.gold;
        } else if (this.platinum) {
          this.coupon = this.platinum;
        }
        data.coupon = this.coupon;
      }
      this.$emit("checkout", data);
    },
  },
};
</script>

<style lang="scss" scoped>
$primaryColor: #5f4635;
$secondaryColor: #ff3647;
$thirdColor: #212529;

@mixin xs {
  @media (max-width: "575px") {
    @content;
  }
}

@mixin sm {
  @media (max-width: "767px") {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: "991px") {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: "1199px") {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: "1440") {
    @content;
  }
}

@mixin customMin($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

@mixin customMax($screen) {
  @media (max-width: $screen) {
    @content;
  }
}
.select-btn {
  margin-top: 10px !important;
  background-color: $secondaryColor !important;
  color: white !important;
  margin: auto;
  // display: block;
  font-weight: 600 !important;
  height: 40px;
  padding-left: 45px !important;
  padding-right: 45px !important;

  @include lg {
    font-size: 0.9rem !important;
  }

  &:hover {
    background: $primaryColor !important;
    color: white !important;
  }
  &:focus {
    border: none !important;
  }
}
// .select-btn:hover {
//   color: rgba(189, 81, 38, 0.74) !important;
// }
</style>
