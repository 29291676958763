<template>
  <div class="deals">
    <!-- latest deals matching your searches -->
    <h1 class="deals__heading text-center d-none d-md-block">
      {{ heading }}
    </h1>
    <h1 class="deals__heading alternate d-md-none d-block">
      {{ headingAlternate }}
    </h1>

    <p v-if="heading" class="deals__subheading text-center d-none d-md-block">
      <slot>
        Below are the most recent deals matching the
        <router-link
          :to="{ name: 'userprofile', query: { name: 'savedroutes' } }"
          >searches</router-link
        >
        you have setup
      </slot>
    </p>

    <div class="position-relative" v-if="!loading && slides.length > 0">
      <Swiper
        :ref="refName"
        class="swiper"
        :class="swiperClass"
        :options="swiperOption"
        @progress="getProgress"
      >
        <SwiperSlide v-for="slide in getSlides" :key="slide.id">
          <div
            class="card cursor-pointer align-items-start p-0 deals__card"
            @click="$router.push(`/trips/${slide.deal_id}`)"
          >
            <img
              class="deals__card__icon m-0"
              v-if="slide.budget == true || slide.budget == `true`"
              :src="budget"
            />

            <img
              v-if="slide.image"
              class="img-fluid w-100 deals__card__img"
              :src="slide.image"
            />
            <img
              v-else
              class="img-fluid w-100"
              src="../../assets/images/placeholder.jpg"
              alt="../../assets/images/placeholder.jpg"
            />
            <div class="deals__card__lowersection">
              <p class="text-start deals__card__lowersection__title mb-2">
                {{ slide.arrcity }}, {{ slide.arrcountry }}
              </p>
              <p class="text-start deals__card__lowersection__from mb-1">
                <font-awesome-icon
                  class="sidebar__icon"
                  :icon="['fas', 'plane']"
                />
                From:

                <span class="ps-1"
                  >{{ slide.depcity }} ({{ slide.departure }})</span
                >
              </p>
              <div
                class="d-flex deals__card__lowersection__monthPriceContainer justify-content-between"
              >
                <p class="deals__card__lowersection__availability mb-1">
                  Months: {{ slide.months }}
                </p>
                <p class="deals__card__lowersection__price text-start">
                  <span class="new ps-0"
                    >${{ Math.round(slide.total_amount) }}</span
                  >
                  <span class="old ps-2">
                    <s>${{ Math.round(slide.ave_price) }}</s>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <div :class="paginationClass" slot="pagination"></div>
      </Swiper>
      <div
        :class="[
          prevClass,

          {
            'swiper-button-disabled':
              hideLeftArrow && prevClass == 'swiper-button-prev2',
          },
        ]"
        slot="button-prev"
      >
        <font-awesome-icon :icon="['fas', 'arrow-circle-left']" />
      </div>
      <div
        :class="[
          nextClass,
          {
            'swiper-button-disabled':
              hideRightArrow && nextClass == 'swiper-button-next2',
          },
        ]"
        slot="button-next"
      >
        <font-awesome-icon :icon="['fas', 'arrow-circle-right']" />
      </div>
    </div>
    <div class="text-center" v-else>
      <div v-if="loading" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>

      <h1 class="no__deals">
        {{
          loading
            ? "Don't Move. We are loading your deals!"
            : "No matching deals. Check back again soon or add more Searches!"
        }}
      </h1>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import budget from "@/assets/budget.svg";

export default {
  props: {
    loading: { type: Boolean },
    slides: { type: Array },
    heading: { type: String },
    swiperClass: { type: String },
    refName: { type: String },

    swiperOption: {
      type: Object,
    },
    paginationClass: {
      type: String,
    },
    prevClass: {
      type: String,
    },
    nextClass: {
      type: String,
    },
    headingAlternate: {
      type: String,
    },
  },
  components: { Swiper, SwiperSlide },
  data() {
    return {
      hideLeftArrow: true,
      hideRightArrow: false,
      budget,
    };
  },
  computed: {
    getSlides() {
      if (this.slides.length > 0) {
        return this.slides.map((slide) => {
          let myslide = { ...slide };
          if (myslide.image) {
            let img = myslide.image;
            myslide.image = `${process.env.VUE_APP_CITY_IMAGES_SMALL}${img}`;
          }

          return myslide;
        });
      }
      return [];
    },
    SlidesLength() {
      return this.slides.length;
    },
  },
  mounted() {
    let arrowButtons = document.querySelector(".swiper-button-prev2");
    let previousClassBtn = document.querySelector(`.${this.prevClass}`);
    let nextClassBtn = document.querySelector(`.${this.nextClass}`);
    if (arrowButtons) {
      if (this.hideLeftArrow) {
        previousClassBtn.classList.add("swiper-button-disabled");
      }
    }
    // remove next slide if there are two or three slides only
    let breakpoint;
    if (
      this.$refs.heroswiper2 &&
      this.$refs.heroswiper2.$swiper &&
      this.$refs.heroswiper2.$swiper.currentBreakpoint
    ) {
      breakpoint = this.$refs.heroswiper2.$swiper.currentBreakpoint;
    }

    if (
      (breakpoint == 1200 && this.slides.length < 4) ||
      (breakpoint == 992 && this.slides.length < 3) ||
      (breakpoint == 768 && this.slides.length < 3) ||
      (breakpoint == 575 && this.slides.length < 2) ||
      (breakpoint == 230 && this.slides.length < 2)
    ) {
      nextClassBtn.classList.add("swiper-button-disabled");
    }
    console.log("mounted");
    console.log("slides", this.getSlides);
  },

  methods: {
    getProgress(value) {
      if (typeof value === "number" && !isNaN(value)) {
        if (value < 0 || value == "-0") {
          this.hideLeftArrow = true;
          this.hideRightArrow = false;
        }
        if (value > 0) {
          this.hideLeftArrow = false;
          this.hideRightArrow = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$headingColor: #5f4635;
$color1: #ff3647;

// media query mixins
// Small devices
.warning {
  color: $color1 !important;
}
@mixin xs {
  @media (max-width: "575px") {
    @content;
  }
}

@mixin sm {
  @media (max-width: "767px") {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: "991px") {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: "1199px") {
    @content;
  }
}
@mixin customMax($screen) {
  @media (max-width: $screen) {
    @content;
  }
}

.deals {
  padding-top: 20px;
  &__heading.alternate {
    padding-left: 20px;
    @include sm {
      text-align: center;
      padding-left: 0px;
    }
  }
  &__subheading {
    font-weight: 700;
    color: $color1;
  }
  &__card {
    // box-shadow: 0px 0px 2px 5px #222;
    border-radius: 8px;
    background: white;

    @media (min-width: 576px) and (max-width: 767px) {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    @include customMax(411px) {
      font-size: 0.9rem;
    }
    @include customMax(325px) {
      font-size: 0.8rem;
    }
    @include customMax(290px) {
      font-size: 0.75rem;
    }
    &__icon {
      position: absolute;
      right: 10px;
      top: 5px;
    }
    &__img {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &__lowersection {
      padding: 12px 12px 5px 15px;
      width: 100%;
      @include xs {
        padding: 12px 10px;
      }
      &__title {
        color: $headingColor;
        font-weight: 700;
      }
      &__from {
        color: $headingColor;
        font-weight: 600;
      }

      &__availability {
        font-weight: 600;
        color: $headingColor;
        text-align: left;
      }
      &__price {
        font-weight: 700;
        color: $color1;
      }

      // &__price .new {
      // }
      &__price .old {
        color: $headingColor;
      }
    }
  }
}

.swiper {
  padding: 40px 0;
  @include xs {
    padding: 15px 0;
  }
}

.swiper-pagination-bullets {
  display: none;
}

// swiper slides styles 1
.swiper-button-prev1:after,
.swiper-container-rtl .swiper-button-next1:after,
.swiper-button-next1:after,
.swiper-container-rtl .swiper-button-prev1:after {
  content: "";
}

.swiper-button-next1,
.swiper-container-rtl .swiper-button-prev1 {
  left: initial;
  top: 0px;
  right: 0px;
  z-index: 1000;
  position: absolute;
  z-index: 1000;
}

.swiper-button-prev1,
.swiper-container-rtl .swiper-button-next1 {
  left: initial;
  top: 0px;
  z-index: 1000;
  position: absolute;
  right: 30px;
}
// swiper slides styles 2
.swiper-button-prev2:after,
.swiper-container-rtl .swiper-button-next2:after,
.swiper-button-next2:after,
.swiper-container-rtl .swiper-button-prev2:after {
  content: "";
}

.swiper-button-next2,
.swiper-container-rtl .swiper-button-prev2 {
  left: initial;

  z-index: 1000;
  position: absolute;
  top: 0px;
  right: 0px;
}

.swiper-button-prev2,
.swiper-container-rtl .swiper-button-next2 {
  left: initial;
  top: 0px;
  z-index: 1000;
  position: absolute;
  right: 30px;
}
// swiper slides styles 3
.swiper-button-prev3:after,
.swiper-container-rtl .swiper-button-next3:after,
.swiper-button-next3:after,
.swiper-container-rtl .swiper-button-prev3:after {
  content: "";
}

.swiper-button-next3,
.swiper-container-rtl .swiper-button-prev3 {
  left: initial;
  top: 0px;
  z-index: 1000;
  position: absolute;
  right: 0px;
}

.swiper-button-prev3,
.swiper-container-rtl .swiper-button-next3 {
  left: initial;
  top: 0px;
  position: absolute;
  right: 30px;
  z-index: 1000;
}
// swiper slides styles 4
.swiper-button-prev4:after,
.swiper-container-rtl .swiper-button-next4:after,
.swiper-button-next4:after,
.swiper-container-rtl .swiper-button-prev4:after {
  content: "";
}

.swiper-button-next4,
.swiper-container-rtl .swiper-button-prev4 {
  left: initial;
  top: 0px;
  z-index: 1000;
  position: absolute;
  right: 0px;
}

.swiper-button-prev4,
.swiper-container-rtl .swiper-button-next4 {
  left: initial;
  top: 0px;
  position: absolute;
  right: 30px;
  z-index: 1000;
}



.cursor-pointer {
  cursor: pointer;
}

.swiper-button-next1.swiper-button-disabled,
.swiper-button-next2.swiper-button-disabled,
.swiper-button-next3.swiper-button-disabled,
.swiper-button-next4.swiper-button-disabled,
.swiper-button-prev1.swiper-button-disabled,
.swiper-button-prev2.swiper-button-disabled,
.swiper-button-prev3.swiper-button-disabled,
.swiper-button-prev4.swiper-button-disabled {
  display: none;
}

// disable on mobile
.swiper-button-prev1,
.swiper-button-prev2,
.swiper-button-prev3,
.swiper-button-prev4,
.swiper-button-next1,
.swiper-button-next2,
.swiper-button-next3,
.swiper-button-next4 {
  @include xs {
    display: none !important;
  }
}

.no__deals {
  font-size: 1rem;
  color: $headingColor;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>
