<template>
  <div class="home__section5">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-lg-6 col-md-4 col-12 text-md-start text-center">
          <img
            class="img-fluid home__section5__mobileImg"
            src="@/assets/home/mobile.svg"
            alt=""
          />
        </div>
        <div
          class="col-lg-6 col-md-8 col-12 text-md-start text-center mt-md-0 mt-4"
        >
          <div class="home__section5__right">
            <p class="home__section5__headingSmall">Available on your phone</p>
            <h2 class="home__section5__heading my-0 text-capitalize">
              See your Personalized <br />
              Deals quickly
            </h2>

            <p class="home__section5__text">
              Simply visit
              <a
                class="home__section5__text"
                href="http://www.browncowtravel.com"
                >http://www.browncowtravel.com</a
              >
              in your phone's web browser to access everything on the go. Mobile
              apps are coming soon!
            </p>
            <div
              class="d-flex flex-sm-row flex-column justify-content-md-start justify-content-center align-items-center home__section5__store__container"
            >
              <button
                class="d-flex align-items-center btn home__section5__store__btn me-sm-4 mb-sm-0 mb-3"
              >
                <img src="@/assets/home/apple.svg" alt="" />
                <span class="d-block home__section5__store__text"
                  >App Store</span
                >
              </button>
              <button
                class="d-flex align-items-center btn home__section5__store__btn"
              >
                <img src="@/assets/home/android.svg" alt="" />
                <span class="d-block home__section5__store__text"
                  >Play Store</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$headingColor: #2e2e2e;
$primaryColor: #5f4635;
$secondaryColor: #ff3647;
$textColor: #676767;
$whiteColor: #fffafa;
$secondaryLightColor: #f86061;
$bg: #eff1f5;
@mixin xs {
  @media (max-width: "575px") {
    @content;
  }
}

@mixin sm {
  @media (max-width: "767px") {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: "991px") {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: "1199px") {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: "1440") {
    @content;
  }
}

@mixin customMin($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

@mixin customMax($screen) {
  @media (max-width: $screen) {
    @content;
  }
}
.home__section5 {
  padding-bottom: 40px;
  padding-top: 40px;
  font-family: "Poppins", sans-serif;
  background: $bg;
  &__right {
    padding-left: 110px;
    @include lg {
      padding-left: 0px;
    }
  }
  &__mobileImg {
    padding-left: 100px;
    @include md {
      padding-left: 0px;
    }
  }

  &__heading {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    color: $headingColor;
    padding-top: 10px;
    padding-bottom: 20px;
    @include sm {
      font-size: 2.3rem;
    }
  }

  &__headingSmall {
    font-weight: 500;
    font-size: 1.125rem;
    color: $secondaryColor;
    margin-bottom: 0;
  }
  &__text {
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    color: $textColor;
    @include sm {
      font-size: 1.025rem;
    }
  }
  &__store {
    &__btn {
      padding: 19px 23px !important;
      border: 1.5px solid #b1adad !important;
      border-radius: 12px !important;
      width: 208px;
    }
    &__container {
      margin-top: 23px;
    }
    &__text {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 1.25rem;
      color: $headingColor;
      padding-left: 17px;
      @include customMax(1400px) {
        font-size: 1.23rem;
      }
    }
  }
}
</style>
