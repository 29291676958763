import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });
Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    current: "Routes",
    isAuthenticated: false,
    step: "",
    user: "",
    accessToken: "",
    userid: "",
    subscriptionId: "",
    clientSecret: "",
    metadata: "",
    customerid: "",
    showNavLinks: false,
    subMax: 0,
  },

  mutations: {
    setCurrent(state, payload) {
      state.current = payload;
    },
    setAuth(state, payload) {
      state.isAuthenticated = payload;
    },
    setStep(state, step) {
      state.step = step;
    },
    setUser(state, user) {
      state.user = user;
    },
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    setUserId(state, payload) {
      state.userid = payload;
    },
    setStripeData(state, payload) {
      state.subscriptionId = payload.subscriptionId;
      state.clientSecret = payload.clientSecret;
      state.metadata = payload.metadata;
    },
    setMetaData(state, payload) {
      state.metadata = payload;
    },
    setCustomerId(state, payload) {
      state.customerid = payload;
    },
    SetShowNavLinks(state, payload) {
      state.showNavLinks = payload;
    },
    SetSubMax(state, payload) {
      state.subMax = payload;
    },
  },
});

export default store;
