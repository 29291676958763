<template>
  <div class="faq">
    <h1 class="faq__heading">Support/FAQ</h1>
    <div class="faq__container">
      <section class="pt-3 pb-5">
        <div class="">
          <div class="row mb-2">
            <h4>FAQs</h4>

            <p>
              Visit our <a href="/faq">FAQ page</a> to read the most common
              questions we get.
            </p>
            <br />
            <br />
            <br />

            <h4>Support</h4>

            <p>
              Please send questions to
              <a href="mailto:support@browncowtravel.com"
                >support@browncowtravel.com</a
              >
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questions: [
        {
          q: "What is Brown Cow Travel?",
          a: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$headingColor: #5f4635;
$color1: #ff3647;

.faq {
  margin: 30px 0;
  &__heading {
    color: $headingColor;
    font-size: 1.35rem;
  }
  &__covid {
    &__heading {
      color: $headingColor;
    }
    &__info {
      color: #333;
    }
  }
  &__container {
    box-shadow: 0px 0px 3px 1px #dfe3ec;
    padding: 30px 30px 12px;
    @media (max-width: 991px) {
      padding: 20px 15px 12px;
    }
  }

  .accordion-button:not(.collapsed) {
    background: $headingColor !important;
    color: white;
  }
}
</style>
