import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { Auth0Plugin } from "./auth";
import hljs from "highlight.js/lib/core";
import "highlight.js/styles/github.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import store from "./store";
import vSelect from "vue-select";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { domain, clientId } from "../auth_config.json";
import vmodal from "vue-js-modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueSocialSharing from "vue-social-sharing";
import VueMeta from "vue-meta";

import {
  faLink,
  faUser,
  faPowerOff,
  faBell,
  faUserCircle,
  faPlane,
  faTag,
  faCreditCard,
  faUserPlus,
  faInfoCircle,
  faAddressBook,
  faSignOutAlt,
  faHeart,
  faTrashAlt,
  faPlusSquare,
  faTimes,
  faBars,
  faPaperPlane,
  faCheckSquare,
  faArrowCircleRight,
  faArrowCircleLeft,
  faPlaneDeparture,
  faGem,
  faCalendarAlt,
  faCoffee,
  faClock,
  faTags,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";

Vue.use(vmodal);
Vue.config.productionTip = false;

Vue.use(hljs.vuePlugin);

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});
Vue.use(VueSocialSharing);
Vue.use(VueMeta);
Vue.component("v-select", vSelect);

library.add(
  faLink,
  faUser,
  faPowerOff,
  faBell,
  faUserCircle,
  faPlane,
  faTag,
  faCreditCard,
  faUserPlus,
  faInfoCircle,
  faAddressBook,
  faSignOutAlt,
  faHeart,
  faTrashAlt,
  faPlusSquare,
  faTimes,
  faBars,
  faPaperPlane,
  faCheckSquare,
  faArrowCircleRight,
  faArrowCircleLeft,
  faPlaneDeparture,
  faGem,
  faCalendarAlt,
  faCoffee,
  faClock,
  faTags,
  faCertificate
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  router,
  store,
  data: { promo: true },
  render: (h) => h(App),
}).$mount("#app");
