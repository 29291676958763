<template>
  <div class="terms">
    <div class=WordSection1>

<p class=MsoNormal align=center style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
auto;text-align:center;line-height:normal;mso-outline-level:1;background:white'><span
style='font-size:24.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:#171717;mso-font-kerning:18.0pt'>Terms and Conditions
on BrownCowTravel.com<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><i><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>Modified:
December 1, 2021</span></i><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>Welcome
to our web site ('</span><b><span lang=EN style='font-size:12.0pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#171717;
letter-spacing:-.25pt;mso-ansi-language:EN'>Site</span></b><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>').&nbsp; We maintain
this web site as a service to our members for educational and business purposes
only and not to provide any specific advice.&nbsp; By accessing, browsing, or
using our Site, you are agreeing to comply with and to be bound by the
following terms and conditions.&nbsp; Please review the following terms
carefully.&nbsp; If you do not agree to these terms and conditions, you should
not use this web site.&nbsp; Note that the terms 'we,' 'our,' and 'us' used
below refer to Brown Cow Travel.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>1.&nbsp;Acceptance of Agreement</span></b><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>You
agree to the terms and conditions outlined in this Web Site Use Agreement Terms
&amp; Conditions ('</span><b><span lang=EN style='font-size:12.0pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#171717;
letter-spacing:-.25pt;mso-ansi-language:EN'>Agreement</span></b><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>') with respect to our
Site.&nbsp; This Agreement constitutes the entire and only agreement between us
and you, and it supersedes all prior or contemporaneous agreements,
representations, <span class=GramE>warranties</span> and understandings with
respect to your use of this Site.&nbsp; This Agreement may be amended at any
time by us without specific notice to you.&nbsp; The current version of the
Agreement will be posted on the Site, and you should review it prior to using
the Site.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>2.&nbsp;Use of Site</span></b><span style='font-size:
12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><i><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>Our
Limited License to You</span></i><span style='font-size:12.0pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#171717;
letter-spacing:-.25pt'>.&nbsp; The content on this Site includes, but is not limited
to, text, photos, videos, infographics, graphics, copyrighted material,
trademarks, trade names, service marks, logos, information from our licensors,
Submissions (as defined below) and other material ('</span><b><span lang=EN
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt;mso-ansi-language:EN'>Content</span></b><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>').&nbsp; As between us
and you, ownership of such Content is and remains with us.&nbsp; We grant you a
limited, revocable, and nontransferable license to view and use a single copy
of the Content solely for your personal use.&nbsp; Except as expressly
permitted by us in writing, you are not allowed to modify, copy, reproduce,
republish, upload, post, transmit, translate, create derivative works of,
incorporate into any information retrieval system, exploit, license,
distribute, transfer, sell, or re-sell any Content or any services obtained
from this Site.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l1 level1 lfo1;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><i><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Your License to Us</span></i><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>.&nbsp; Some areas of
the Site provide the opportunity for you to share knowledge and to post
interesting and helpful information directly or via web site links including,
but not limited to, remarks, suggestions, ideas, videos, infographics, graphics,
material, specifications, white papers, case studies and other information (the
'</span><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>Submissions</span></b><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#171717;letter-spacing:-.25pt'>').&nbsp; By posting Submissions, you
grant to us an irrevocable, royalty-free, worldwide, perpetual license to use,
reproduce, modify, adapt, publish, translate, create derivative works from,
distribute, exploit, perform, display worldwide, and create derivative works
from the Submissions for any purpose without compensation to you.&nbsp; You
also grant us the right to identify you as the author of your Submissions by
name and/or email address.&nbsp; We will not be required to treat your
Submissions as confidential.&nbsp; We will not be liable for the Submissions
including, but not limited to, any similarities between your Submissions and
any existing or future products, <span class=GramE>services</span> or
operations.&nbsp; By your posting Submissions, we are not endorsing your
products, services, or business, and under no circumstances may you represent
that we are.&nbsp; By posting Submissions, you represent and warrant that
either you are the owner of the Submissions or that the owner of the
Submissions has consented to your use of it in the <span class=GramE>manner in
which</span> it is used.&nbsp; You acknowledge that you are and will be solely
responsible for your Submissions as well as any damages caused by it including,
but not limited to, any infringement upon the rights of other parties.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l2 level1 lfo2;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>2.  At
a minimum, you agree that you will not post Submissions that include any of the
following:<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:60.75pt;text-indent:-.25in;line-height:normal;mso-list:l2 level2 lfo2;
tab-stops:list 1.0in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Anything that interferes
with or disrupts the Site or the operation of the <span class=GramE>Site;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:60.75pt;text-indent:-.25in;line-height:normal;mso-list:l2 level2 lfo2;
tab-stops:list 1.0in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Statements about
candidates for political <span class=GramE>office;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:60.75pt;text-indent:-.25in;line-height:normal;mso-list:l2 level2 lfo2;
tab-stops:list 1.0in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Statements or other
material that defames, harasses, abuses, stalks, threatens or in any way
infringes on the rights of <span class=GramE>others;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:60.75pt;text-indent:-.25in;line-height:normal;mso-list:l2 level2 lfo2;
tab-stops:list 1.0in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Statements or material
that encourages criminal conduct or that would give rise to civil liability or
otherwise violate any law or regulation in any <span class=GramE>jurisdiction;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:60.75pt;text-indent:-.25in;line-height:normal;mso-list:l2 level2 lfo2;
tab-stops:list 1.0in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Statements or material
that contains vulgar, obscene, profane or otherwise objectionable language or
that would not be considered socially or professionally <span class=GramE>appropriate;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:60.75pt;text-indent:-.25in;line-height:normal;mso-list:l2 level2 lfo2;
tab-stops:list 1.0in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Statements or material
that impersonate any other person or entity whether actual or <span
class=GramE>fictitious;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:60.75pt;text-indent:-.25in;line-height:normal;mso-list:l2 level2 lfo2;
tab-stops:list 1.0in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Statements or material
that constitute junk mail, spam or unauthorized advertising or promotional
material; and<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:60.75pt;text-indent:-.25in;line-height:normal;mso-list:l2 level2 lfo2;
tab-stops:list 1.0in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Files that contain
malicious code, viruses, corrupted <span class=GramE>files</span> or any other
software that may damage the operation of another's computer system.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l2 level1 lfo2;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><i><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Compliance with Law</span></i><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>.&nbsp; You agree that
your use of the Site will comply with all applicable laws.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>3.&nbsp;Users</span></b><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>To
access certain features of this Site, we may ask you to register by providing
information about yourself.&nbsp; Our use of such information is governed by
the terms of our&nbsp;</span><span style='color:black;mso-color-alt:windowtext'><a
href="/privacy"><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#146AA4;letter-spacing:-.25pt'>Privacy Policy</span></a></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>By
using this Site:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l3 level1 lfo3;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>You represent that all
registration and other information you submit, if any, is truthful and <span
class=GramE>accurate;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l3 level1 lfo3;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>You agree to maintain
the accuracy of any information you <span class=GramE>provide;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l3 level1 lfo3;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>You agree that you will
not submit any personal information if you are under the age of <span
class=GramE>thirteen;&nbsp; and</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l3 level1 lfo3;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Your use of the Site
does not violate any applicable law or regulation.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>You
may create and/or receive a username, password, or account for use of the Site.
In such case, you are responsible for maintaining the confidentiality of the
password, and you are responsible for all activities that occur under your
password, <span class=GramE>username</span> and account.&nbsp; We assume no
liability for any loss or damage from any unauthorized use of your password or
account by a third- party.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>You
represent and warrant that if you are purchasing a service or product from us
on our Site or on a third-party web site, any credit information you supply is
true and complete and that charges, including applicable taxes, incurred by you
will be honored by your credit card company or you will pay the charges.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>4.&nbsp;Third-Party Services and Links to Other Websites</span></b><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>Our
Site may allow access to or promote third-party product and/or service
providers and sponsors ('</span><b><span lang=EN style='font-size:12.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#171717;letter-spacing:-.25pt;mso-ansi-language:EN'>Merchants</span></b><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>') from whom you may
purchase certain goods or services.&nbsp; You understand that we do not operate
or control the products or services offered by Merchants.&nbsp; Merchants are
responsible for all aspects of order processing, fulfillment, <span
class=GramE>billing</span> and customer service.&nbsp; We are not a party to
any transactions entered into between you and Merchants.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>In
addition, this Site may contain links to websites operated by other parties.&nbsp;
We provide these links to other websites as a convenience and use of these
sites is&nbsp;<u>at your own risk</u>.&nbsp; The linked sites have not been
reviewed or monitored by us, are not <span class=GramE>owned</span> or operated
by us, and are not under our control, and we are not responsible for the
content available on the other sites or any information that you provided on
such sites.&nbsp; Inclusion of any linked web site in our Site does not imply
our approval or endorsement of information or material on these web sites by
us.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>USE
OF MERCHANTS' AND OTHER THIRD-PARTY WEB SITES, PRODUCTS AND SERVICES IS AT YOUR
SOLE RISK, AND WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS,
IMPLIED OR OTHERWISE INCLUDING WARRANTIES OF TITLE, FITNESS FOR PURPOSE,
MERCHANTABILITY OR NON-INFRINGEMENT, CONCERNING MERCHANT AND THIRD-PARTY
WEBSITES.&nbsp; WE EXPRESSLY DISCLAIM ANY RESPONSIBILITY FOR THE CONTENT,
MATERIALS, ACCURACY OR QUALITY OF PRODUCTS OR SERVICES ON THIRD-PARTY WEB
SITES.&nbsp; UNDER NO CIRCUMSTANCES ARE WE LIABLE FOR ANY DAMAGES ARISING FROM:
(A) YOUR ACCESS TO AND USE OF MERCHANTS' SITES OR ANY OTHER SITE LINKED TO OUR
SITE, (B) THE TRANSACTIONS BETWEEN YOU AND MERCHANTS AND ANY THIRD PARTIES, OR
(C) ANY INFORMATION, SERVICE OR PRODUCT APPEARING ON MERCHANTS' WEB SITES OR
ANY OTHER SITE LINKED TO OUR SITE.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>5.&nbsp;Links to Our Site</span></b><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>Unless
otherwise set forth in a written agreement between you and us, you agree to the
following when linking to our Site:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l0 level1 lfo4;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>The appearance, position
and other aspects of the link may not be such as to damage or dilute the goodwill
associated with our trademarks and <span class=GramE>names;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l0 level1 lfo4;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>The appearance, position
and other attributes of the link may not create the false appearance that your
organization or entity is sponsored by, affiliated with, endorsed by or
otherwise associated with <span class=GramE>us;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l0 level1 lfo4;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>When selected by a user,
the link must display this Site on full-screen and not within a 'frame' on the
linking web site; and<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l0 level1 lfo4;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>We reserve the right to
revoke access to the link and the related website at any time and in our sole
discretion.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>6.&nbsp;Indemnification</span></b><span style='font-size:
12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>You
agree to indemnify, defend, and hold harmless Brown Cow Travel, and our current
and future directors, affiliates, subsidiaries, officers, staff, and product
and service providers ('</span><b><span lang=EN style='font-size:12.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#171717;letter-spacing:-.25pt;mso-ansi-language:EN'>Affiliated Parties</span></b><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>') from and against all
liability, loss, claim and expense, including reasonable attorneys' fees and
legal fees, related to your violation of this <span class=GramE>Agreement</span>
or related to your use of the Site or its Content.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>7.&nbsp;Disclaimer and Limitations</span></b><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>THE
SITE AND THE INFORMATION FROM OR THROUGH THE SITE IS PROVIDED 'AS-IS,' 'AS
AVAILABLE,' AND ALL WARRANTIES, EXPRESS OR IMPLIED (INCLUDING, BUT NOT LIMITED
TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE)
ARE DISCLAIMED TO THE FULLEST EXTENT PROVIDED BY APPLICABLE LAW.&nbsp; WE
EXPRESSLY DISCLAIM TO THE EXTENT ALLOWED BY APPLICABLE LAW, NARROWLY CONSTRUED,
ALL LIABILITY FOR ALL CONTENT ON THIS SITE DRAFTED OR SUPPLIED BY PARTIES OTHER
THAN BROWN COW TRAVEL.&nbsp; THE SITE AND ITS CONTENT, INFORMATION AND SERVICES
MAY CONTAIN BUGS, ERRORS, PROBLEMS, OR OTHER LIMITATIONS.&nbsp; WE HAVE NO
LIABILITY WHATSOEVER FOR YOUR USE OF ANY SITE CONTENT, INFORMATION OR
SERVICE.&nbsp; IN PARTICULAR, BUT NOT AS A LIMITATION THEREOF, THE AFFILIATED
PARTIES ARE NOT LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL
DAMAGES, INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION,
OR THE LIKE, WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT
(INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE
POSSIBILITY OF SUCH DAMAGES, EXCEPT AS OTHERWISE REQUIRED BY LAW.&nbsp; IN
ADDITION, THE AFFILIATED PARTIES WILL NOT BE LIABLE TO YOU FOR ANY INCIDENTAL,
SPECIAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND THAT MAY RESULT FROM USE OF OR
INABILITY TO USE OUR SITE.&nbsp; THE LIMITATION OF DAMAGES SET FORTH ABOVE ARE
FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN US AND YOU.&nbsp; THIS
SITE AND THE PRODUCTS, SERVICES, <span class=GramE>CONTENT</span> AND
INFORMATION PRESENTED WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS.&nbsp; NO
ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US THROUGH
THE SITE SHALL CREATE ANY WARRANTY, REPRESENTATION OR GUARANTEE NOT EXPRESSLY
STATED IN THIS AGREEMENT.&nbsp; WE DO NOT PROVIDE LEGAL ADVICE NOR DO WE ENTER
INTO ANY ATTORNEY-CLIENT RELATIONSHIP.&nbsp; ALL RESPONSIBILITY OR LIABILITY
FOR ANY DAMAGES CAUSED BY VIRUSES CONTAINED WITHIN AN ELECTRONIC FILE ACCESSED
THROUGH THE SITE IS DISCLAIMED.&nbsp; OUR MAXIMUM LIABILITY TO YOU UNDER ANY
AND ALL CIRCUMSTANCES WILL BE EQUAL TO THE PURCHASE PRICE YOU PAY FOR ANY
GOODS, SERVICES OR INFORMATION PROVIDED ON THIS SITE.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>8.&nbsp;Notice and Procedure for Making Claims of
Copyright or Other Intellectual Property</span></b><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>We
respect the intellectual property of others and take the protection of copyrights
and all other intellectual property very seriously, and we ask our users to do
the same. We therefore employ measures to prevent copyright and other
intellectual property infringement on the Site. Pursuant to Title 17, United
States Code, Section 512(c)(2), if you believe that your work has been copied
in a way that constitutes copyright or other intellectual property
infringement, please send a written notification of the claimed copyright or
other intellectual property infringement to the following Designated Agent:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>General
Counsel<br>
Brown Cow Travel<br>
support@browncowtravel.com<br>
Phone:&nbsp;</span><span style='color:black;mso-color-alt:windowtext'>&#8234;</span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>(704) 251-5008</span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>Under
Title 17, United States Code, Section 512(c)(3)(A), to be effective the
notification must include the following information:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l4 level1 lfo5;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>A physical or electronic
signature of the person authorized to act on behalf of the owner of the
copyright or other intellectual property interest that is allegedly <span
class=GramE>infringed;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l4 level1 lfo5;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Identification of the
copyrighted work or other intellectual property that you claim has been infringed,
or if multiple copyrighted works or other intellectual property on the Site are
covered by the notice, a representative list of such <span class=GramE>works;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l4 level1 lfo5;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Identification of the
material that you claim is infringing or you claim is the subject of infringing
activity and that should be removed or access to which should be disabled, and
information reasonably sufficient to permit us to locate the <span class=GramE>material;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l4 level1 lfo5;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Information reasonably
sufficient to permit us to contact you, such as your name, address, telephone
number and email <span class=GramE>address;</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l4 level1 lfo5;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>A statement by you that
you have a good-faith belief that the disputed use is not authorized by the
copyright or other intellectual property owner, its agent, or the law; and<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
margin-left:24.75pt;text-indent:-.25in;line-height:normal;mso-list:l4 level1 lfo5;
tab-stops:list .5in;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:#171717;letter-spacing:-.25pt'><span style='mso-list:Ignore'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>A statement by you that
the notification is accurate, and under penalty of perjury, that you are the
owner of the copyright or other intellectual property or that you are
authorized to act on behalf of the owner of the copyright or other intellectual
property.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>9.&nbsp;Information and Press Releases</span></b><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>THE
SITE CONTAINS INFORMATION AND PRESS RELEASES ABOUT US. WHILE THIS INFORMATION
WAS BELIEVED TO BE ACCURATE AS OF THE DATE PREPARED, WE DISCLAIM ANY DUTY OR
OBLIGATION TO UPDATE THIS INFORMATION OR ANY PRESS RELEASES. Information about
organizations other than ours contained in the press release or otherwise
should not be relied upon as being provided or endorsed by us.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>10.&nbsp;Termination</span></b><span style='font-size:
12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>Notwithstanding
any of the terms of this Agreement, we reserve the right to discontinue the
Site and to terminate your use of the Site which includes blocking your access
to the Site and its Content or any portion of it at any time without notice and
in our sole discretion.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>11.&nbsp;Privacy Policy</span></b><span style='font-size:
12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>By
agreeing to these terms, you acknowledge that we may use and disclose your
information as described in our&nbsp;</span><span style='color:black;
mso-color-alt:windowtext'><a href="http://browncowtravel.com/privacy-policy"><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#146AA4;letter-spacing:-.25pt'>Privacy Policy</span></a></span><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>.&nbsp; Note that its
terms may change from time-to-time without notice. You should check our Site to
be informed of our current policy.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>12.&nbsp;Miscellaneous</span></b><span style='font-size:
12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>The
headings in this Agreement are for convenience of reference only and shall not
limit or otherwise affect the meaning hereof. Should any part of this Agreement
be held invalid or unenforceable, that portion shall be construed consistent
with applicable law and the remaining portions shall remain in full force and
effect. To the extent that the Privacy Policy or anything in or associated with
the Site is in conflict or inconsistent with this Agreement, this Agreement
shall have precedence. Our failure to enforce any provision of this Agreement
shall not be deemed a waiver of such provision nor of the right to enforce such
provision. This Agreement is the complete agreement and supersedes all prior or
contemporaneous understandings, <span class=GramE>negotiations</span> and
communication, whether oral or written, with respect to the subject matter
hereof. Only Brown Cow Travel may modify this Agreement, and it may do so at
any time without notice. You should check our Site to be informed of our
current policy. Modifications will be in effect upon posting to the Site.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>For
all purposes, this Agreement shall be governed and construed in accordance with
the laws of the North Carolina without regard to its choice-of-law rules, and
any legal action or proceeding arising out of this Agreement or related to the
Site will be brought and resolved exclusively in the federal and local courts
of the North Carolina or by dispute resolution proceedings held in Monroe, NC.
USA.&nbsp; Any cause of action or claim you may have with respect to this <span
class=GramE>Agreement</span> or this Site must be commenced within six (6)
months after the claim or cause of action arises or such claim or cause of
action shall be barred.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal;
background:white'><b><span lang=EN style='font-size:12.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#171717;letter-spacing:-.25pt;
mso-ansi-language:EN'>13.&nbsp;Additional Questions</span></b><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white'><span
style='font-size:12.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#171717;letter-spacing:-.25pt'>Thank you for your
cooperation.&nbsp; Questions or comments regarding the Site except for claims
addressed under Section 8 above should be directed by electronic mail to&nbsp;support@browncowtravel.com<o:p></o:p></span></p>

<p class=MsoNormal><o:p>&nbsp;</o:p></p>

</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
