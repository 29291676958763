<template>
    <div v-if="$auth.isAuthenticated">
        <div v-if="!$auth.usrSubActive || $auth.usrSubLvl"> 
            <subscription :auth_id="this.auth_user" :sub_active=this.auth_subActive :sub_value=auth_sublvl />
        </div>
        <div v-else>
            <span>There is already an active subscription tied to {{$auth.user.email}}. <br /><br /> To manage your subscriptions settings, go to the subscription settings within your <a href="">profile</a> settings.</span>
        </div>
    </div>
    <div v-else>
            <span>Please register and login to start a new subscription.</span>
    </div>
</template>

<script>

import { getInstance } from "./../auth/authWrapper.js";
import Subscription from '../components/Subscription.vue';


export default {
  name: "Registration",
  components: {
    Subscription,
  },
  beforeMount() {
        this.init();
    },
  data() {
      return{
          auth_user: null,
          auth_sublvl: -1,
          auth_subActive: false
      }
  },
  methods: {
    init() {
        var authSvc = getInstance();
        var claims = authSvc.getCustomClaims();
        //console.log(claims);
        this.auth_user = claims.usrAuthId.sub,
        this.auth_sublvl = claims.usrSubLvl,
        this.auth_subActive = claims.usrSubActive
    } 
  }
};
</script>