<template>
  <div class="trips" v-if="showdata">
    <!-- desktop -->
    <div class="row d-lg-flex d-none mb-5">
      <div class="col-12">
        <GraphicContent :src="flightData.image_lg" />
      </div>

      <div class="col-lg-9 col-12">
        <div class="my-3 px-3">
          <div>
            <!-- show outbound flight -->
            <div
              class="d-flex align-items-baseline justify-content-lg-start justify-content-center trips__depArr"
              v-if="showoutbound"
            >
              <div>
                <h1
                  v-if="originCity"
                  class="trips__depArr__city m-0 text-center"
                >
                  {{ originCity }}
                </h1>
                <p
                  class="m-0 trips__depArr__country text-center"
                  v-if="originCity_Country"
                >
                  {{ originCity_Country }}
                </p>
              </div>
              <p class="m-0 trips__depArr__to mx-lg-3 mx-sm-3 mx-2">to</p>
              <div>
                <h1
                  v-if="destinationCity"
                  class="trips__depArr__city m-0 text-center"
                >
                  {{ destinationCity }}
                </h1>
                <p
                  v-if="destinationCity_Country"
                  class="m-0 trips__depArr__country text-center"
                >
                  {{ destinationCity_Country }}
                </p>
              </div>
              <div
                v-if="stopNumbers"
                class="badge bg-dark text-light p-2 ms-3 ms-lg-3"
              >
                {{ stopNumbers }}-stop
              </div>
            </div>
            <!-- show return flight -->
            <div
              class="d-flex align-items-baseline justify-content-lg-start justify-content-center trips__depArr"
              v-if="!showoutbound"
            >
              <div>
                <h1
                  v-if="originCity"
                  class="trips__depArr__city m-0 text-center"
                >
                  {{ originCity }}
                </h1>
                <p
                  v-if="originCity_Country"
                  class="m-0 trips__depArr__country text-center"
                >
                  {{ originCity_Country }}
                </p>
              </div>
              <p class="m-0 trips__depArr__to mx-lg-3 mx-sm-3 mx-2">to</p>
              <div>
                <h1
                  v-if="destinationCity"
                  class="trips__depArr__city m-0 text-center"
                >
                  {{ destinationCity }}
                </h1>
                <p
                  v-if="destinationCity_Country"
                  class="m-0 trips__depArr__country text-center"
                >
                  {{ destinationCity_Country }}
                </p>
              </div>
              <div class="badge bg-dark text-light p-2 ms-3 ms-lg-3">
                {{ stopNumbers }}-stop
              </div>
            </div>
          </div>
        </div>
        <hr class="m-0 my-2" />
        <div class="my-4" v-if="dealDictStatus">
          <!-- hopped arrows section -->
          <div
            class="trips__flightLegs d-flex align-items-center position-relative ps-3"
          >
            <div>
              <h1
                class="trips__depArr__code m-0"
                v-if="currentSlice[0] && currentSlice[0].origin"
              >
                {{ currentSlice[0].origin }}
              </h1>
              <p
                class="m-0 trips__depArr__country stop"
                v-if="currentSlice[0] && currentSlice[0].originCity"
              >
                {{ currentSlice[0].originCity }}
              </p>
            </div>
            <div class="trips__flightLegs__imgContainer" v-if="currentSlice[0]">
              <div class="position-relative">
                <img
                  class="trips__flightLegs__img mx-2"
                  src="../assets/images/arrows.png"
                  alt=""
                />
                <p
                  class="m-0 trips__flightLegs__time badge bg-dark text-light text-center"
                  v-if="currentSlice[0] && currentSlice[0].duration"
                >
                  {{ currentSlice[0].duration }}
                </p>
              </div>
            </div>
            <div>
              <h1
                class="trips__depArr__code m-0"
                v-if="currentSlice[0] && currentSlice[0].destination"
              >
                {{ currentSlice[0].destination }}
              </h1>
              <p
                class="m-0 trips__depArr__country stop"
                v-if="currentSlice[0] && currentSlice[0].destinationCity"
              >
                {{ currentSlice[0].destinationCity }}
              </p>
            </div>
            <div class="trips__flightLegs__imgContainer" v-if="currentSlice[1]">
              <div class="position-relative">
                <img
                  class="trips__flightLegs__img mx-2"
                  src="../assets/images/arrows.png"
                  alt=""
                />
                <p
                  class="m-0 trips__flightLegs__time badge bg-dark text-light text-center"
                  v-if="currentSlice[1] && currentSlice[1].duration"
                >
                  {{ currentSlice[1].duration }}
                </p>
              </div>
            </div>
            <div>
              <h1
                class="trips__depArr__code m-0"
                v-if="currentSlice[1] && currentSlice[1].destination"
              >
                {{ currentSlice[1].destination }}
              </h1>
              <p
                class="m-0 trips__depArr__country stop"
                v-if="currentSlice[1] && currentSlice[1].destinationCity"
              >
                {{ currentSlice[1].destinationCity }}
              </p>
            </div>
            <div class="trips__flightLegs__imgContainer" v-if="currentSlice[2]">
              <div class="position-relative">
                <img
                  class="trips__flightLegs__img mx-2"
                  src="../assets/images/arrows.png"
                  alt=""
                />
                <p
                  class="m-0 trips__flightLegs__time badge bg-dark text-light text-center"
                  v-if="currentSlice[2] && currentSlice[2].duration"
                >
                  {{ currentSlice[2].duration }}
                </p>
              </div>
            </div>
            <div>
              <h1
                class="trips__depArr__code m-0"
                v-if="currentSlice[2] && currentSlice[2].destination"
              >
                {{ currentSlice[2].destination }}
              </h1>
              <p
                class="m-0 trips__depArr__country stop"
                v-if="currentSlice[2] && currentSlice[2].destinationCity"
              >
                {{ currentSlice[2].destinationCity }}
              </p>
            </div>
          </div>
          <button
            @click="reverseRoutes"
            class="btn trips__returnFlightBtn d-block my-3"
          >
            {{ flightbtnText }}
          </button>
          <hr />
        </div>
        <!-- deal information paragraph section -->
        <div class="my-4" v-if="flightData.dest_description">
          <p class="trips__informatonPara m-0">
            {{ flightData.dest_description }}
          </p>
        </div>
        <!-- Flight leg info -->
        <div class="my-4" v-if="dealDictStatus">
          <div class="card p-3 trips__flightLegInfo">
            <div class="trips__flightLegInfo__content">
              <Swiper ref="heroswiper" class="swiper" :options="swiperOption">
                <SwiperSlide v-for="slide in slides" :key="slide.id">
                  <h4
                    class="text-start trips__flightLegInfo__content__flightname"
                  >
                    {{ slide.originCity }}({{ slide.origin }}) to
                    {{ slide.destinationCity }} ({{ slide.destination }})
                  </h4>
                  <hr class="w-75" />
                  <div
                    class="d-flex flex-sm-row flex-column align-items-center"
                  >
                    <div
                      class="trips__flightLegInfo__content__flightdestContainer"
                    >
                      <div>
                        <font-awesome-icon
                          class="paperplan"
                          :icon="['fas', 'paper-plane']"
                        />
                      </div>
                      <p class="m-0 trips__flightLegInfo__content__flightdest">
                        Flight Number :
                        {{ slide.flighNumber }}
                      </p>
                      <p class="m-0 trips__flightLegInfo__content__flightdest">
                        Airline :
                        {{ slide.marketing_carrier }}
                      </p>

                      <p
                        v-if="slide.carrierName && slide.carrierName.name"
                        class="m-0 trips__flightLegInfo__content__flightdest"
                      >
                        {{ slide.carrierName.name }} {{ slide.aircraftName }}
                      </p>
                    </div>
                    <div
                      class="d-flex flex-column trips__flightLegInfo__content__featuresContainer justify-content-center ms-sm-5 ms-0"
                    >
                      <div
                        v-for="feature in slide.cabin_amenities"
                        :key="feature"
                        class="d-flex align-items-center"
                      >
                        <font-awesome-icon
                          class="checksquare"
                          :icon="['fas', 'check-square']"
                        />
                        <p
                          class="mb-0 ms-2 trips__flightLegInfo__content__feature"
                        >
                          {{ feature }}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <div class="swiper-pagination" slot="pagination"></div>
              </Swiper>
              <div class="swiper-button-prev" slot="button-prev">
                <font-awesome-icon :icon="['fas', 'arrow-circle-left']" />
              </div>
              <div class="swiper-button-next" slot="button-next">
                <font-awesome-icon :icon="['fas', 'arrow-circle-right']" />
              </div>
            </div>
          </div>
        </div>
        <!-- similar flights section -->
        <div class="my-4">
          <div class="card p-3 trips__similarFlights my-3">
            <h4 class="trips__similarFlights__heading text-start">
              Similar Flights
            </h4>
            <hr class="w-75" />
            <Content
              v-if="suggested.length > 0"
              :loading="loading"
              heading=""
              :slides="suggested"
              refName="heroswiper2"
              swiperClass="swiper2"
              :swiperOption="swiperOption2"
              paginationClass="swiper-pagination2"
              prevClass="swiper-button-prev2"
              nextClass="swiper-button-next2"
              headingAlternate=""
            />
            <div v-else class="text-center">
              <h1 class="no__deals">No similar flights found at the moment</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-12">
        <div class="trips__rightColumn">
          <!-- pricebox -->
          <div
            class="card my-3 trips__priceBox text-lg-start text-center pt-3 pb-2 px-xl-3 px-1"
            v-if="flightData"
          >
            <div class="d-flex justify-content-center align-items-end">
              <h4 class="m-0 trips__priceBox__price">
                ${{ Math.round(flightData.total_amount) }}
              </h4>
            </div>
            <div class="d-flex justify-content-center align-items-end mt-2">
              <p
                v-if="flightData.trip_type"
                class="mb-0 badge bg-dark trips__priceBox__badges p-2"
              >
                {{ flightData.trip_type }}
              </p>
              <p class="mb-0 badge bg-dark ms-2 trips__priceBox__badges p-2">
                {{ cabinName }}
              </p>
            </div>
            <p
              class="text-center trips__priceBox__normalprice font-weight-bold"
            >
              Normal Price:
              <span class="alternate"
                >${{ flightData.ave_price }} |
                {{ flightData.ave_price_percent }}% off</span
              >
            </p>
            <a
              :href="flightData.bookURL"
              target="_blank"
              class="btn trips__returnFlightBtn d-block my-3"
              v-if="showFlightButton"
            >
              Book This Flight Now
            </a>
          </div>
          <!-- deal details -->
          <div class="card my-3 trips__dealDetails pt-3 pb-2 px-3">
            <div class="trips__dealDetails__headingContainer">
              <h4 class="trips__dealDetails__headingContainer__heading">
                Deal Details
              </h4>
              <hr />
            </div>
            <div
              class="d-flex justify-content-lg-center justify-content-center"
            >
              <div>
                <div
                  class="d-flex mb-2 trips__dealDetailsContainer"
                  v-for="detail in dealDetails"
                  :key="detail.icon"
                >
                  <div class="trips__dealDetails__iconContainer">
                    <font-awesome-icon
                      class="trips__dealDetails__icon"
                      :icon="['fas', detail.icon]"
                    />
                  </div>
                  <p
                    class="mb-0 ms-2 trips__dealDetails__text"
                    v-html="detail.text"
                  ></p>
                </div>
              </div>
            </div>
          </div>
          <!-- share the love -->
          <div
            class="card d-flex flex-column align-items-center my-3 trips__share pt-3 pb-2 px-3"
          >
            <div class="trips__share__headingContainer">
              <h4 class="trips__share__headingContainer__heading">
                Share the Love
              </h4>
              <hr />
            </div>
            <p class="trips__share__text">
              Tell someone you love about the deal
            </p>
            <div>
              <ShareNetwork
                v-for="network in networks"
                :network="network.network"
                :key="network.network"
                :url="sharing.url"
                :title="sharing.title"
                :description="sharing.description"
                :hashtags="sharing.hashtags"
                :twitterUser="sharing.twitterUser"
              >
                <img
                  :src="network.icon"
                  height="48px"
                  width="48px"
                  class="mx-1"
                />
                <!-- <span>{{ network.name }}</span> -->
              </ShareNetwork>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- small screens -->
    <div class="row d-flex d-lg-none mb-5">
      <div class="col-12">
        <GraphicContent :src="flightData.image_lg" />
      </div>
      <div class="col-12">
        <div class="px-3 mt-3">
          <div>
            <!-- show outbound flight -->
            <div
              class="d-flex align-items-baseline justify-content-lg-start justify-content-center trips__depArr"
              v-if="showoutbound"
            >
              <div>
                <h1
                  v-if="originCity"
                  class="trips__depArr__city m-0 text-center"
                >
                  {{ originCity }}
                </h1>
                <p
                  class="m-0 trips__depArr__country text-center"
                  v-if="originCity_Country"
                >
                  {{ originCity_Country }}
                </p>
              </div>
              <p class="m-0 trips__depArr__to mx-lg-3 mx-sm-3 mx-2">to</p>
              <div>
                <h1
                  v-if="destinationCity"
                  class="trips__depArr__city m-0 text-center"
                >
                  {{ destinationCity }}
                </h1>
                <p
                  v-if="destinationCity_Country"
                  class="m-0 trips__depArr__country text-center"
                >
                  {{ destinationCity_Country }}
                </p>
              </div>
              <div
                v-if="stopNumbers"
                class="badge bg-dark text-light p-2 ms-3 ms-lg-3"
              >
                {{ stopNumbers }}-stop
              </div>
            </div>
            <!-- show return flight -->
            <div
              class="d-flex align-items-baseline justify-content-lg-start justify-content-center trips__depArr"
              v-if="!showoutbound"
            >
              <div>
                <h1
                  v-if="originCity"
                  class="trips__depArr__city m-0 text-center"
                >
                  {{ originCity }}
                </h1>
                <p
                  v-if="originCity_Country"
                  class="m-0 trips__depArr__country text-center"
                >
                  {{ originCity_Country }}
                </p>
              </div>
              <p class="m-0 trips__depArr__to mx-lg-3 mx-sm-3 mx-2">to</p>
              <div>
                <h1
                  v-if="destinationCity"
                  class="trips__depArr__city m-0 text-center"
                >
                  {{ destinationCity }}
                </h1>
                <p
                  v-if="destinationCity_Country"
                  class="m-0 trips__depArr__country text-center"
                >
                  {{ destinationCity_Country }}
                </p>
              </div>
              <div class="badge bg-dark text-light p-2 ms-3 ms-lg-3">
                {{ stopNumbers }}-stop
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <!-- pricebox -->
        <div
          class="card my-3 trips__priceBox text-lg-start text-center px-xl-3 px-1 mb-4 mt-3 p-3"
          v-if="flightData"
        >
          <div class="d-flex justify-content-center align-items-end">
            <h4 class="m-0 trips__priceBox__price">
              ${{ Math.round(flightData.total_amount) }}
            </h4>
          </div>
          <div class="d-flex justify-content-center align-items-end mt-2">
            <p
              v-if="flightData.trip_type"
              class="mb-0 badge bg-dark trips__priceBox__badges p-2"
            >
              {{ flightData.trip_type }}
            </p>
            <p class="mb-0 badge bg-dark ms-2 trips__priceBox__badges p-2">
              {{ cabinName }}
            </p>
          </div>
          <p
            class="text-center trips__priceBox__normalprice font-weight-bold m-0"
          >
            Normal Price:
            <span class="alternate"
              >${{ flightData.ave_price }} | {{ flightData.ave_price_percent }}%
              off</span
            >
          </p>
          <a
            :href="flightData.bookURL"
            target="_blank"
            class="btn trips__returnFlightBtn d-block my-3"
            v-if="showFlightButton"
          >
            Book This Flight Now
          </a>
        </div>

        <!-- deal details -->

        <div class="my-3">
          <div class="card trips__dealDetails p-3">
            <div class="trips__dealDetails__headingContainer">
              <h4 class="trips__dealDetails__headingContainer__heading">
                Deal Details
              </h4>
              <hr />
            </div>
            <div
              class="d-flex justify-content-lg-center justify-content-center"
            >
              <div>
                <div
                  class="d-flex mb-2 trips__dealDetailsContainer"
                  v-for="detail in dealDetails"
                  :key="detail.icon"
                >
                  <div class="trips__dealDetails__iconContainer">
                    <font-awesome-icon
                      class="trips__dealDetails__icon"
                      :icon="['fas', detail.icon]"
                    />
                  </div>
                  <p
                    class="mb-0 ms-2 trips__dealDetails__text"
                    v-html="detail.text"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- deal information paragraph section -->
        <div class="my-3" v-if="flightData.dest_description">
          <p class="trips__informatonPara m-0">
            {{ flightData.dest_description }}
          </p>
        </div>
        <!-- Flight leg info -->
        <div class="my-3">
          <div class="card p-3 trips__flightLegInfo">
            <div class="trips__flightLegInfo__content">
              <Swiper ref="heroswiper" class="swiper" :options="swiperOption">
                <SwiperSlide v-for="slide in slides" :key="slide.id">
                  <h4
                    class="text-start trips__flightLegInfo__content__flightname"
                  >
                    {{ slide.originCity }}({{ slide.origin }}) to
                    {{ slide.destinationCity }} ({{ slide.destination }})
                  </h4>
                  <hr class="w-75" />
                  <div
                    class="d-flex flex-md-row flex-column align-items-center"
                  >
                    <div
                      class="trips__flightLegInfo__content__flightdestContainer"
                    >
                      <div>
                        <font-awesome-icon
                          class="paperplan"
                          :icon="['fas', 'paper-plane']"
                        />
                      </div>
                      <p class="m-0 trips__flightLegInfo__content__flightdest">
                        Flight Number :
                        {{ slide.flighNumber }}
                      </p>
                      <p class="m-0 trips__flightLegInfo__content__flightdest">
                        Airline :
                        {{ slide.marketing_carrier }}
                      </p>

                      <p
                        v-if="slide.carrierName && slide.carrierName.name"
                        class="trips__flightLegInfo__content__flightdest"
                      >
                        {{ slide.carrierName.name }} {{ slide.aircraftName }}
                      </p>
                    </div>
                    <div
                      class="d-flex flex-column trips__flightLegInfo__content__featuresContainer justify-content-center ms-sm-5 ms-0"
                    >
                      <div
                        v-for="feature in slide.cabin_amenities"
                        :key="feature"
                        class="d-flex align-items-center"
                      >
                        <font-awesome-icon
                          class="checksquare"
                          :icon="['fas', 'check-square']"
                        />
                        <p
                          class="mb-0 ms-2 trips__flightLegInfo__content__feature"
                        >
                          {{ feature }}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <div class="swiper-pagination" slot="pagination"></div>
              </Swiper>
              <div class="swiper-button-prev" slot="button-prev">
                <font-awesome-icon :icon="['fas', 'arrow-circle-left']" />
              </div>
              <div class="swiper-button-next" slot="button-next">
                <font-awesome-icon :icon="['fas', 'arrow-circle-right']" />
              </div>
            </div>
          </div>
        </div>

        <!-- similar flights section -->
        <div class="my-3">
          <div class="card p-3 trips__similarFlights">
            <h4 class="trips__similarFlights__heading">Similar Flights</h4>
            <hr class="w-75" />

            <Content
              v-if="suggested.length > 0"
              :loading="loading"
              heading=""
              :slides="suggested"
              refName="heroswiper2"
              swiperClass="swiper2"
              :swiperOption="swiperOption2"
              paginationClass="swiper-pagination2"
              prevClass="swiper-button-prev2"
              nextClass="swiper-button-next2"
              headingAlternate=""
            />

            <div v-else class="text-center">
              <h1 class="no__deals">No similar flights found at the moment</h1>
            </div>
          </div>
        </div>

        <!-- share the love -->
        <div
          class="card d-flex flex-column align-items-center my-3 trips__share p-3"
        >
          <div class="trips__share__headingContainer">
            <h4 class="trips__share__headingContainer__heading">
              Share the Love
            </h4>
            <hr />
          </div>
          <p class="trips__share__text">Tell someone you love about the deal</p>
          <div>
            <ShareNetwork
              v-for="network in networks"
              :network="network.network"
              :key="network.network"
              :url="sharing.url"
              :title="sharing.title"
              :description="sharing.description"
              :hashtags="sharing.hashtags"
              :twitterUser="sharing.twitterUser"
            >
              <img
                :src="network.icon"
                height="48px"
                width="48px"
                class="mx-1"
              />
            </ShareNetwork>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { getInstance } from "../auth/authWrapper";
import GraphicContent from "@/components/trips/GraphicContent.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Content from "@/components/deal/Content.vue";
import "swiper/css/swiper.css";
import { format } from "date-fns";
import facebook from "@/assets/facebook.svg";
import sms from "@/assets/sms.svg";
import reddit from "@/assets/raddit.svg";
import whatsapp from "@/assets/whatsapp.svg";
import email from "@/assets/email.svg";
import twitter from "@/assets/twitter.svg";
export default {
  components: { Content, GraphicContent, Swiper, SwiperSlide },
  data() {
    return {
      test: "demo",

      user: {},
      id: "XE19ED2A9-4FB8-4844-9EE5-10-AA",

      flightbtnText: "See Return Flight",
      showoutbound: true,
      showdata: false,
      slides: [],
      stopNumbers: 0,
      slimilarFlights: [
        {
          city: "Atlanta",
          route: "GA to Orlando,",
          code: "FL",
          price: "$249",
        },
        {
          city: "Atlanta",
          route: "GA to Orlando,",
          code: "FL",
          price: "$249",
        },
        {
          city: "Atlanta",
          route: "GA to Orlando,",
          code: "FL",
          price: "$249",
        },
        {
          city: "Atlanta",
          route: "GA to Orlando,",
          code: "FL",
          price: "$249",
        },
        {
          city: "Atlanta",
          route: "GA to Orlando,",
          code: "FL",
          price: "$249",
        },
        {
          city: "Atlanta",
          route: "GA to Orlando,",
          code: "FL",
          price: "$249",
        },
        {
          city: "Atlanta",
          route: "GA to Orlando,",
          code: "FL",
          price: "$249",
        },
        {
          city: "Atlanta",
          route: "GA to Orlando,",
          code: "FL",
          price: "$249",
        },
      ],
      dealDetails: [
        {
          icon: "plane-departure",
          text: "<span class='alternate'>Airline:</span> Southwest",
          name: "Airline",
        },
        {
          icon: "gem",
          text: "<span class='alternate'>Rarity:</span> Impossible to find deal",
          name: "Rarity",
        },
        {
          icon: "calendar-alt",
          text: "<span class='alternate'>Dates:</span> Nov 2021 - Dec 2021",
          name: "Dates",
        },
        {
          icon: "coffee",
          text: "<span class='alternate'>Class:</span> Economy",
          name: "Class",
        },
        {
          icon: "clock",
          text: "<span class='alternate'>Deal will last:</span> Less than 12 hours",
          name: "Expires_at",
        },
      ],
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView: 1,
        spaceBetween: 5,
      },
      suggested: [],
      loading: false,
      swiperOption2: {
        pagination: {
          el: ".swiper-pagination2",
          clickable: true,
        },

        navigation: {
          nextEl: ".swiper-button-next2",
          prevEl: ".swiper-button-prev2",
        },

        breakpoints: {
          1200: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          575: {
            slidesPerView: 1.15,
            spaceBetween: 5,
          },
          230: {
            slidesPerView: 1.15,
            spaceBetween: 10,
          },
        },
      },
      flightData: {},
      slices: [],
      dealDict: [],

      // -----new data
      cities: [],

      cabinName: "",
      departureFlight: {},
      returnFlight: {},
      departureSlice: [],
      returnSlice: [],
      currentSlice: [],
      showFlightButton: false,
      sharing: {
        url: "",
        title: "",
        description: "",
        quote: "Look at this sweet flight I found",
        hashtags: "browncowtravel,deals",
        twitterUser: "browncowtravel",
      },
      networks: [
        {
          network: "twitter",
          name: "Twitter",
          icon: twitter,
          color: "#1da1f2",
        },
        {
          network: "facebook",
          name: "Facebook",
          icon: facebook,
          color: "#1877f2",
        },
        {
          network: "reddit",
          name: "Reddit",
          icon: reddit,
          color: "#ff4500",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: whatsapp,
          color: "#25d366",
        },
        {
          network: "email",
          name: "Email",
          icon: email,
          color: "#333333",
        },
        {
          network: "sms",
          name: "SMS",
          icon: sms,
          color: "#333333",
        }
      ],
    };
  },
  async created() {
    await this.getData();
    if (this.isAuthenticated) {
      this.showFlightButton = true;
    } else {
      this.showFlightButton = false;
    }
    console.log("envData==>", process.env);
    this.getSimilarFlights(this.userid);
    await console.log("created==>", this.flightData);
    this.sharing.url = `https://app.browncowtravel.com/trips/${this.$route.params.id}`;
    this.sharing.title = `Look at this sweet flight I found to ${this.destinationCity} for $${this.total_amount}`;
      this.sharing.description = `Look at this sweet flight I found to ${this.destinationCity} for $${this.total_amount}`;
  },

  watch: {
    showoutbound(value) {
      if (value) {
        this.currentSlice = this.departureSlice;
      } else {
        this.currentSlice = this.returnSlice;
      }
      this.stopNumbers = 0;
      this.currentSlice.forEach((slide) => {
        if (
          slide.destination !== this.flightData.departure &&
          slide.destination !== this.flightData.arrival
        ) {
          this.stopNumbers++;
        }
      });
    },
    "$route.params": {
      deep: true,
      handler() {
        this.user =
          this.userid || encodeURIComponent(this.$auth.user.sub.toString());
        this.getData();
        this.getSimilarFlights(this.user);
      },
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
    userid() {
      return this.$store.state.userid;
    },
  },

  methods: {
    init(fn) {
      var instance = getInstance();

      fn(instance);
    },
    async loadTokenIntoStore(instance) {
      await instance.getTokenSilently();

      this.user = encodeURIComponent(this.$auth.user.sub.toString());
      this.getData();
    },
    async getSimilarFlights(user) {
      try {
        this.loading = true;
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/deals/${user}`
        );

        if (res.data.suggested) {
          this.suggested = res.data.suggested;
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
        throw new Error(error);
      }
    },
    async getData() {
      try {
        this.showdata = false;
        let res = await axios.get(
          `${process.env.VUE_APP_API_URL}/trips/${this.$route.params.id}`
        );

        this.flightData = res.data[0];
        if (this.flightData.image) {
          this.flightData.image_lg = `${process.env.VUE_APP_CITY_IMAGES_LARGE}${this.flightData.image}`;
          this.flightData.image_sm = `${process.env.VUE_APP_CITY_IMAGES_SMALL}${this.flightData.image}`;
        }

        //Set some params
        this.Airline = "";
        this.rarity = "";
        this.airlineClass = "";
        this.dealDate = "";
        this.originCity = "";
        this.originCity_Country = "";
        this.destinationCity = "";
        this.destinationCity_Country = "";
        this.total_amount = "";

        //If this is a Flyline results
        if (this.flightData.dealDict) {
          this.dealDict = this.flightData.dealDict;
          this.dealDictStatus = true;
          this.cabinName = this.dealDict[0].pricing.fares[0].cabin_name;

          let airports = this.flightData.airports;

          // citynames
          let departureFlight = this.dealDict[0].pricing.slices[0];
          let returnFlight = this.dealDict[0].pricing.slices[1];

          airports.forEach((airport) => {
            if (airport.iata_code == departureFlight.origin) {
              departureFlight.originCity = airport.city.name;
            }
            if (airport.iata_code == departureFlight.destination) {
              departureFlight.destinationCity = airport.city.name;
            }
            if (airport.iata_code == returnFlight.origin) {
              returnFlight.originCity = airport.city.name;
            }
            if (airport.iata_code == returnFlight.destination) {
              returnFlight.destinationCity = airport.city.name;
            }
          });
          this.departureFlight = departureFlight;
          this.returnFlight = returnFlight;

          // arrival/departure cities details
          this.cities.push(this.flightData.airports[0]);
          this.cities.push(this.flightData.airports[1]);

          //slides
          let segments = [];
          segments.push(...this.dealDict[0].pricing.slices[0].segments);
          segments.push(...this.dealDict[0].pricing.slices[1].segments);
          let fares = [];
          this.dealDict[0].pricing.fares.forEach((fare) => {
            fare.segments.forEach((item) => {
              fares.push(item);
            });
          });

          segments = segments.map((seg) => {
            let data = {};

            airports.forEach((airport) => {
              if (airport.iata_code == seg.origin) {
                data.originCity = airport.city.name;
              }
              if (airport.iata_code == seg.destination) {
                data.destinationCity = airport.city.name;
              }
            });

            data.origin = seg.origin;
            data.destination = seg.destination;

            data.marketing_carrier = seg.marketing_carrier;

            fares.forEach((fare, i) => {
              let cabinKeys = Object.keys(fares[i].cabin_amenities);
              let amenities = cabinKeys.map((key) => {
                return fares[i].cabin_amenities[key].display_text;
              });

              if (fare.segment_id == seg.id) {
                data.cabin_amenities = amenities;
              }
            });

            this.flightData.aircraft.forEach((ar) => {
              if (ar.iata_code == seg.aircraft) {
                data.aircraftName = ar.name;
              }
            });
            if (this.flightData.carriers) {
              this.flightData.carriers.forEach((carrier) => {
                if (carrier.code == seg.operating_carrier) {
                  data.carrierName = carrier;
                }
              });
            }

            data.flighNumber = seg.marketing_carrier_flight_number;

            return data;
          });

          this.slides = [...segments];

          this.slices = this.dealDict[0].pricing.slices[0].segments;

          let departureSlice = this.dealDict[0].pricing.slices[0].segments.map(
            (slice) => {
              let duration = slice.duration.substring(2);
              duration = duration.replace("H", ":");
              duration = duration.replace("M", "");
              let minutesLength = duration.split(":")[1].trim().length;
              if (minutesLength < 2) {
                duration = duration.replace(":", `:0`);
              }

              return {
                ...slice,
                duration,
              };
            }
          );
          let returnSlice = this.dealDict[0].pricing.slices[1].segments.map(
            (slice) => {
              let duration = slice.duration.substring(2);
              duration = duration.replace("H", ":");
              duration = duration.replace("M", "");
              let minutesLength = duration.split(":")[1].trim().length;
              if (minutesLength < 2) {
                duration = duration.replace(":", `:0`);
              }

              return {
                ...slice,
                duration,
              };
            }
          );
          //adding full city names into the slices
          airports.forEach((airport) => {
            departureSlice = departureSlice.map((slice) => {
              if (airport.iata_code == slice.origin) {
                slice.originCity = airport.city.name;
              } else if (airport.iata_code == slice.destination) {
                slice.destinationCity = airport.city.name;
              }
              return slice;
            });
            returnSlice = returnSlice.map((slice) => {
              if (airport.iata_code == slice.origin) {
                slice.originCity = airport.city.name;
              } else if (airport.iata_code == slice.destination) {
                slice.destinationCity = airport.city.name;
              }
              return slice;
            });
          });

          this.returnSlice = returnSlice;
          this.departureSlice = departureSlice;
          // set initial slice as departure
          this.currentSlice = departureSlice;
          this.stopNumbers = 0;
          this.currentSlice.forEach((slide) => {
            if (
              slide.destination !== this.flightData.departure &&
              slide.destination !== this.flightData.arrival
            ) {
              this.stopNumbers++;
            }
          });

          this.slices = this.slices.map((slice) => {
            let duration = slice.duration.substring(2);
            duration = duration.replace("H", ":");
            duration = duration.replace("M", "");
            let minutesLength = duration.split(":")[1].trim().length;
            if (minutesLength < 2) {
              duration = duration.replace(":", `:0`);
            }
            return {
              origin: slice.origin,
              destination: slice.destination,
              duration,
            };
          });

          if (this.flightData.carriers) {
            this.Airline = this.flightData.carriers[0].name;
          }
          this.rarity = this.flightData.rarity;
          this.airlineClass = `${this.dealDict[0].pricing.fares[0].cabin_name}`;
          this.dealDate = new Date(this.dealDict[0].pricing.ticket_time_limit);

          //Extract Data
          this.originCity = this.departureFlight.originCity;
          this.originCity_Country = this.cities[1].iata_country_code;
          this.destinationCity = this.departureFlight.destinationCity;
          this.destinationCity_Country = this.cities[0].iata_country_code;
        }
        // Else if this is a Skyscanner entry
        else if (this.flightData.dealDictv2ComingSoon) {
          this.dealDict = this.flightData.dealDictv2;
          this.dealDictStatus = true;
          this.cabinName = this.flightData.cabin_name;

          let airports = this.flightData.airports;

          // citynames
          let departureFlight = this.dealDict[0].pricing.legs[0];
          let returnFlight = this.dealDict[0].pricing.legs[1];

          departureFlight.originCity = airports[0].origin.parent.name;
          departureFlight.destinationCity = airports[0].destination.parent.name;
          returnFlight.originCity = airports[0].destination.parent.name;
          returnFlight.destinationCity = airports[0].origin.parent.name;

          this.departureFlight = departureFlight;
          this.returnFlight = returnFlight;

          // arrival/departure cities details
          //this.cities.push(this.flightData.airports[0]);
          //this.cities.push(this.flightData.airports[1]);

          //slides
          let segments = [];
          segments.push(...this.dealDict[0].pricing.slices[0].segments);
          segments.push(...this.dealDict[0].pricing.slices[1].segments);
          let fares = [];
          this.dealDict[0].pricing.fares.forEach((fare) => {
            fare.segments.forEach((item) => {
              fares.push(item);
            });
          });

          segments = segments.map((seg) => {
            let data = {};

            airports.forEach((airport) => {
              if (airport.iata_code == seg.origin) {
                data.originCity = airport.city.name;
              }
              if (airport.iata_code == seg.destination) {
                data.destinationCity = airport.city.name;
              }
            });

            data.origin = seg.origin;
            data.destination = seg.destination;

            data.marketing_carrier = seg.marketing_carrier;

            fares.forEach((fare, i) => {
              let cabinKeys = Object.keys(fares[i].cabin_amenities);
              let amenities = cabinKeys.map((key) => {
                return fares[i].cabin_amenities[key].display_text;
              });

              if (fare.segment_id == seg.id) {
                data.cabin_amenities = amenities;
              }
            });

            this.flightData.aircraft.forEach((ar) => {
              if (ar.iata_code == seg.aircraft) {
                data.aircraftName = ar.name;
              }
            });
            if (this.flightData.carriers) {
              this.flightData.carriers.forEach((carrier) => {
                if (carrier.code == seg.operating_carrier) {
                  data.carrierName = carrier;
                }
              });
            }

            data.flighNumber = seg.marketing_carrier_flight_number;

            return data;
          });

          this.slides = [...segments];

          this.slices = this.dealDict[0].pricing.slices[0].segments;

          let departureSlice = this.dealDict[0].pricing.slices[0].segments.map(
            (slice) => {
              let duration = slice.duration.substring(2);
              duration = duration.replace("H", ":");
              duration = duration.replace("M", "");
              let minutesLength = duration.split(":")[1].trim().length;
              if (minutesLength < 2) {
                duration = duration.replace(":", `:0`);
              }

              return {
                ...slice,
                duration,
              };
            }
          );
          let returnSlice = this.dealDict[0].pricing.slices[1].segments.map(
            (slice) => {
              let duration = slice.duration.substring(2);
              duration = duration.replace("H", ":");
              duration = duration.replace("M", "");
              let minutesLength = duration.split(":")[1].trim().length;
              if (minutesLength < 2) {
                duration = duration.replace(":", `:0`);
              }

              return {
                ...slice,
                duration,
              };
            }
          );
          //adding full city names into the slices
          airports.forEach((airport) => {
            departureSlice = departureSlice.map((slice) => {
              if (airport.iata_code == slice.origin) {
                slice.originCity = airport.city.name;
              } else if (airport.iata_code == slice.destination) {
                slice.destinationCity = airport.city.name;
              }
              return slice;
            });
            returnSlice = returnSlice.map((slice) => {
              if (airport.iata_code == slice.origin) {
                slice.originCity = airport.city.name;
              } else if (airport.iata_code == slice.destination) {
                slice.destinationCity = airport.city.name;
              }
              return slice;
            });
          });

          this.returnSlice = returnSlice;
          this.departureSlice = departureSlice;
          // set initial slice as departure
          this.currentSlice = departureSlice;
          this.stopNumbers = 0;
          this.currentSlice.forEach((slide) => {
            if (
              slide.destination !== this.flightData.departure &&
              slide.destination !== this.flightData.arrival
            ) {
              this.stopNumbers++;
            }
          });

          this.slices = this.slices.map((slice) => {
            let duration = slice.duration.substring(2);
            duration = duration.replace("H", ":");
            duration = duration.replace("M", "");
            let minutesLength = duration.split(":")[1].trim().length;
            if (minutesLength < 2) {
              duration = duration.replace(":", `:0`);
            }
            return {
              origin: slice.origin,
              destination: slice.destination,
              duration,
            };
          });

          if (this.flightData.carriers) {
            this.Airline = this.flightData.carriers[0].name;
          }
          this.rarity = this.flightData.rarity;
          this.airlineClass = `${this.dealDict[0].pricing.fares[0].cabin_name}`;
          this.dealDate = new Date(this.dealDict[0].pricing.ticket_time_limit);

          //Extract Data
          this.originCity = this.departureFlight.originCity;
          this.originCity_Country = this.cities[1].iata_country_code;
          this.destinationCity = this.departureFlight.destinationCity;
          this.destinationCity_Country = this.cities[0].iata_country_code;
        }
        //Else this is a manual entry
        else {
          this.dealDictStatus = false;
          this.cabinName = this.flightData.cabin_name;
          this.dealDate = new Date(this.flightData.ticket_time_limit);
          this.originCity = this.flightData.depcity;
          this.originCity_Country = this.flightData.depcountry;
          this.destinationCity = this.flightData.arrcity;
          this.total_amount = this.flightData.total_amount;
          this.destinationCity_Country = this.flightData.arrcountry;
          this.rarity = this.flightData.rarity;
          this.airlineClass = this.cabinName;
          this.Airline = this.flightData.marketing_carrier;
        }

        let depDate = this.flightData.depDate;
        let arrDate = this.flightData.arrDate;

        let expires_at = format(new Date(this.dealDate), "M/d/yy");
        console.log(depDate);
        depDate = format(
          new Date(
            depDate.substring(0, 4),
            depDate.substring(5, 7) - 1,
            depDate.substring(8, 10)
          ),
          "M/d/yy"
        );
        arrDate = format(
          new Date(
            arrDate.substring(0, 4),
            arrDate.substring(5, 7) - 1,
            arrDate.substring(8, 10)
          ),
          "M/d/yy"
        );

        // let expires_at = format(new Date(dealDate), "M-d-yy");
        // depDate = format(new Date(depDate), "M-d-yy");
        // arrDate = format(new Date(arrDate), "M-d-yy");
        this.dealDetails = this.dealDetails.filter((detail) => {
          if (detail.name == "Airline") {
            detail.text = `<span class='alternate'>Airline:</span> ${this.Airline}`;
          } else if (detail.name == "Class") {
            detail.text = `<span class='alternate'>Class:</span> ${this.airlineClass}`;
          } else if (detail.name == "Dates") {
            detail.text = `<span class='alternate'>Dates:</span> ${depDate} - ${arrDate}`;
          } else if (detail.name == "Rarity") {
            detail.text = `<span class='alternate'>Rarity:</span> ${this.rarity}`;
          } else if (detail.name == "Expires_at") {
            detail.text = `<span class='alternate'>Deal expires:</span> ${expires_at}`;
          }
          return detail;
        });
        this.showdata = true;
      } catch (error) {
        console.log(error);
      }
    },

    reverseRoutes() {
      this.showoutbound = !this.showoutbound;
      if (this.showoutbound) {
        this.flightbtnText = "See Return Flight";
      } else if (!this.showoutbound) {
        this.flightbtnText = "Show Outbound Flight";
      }
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          property: "og:title",
          content: `Look at this sweet flight I found to ${this.flightData.arrcity} for $${this.flightData.total_amount}`,
        },
        {
          name: "twitter:title",
          content: `Look at this sweet flight I found to ${this.flightData.arrcity} for $${this.flightData.total_amount}`,
        },
        {
          property: "og:description",
          content: this.flightData.dest_description,
        },
        {
          name: "twitter:description",
          content: this.flightData.dest_description,
        },
        {
          property: "og:url",
          content: `https://www.browncowtravel.com/trips/${this.flightData.deal_id}`,
        },
        {
          property: "og:updated_time",
          content: this.flightData.dealLastUpdated,
        },
        {
          property: "og:image",
          content: `${this.flightData.image_sm}`,
        },
        {
          property: "og:image:secure_url",
          content: `${this.flightData.image_sm}`,
        },
        {
          name: "twitter:image",
          content: `${this.flightData.image_sm}`,
        },
        { property: "og:image:width", content: "560" },
        { property: "og:image:height", content: "373" },
        {
          property: "og:image:alt",
          content: `Brown Cow Travel - ${this.flightData.arrcity}`,
        },
        { property: "og:image:type", content: "image/jpeg" },
        { name: "twitter:card", content: "summary_large_image" },
      ],
    };
  },
};
</script>

<style lang="scss">
$headingColor: #5f4635;
@mixin xs {
  @media (max-width: "575px") {
    @content;
  }
}

@mixin sm {
  @media (max-width: "767px") {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: "991px") {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: "1199px") {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: "1440") {
    @content;
  }
}

@mixin customMin($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

@mixin customMax($screen) {
  @media (max-width: $screen) {
    @content;
  }
}
$primaryColor: #5f4635;
$secondaryColor: #ff3647;
$thirdColor: #212529;
.trips {
  &__depArr {
    // max-width: 100%;
    @include md {
      max-width: 100%;
    }
    &__city {
      // color: $primaryColor;
      color: $secondaryColor;
      font-size: 2rem;
      flex-basis: 100px;

      text-align: center;
      @include xs {
        font-size: 1.7rem;
      }
      @include customMax(400px) {
        font-size: 1.5rem;
      }
      @include customMax(375px) {
        font-size: 1.38rem;
      }
      @include customMax(360px) {
        font-size: 1.2rem;
      }
    }
    &__code {
      color: $primaryColor;
      font-size: 1.1rem;
      flex-basis: 100px;

      text-align: center;
    }
    &__to {
      font-weight: 800;
      font-size: 1.8rem;
      color: $secondaryColor;
      @include xs {
        font-size: 1.7rem;
      }
      @include customMax(400px) {
        font-size: 1.5rem;
      }
      @include customMax(375px) {
        font-size: 1.38rem;
      }
      @include customMax(360px) {
        font-size: 1.3rem;
      }
    }
    &__country {
      color: $thirdColor;
      font-weight: 600;
      font-size: 1rem;
    }
    &__country.stop {
      font-size: 0.8rem;
    }
  }
  &__flightLegs {
    padding-top: 10px;
    &__imgContainer {
      transform: translateY(-15px);
      margin-left: 5px;
      margin-right: 5px;
    }

    &__img {
      @include lg {
        max-width: 100px;
      }
    }

    &__time {
      font-weight: 700;

      max-width: 49px;
      text-align: center;
      position: absolute;
      top: -6px;

      left: 77px;
      padding-left: 11px !important;
      padding-right: 11px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      @include lg {
        left: 37px;
        padding-left: 8px !important;
        max-width: 42px;
      }
    }
  }

  &__returnFlightBtn {
    background-color: $secondaryColor !important;
    color: white !important;
    margin: auto;
    display: block;
    font-weight: 600 !important;
    @include lg {
      font-size: 0.9rem !important;
    }

    &:hover {
      background: $primaryColor !important;
      color: white !important;
    }
  }
  &__informatonPara {
    font-weight: 500;
    color: $primaryColor;

    @include xs {
      font-size: 0.9rem;
      text-align: center;
    }
  }

  &__flightLegInfo {
    &__content {
      &__flightdestContainer {
        @include xs {
          text-align: center;
        }
      }
      &__flightname {
        color: $thirdColor;
        @include md {
          text-align: center;
        }
        @include sm {
          font-size: 1.2rem;
        }
      }

      &__flightdest {
        font-weight: 700;
        padding-top: 15px;
        color: $primaryColor;
        @include xs {
          font-size: 0.9rem;
        }
        @include customMax(375px) {
          font-size: 0.75rem;
        }
        @include customMax(345px) {
          font-size: 0.7rem;
        }
      }
      &__feature {
        color: $primaryColor;
        font-weight: 700;
        @include xs {
          font-size: 0.85rem;
        }
      }
      &__featuresContainer {
        transform: translateY(-20px);
        @include sm {
          transform: translateY(0px);
          margin-top: 20px;

          margin-bottom: 40px;
        }
        @include xs {
          margin-bottom: 5px;
        }
      }
    }
  }
  &__similarFlights {
    &__heading {
      @include md {
        text-align: left;
      }
    }
    &__table {
      @include md {
        text-align: center;
        margin: auto;
      }
    }
    &__text {
      font-weight: 500;

      width: 230px;
      padding-left: 5px;
      @include md {
        padding-left: 0px;
      }
      @include xs {
        font-size: 0.9rem;
        padding-right: 5px;
      }
    }
  }

  // right side content styles
  &__priceBox {
    &__price {
      @include lg {
        font-size: 1.3rem;
      }
      @include md {
        font-size: 1.5rem;
      }
    }
    &__badges {
      @include lg {
        font-size: 0.7rem !important;
      }
      @include md {
        font-size: 0.75rem !important;
      }
    }
    &__normalprice {
      font-weight: 700;
      padding-top: 10px;
      color: $thirdColor;
      @include lg {
        font-size: 0.85rem;
        text-align: center;
      }
    }
    &__normalprice .alternate {
      font-weight: 500;
    }
  }

  &__dealDetails {
    &__headingContainer {
      width: 100%;
      margin: auto;
      @include md {
        width: 90%;
      }
      &__heading {
        text-align: left !important;

        @include md {
          text-align: center !important;
        }
      }
    }
    @include md {
      text-align: center;
    }
    &__iconContainer {
      flex-basis: 7%;
      max-width: 7%;
    }
    &__icon {
      color: $secondaryColor !important;

      @include lg {
        width: 1.125em !important;
        // transform: translateY(3px);
      }
      @include xs {
        transform: translateY(0px);
      }
    }
    &__text {
      color: $primaryColor;
      font-weight: 500;
      text-align: left;
      // font-size: 1.1rem;
      @include lg {
        font-size: 0.95rem;
      }
      @include md {
        font-size: 1.1rem;
      }
      @include xs {
        font-size: 0.9rem;
      }
    }
    &__text .alternate {
      font-weight: 700;
    }
  }
  &__dealDetailsContainer {
    @include md {
      margin-left: auto;
      margin: auto;
    }
  }
  &__share {
    &__headingContainer {
      width: 100%;
      margin: auto;
      // @include md {
      //   width: 90%;
      // }
      &__heading {
        text-align: left;
      }
    }
    &__text {
      font-weight: 600;
      color: $thirdColor;
      @include lg {
        font-size: 0.95rem;
      }
      @include md {
        text-align: center;
      }
    }
  }
  &__rightColumn {
    position: sticky;
    top: 20px;
    margin-top: 20px;
  }
  // &__rightColumn .card {
  // }
}
.trips hr {
  @include md {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100% !important;
  }
}
.paperplan {
  font-size: 200px;
  color: $secondaryColor;
  @include xs {
    font-size: 110px;
  }
}
.checksquare {
  color: $secondaryColor;
}

// swiper slides styles
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "";
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 350px;
  left: initial;
  bottom: 10px;
  top: initial;
  @include lg {
    right: 160px;
  }
  @include sm {
    right: -64%;
    bottom: 33px;
    position: relative;
  }
  @include xs {
    right: -50%;
    bottom: -10px;
  }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  right: initial;
  left: 400px;
  bottom: 10px;
  top: initial;
  @include lg {
    left: 320px;
  }
  @include sm {
    left: 32%;
    bottom: 11px;
    position: relative;
  }
  @include xs {
    left: 35%;
    bottom: -32px;
  }
}

.swiper-pagination {
  @include xs {
    display: none;
  }
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 5px;
  left: 18px;
  @include lg {
    left: 78px;
  }
  @include sm {
    bottom: 0px;
    left: 0px;
  }
}

.fa-arrow-circle-right,
.fa-arrow-circle-left {
  color: $thirdColor;
}
.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  background-color: $secondaryColor !important;
  opacity: 0.2;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
}

th {
  width: 150px;

  padding: 5px;
}

.no__deals {
  font-size: 1rem;
  color: $headingColor;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>
