<template>
  <div class="WordSection1">
    <p class=MsoNormal align=center style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
auto;text-align:center;line-height:normal;mso-outline-level:1;background:white'><span
style='font-size:24.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:#171717;mso-font-kerning:18.0pt'>Privacy Notice for Individuals in the United States<o:p></o:p></span></p>
    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <b
        ><span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            color: #171717;
            letter-spacing: -0.25pt;
          "
          >Date Posted:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        ></b
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >December 1, 2021<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <b
        ><span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            color: #171717;
            letter-spacing: -0.25pt;
          "
          >Effective Date:&nbsp;</span
        ></b
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >December 1, 2021<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >BROWN COW TRAVEL (collectively, 'we,' 'our,' or 'us') provide this
        privacy notice to describe how we process the information you provide
        when visiting our websites ('Site'), or otherwise interact with
        us.&nbsp;<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <b
        ><span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            color: #171717;
            letter-spacing: -0.25pt;
          "
          >Information Collection</span
        ></b
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
      >
        <o:p></o:p>
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >We collect information you provide to us when you visit our Site,
        become a member of BROWN COW TRAVEL, or otherwise interact with us,
        including your name, address, telephone number, email address, and
        payment information.&nbsp; If you post your resume to or apply for a
        position through the BROWN COW TRAVEL job board, we collect information
        related to your posting and application including your name, contact
        information, educational and professional history, and status as a
        veteran.&nbsp; BROWN COW TRAVEL may collect information about you from
        our members and partners and from sources outside of BROWN COW TRAVEL
        who provide data for marketing purposes.<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >We also collect information relating to your interactions with our
        Site, including the operating system, browser, country, IP address, date
        and time of visit, pages visited and other activities on the Site, and
        whether you visit our Site through a mobile device or personal
        computer.&nbsp;<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <b
        ><span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            color: #171717;
            letter-spacing: -0.25pt;
          "
          >Information Use</span
        ></b
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        ><o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >We may use the information we collect for any lawful purpose,
        including:<o:p></o:p
      ></span>
    </p>

    <ul type="disc">
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >To organize events, including to promote events and send
          communications regarding event registration and
          <span class="GramE">participation;</span><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >To facilitate attendance at events, including engaging speakers and
          exhibitors, or helping to provide housing and other event
          <span class="GramE">services;</span><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >For membership purposes, such as to provide information related to
          your employer's membership or <span class="GramE">account;</span
          ><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >To provide communications to which you subscribe or that are relevant
          to <span class="GramE">you;</span><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >To publish data on job <span class="GramE">boards;</span><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >To administer <span class="GramE">testing;</span><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >To promote BROWN COW TRAVEL <span class="GramE">services;</span
          ><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >To deliver <span class="GramE">publications;</span><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >To respond to your <span class="GramE">inquiries;</span><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >For fundraising <span class="GramE">purposes;</span><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >To process PAC <span class="GramE">donations;</span><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >For business purposes, such as analytics, research, marketing,
          recruitment, and operational <span class="GramE">purposes;</span
          ><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >To comply with the law; and/or<o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l0 level1 lfo1;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >As otherwise disclosed at the time information is collected.<o:p
          ></o:p
        ></span>
      </li>
    </ul>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <b
        ><span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            color: #171717;
            letter-spacing: -0.25pt;
          "
          >Information Sharing</span
        ></b
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        ><o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >We may share information that identifies you individually with third
        parties, including the following:<o:p></o:p
      ></span>
    </p>

    <ul type="disc">
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l1 level1 lfo2;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >With third parties that perform services on our behalf, including
          data storage providers, technology vendors, email service providers,
          marketing and advertising providers, and service providers used to
          assist with our events and <span class="GramE">activities;</span
          ><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l1 level1 lfo2;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >With other BROWN COW TRAVEL members, including through committees and
          forums used to connect members and the job
          <span class="GramE">board;</span><o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l1 level1 lfo2;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >With attendees of our events regarding information related to the
          event, such as exhibitor and sponsor attendance; and<o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l1 level1 lfo2;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >With your consent; or<o:p></o:p
        ></span>
      </li>
      <li
        class="MsoNormal"
        style="
          color: #171717;
          mso-margin-top-alt: auto;
          mso-margin-bottom-alt: auto;
          line-height: normal;
          mso-list: l1 level1 lfo2;
          tab-stops: list 0.5in;
          background: white;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            letter-spacing: -0.25pt;
          "
          >As otherwise disclosed at the time of data collection or sharing.<o:p
          ></o:p
        ></span>
      </li>
    </ul>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >We may share information that has been de-identified or aggregated, or
        any other technical information, without limitation.<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <b
        ><span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            color: #171717;
            letter-spacing: -0.25pt;
          "
          >Third-Party Links</span
        ></b
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        ><o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >The Site may provide links to third-party websites or apps, including
        our social media pages.&nbsp;We do not control the privacy practices of
        those websites or apps, and they are not covered by this privacy
        notice.&nbsp;You should review the privacy notices of other websites or
        apps that you use to learn about their data practices.<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >The Site may also include integrated social media tools or 'plug-ins'
        such as social networking, photo sharing, and video streaming tools
        offered by third parties.&nbsp;If you use these tools to share personal
        information or <span class="GramE">you otherwise interact</span> with
        social media features on the Site, those social media companies may
        collect information about you and may use and share such information in
        accordance with your account settings, including by sharing such
        information with the general public.&nbsp;Your interactions with
        third-party social media companies and the use of their features are
        governed by the privacy policies of the social media companies that
        provide those features.&nbsp;We encourage you to carefully read the
        privacy policies of any social media accounts you create and use.<o:p
        ></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <b
        ><span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            color: #171717;
            letter-spacing: -0.25pt;
          "
          >Digital Advertising &amp; Analytics</span
        ></b
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        ><o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >We may work with ad networks and other ad serving providers
        ('Advertising Providers') that serve ads on our behalf on non-affiliated
        platforms.&nbsp;Some of those ads may be personalized, meaning that they
        are intended to be relevant to you based on information Advertising
        Providers collect about your use of the Site and other sites or apps
        over time, including information about relationships among different
        browsers and devices.&nbsp;This type of advertising is known as
        interest-based advertising.<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >Your choice applies to this browser, and if you delete your cookies,
        use a different browser or device, or reset your advertising identifier
        you may need to make your choices again.&nbsp;After you exercise choice,
        you will still receive advertising <span class="GramE">online</span> but
        that advertising may be less relevant to your interests.&nbsp;Data may
        also continue to be collected for other purposes such as analytics,
        frequency capping, and reporting.<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >We may also work with third parties that collect data about your use of
        the Site and other sites or apps over time for non-advertising
        purposes.&nbsp;We use Google Analytics and other third-party services to
        improve the performance of the Site and for analytics and marketing
        purposes.&nbsp;For more information about how Google Analytics collects
        and uses data when you use our website, visit&nbsp;</span
      ><span style="color: black; mso-color-alt: windowtext"
        ><a
          href="https://www.google.com/policies/privacy/partners/"
          target="_blank"
          ><span
            style="
              font-size: 12pt;
              font-family: 'Arial', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #146aa4;
              letter-spacing: -0.25pt;
            "
            >www.google.com/policies/privacy/partners/</span
          ></a
        ></span
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >, and to opt out of Google Analytics, visit&nbsp;</span
      ><span style="color: black; mso-color-alt: windowtext"
        ><a href="https://tools.google.com/dlpage/gaoptout/" target="_blank"
          ><span
            style="
              font-size: 12pt;
              font-family: 'Arial', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #146aa4;
              letter-spacing: -0.25pt;
            "
            >tools.google.com/<span class="SpellE">dlpage</span>/<span
              class="SpellE"
              >gaoptout</span
            >/</span
          ></a
        ></span
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >.<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >We also use Hotjar, a technology service that helps us better
        understand our users' experience (<span class="GramE">e.g.</span> how
        much time they spend on which pages, which links they choose to click,
        what users do and don't like, etc.) and this enables us to build and
        maintain our service with users' feedback.&nbsp;Hotjar uses cookies and
        other technologies to collect data on our users' behavior and their
        devices (in particular, device IP address, device screen size, device
        type, unique device identifiers, browser information, geographic
        location, and preferred language).&nbsp;For further details, please see
        Hotjar's privacy policy by clicking through on this&nbsp;</span
      ><span style="color: black; mso-color-alt: windowtext"
        ><a href="https://www.hotjar.com/legal/policies/privacy" target="_blank"
          ><span
            style="
              font-size: 12pt;
              font-family: 'Arial', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #146aa4;
              letter-spacing: -0.25pt;
            "
            >link</span
          ></a
        ></span
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >.&nbsp;You can opt-out to the creation of a user profile, Hotjar's
        storing of data about your usage of our site, and Hotjar's use of
        tracking cookies on other websites by following this opt-out&nbsp;</span
      ><span style="color: black; mso-color-alt: windowtext"
        ><a
          href="https://www.hotjar.com/legal/compliance/opt-out"
          target="_blank"
          ><span
            style="
              font-size: 12pt;
              font-family: 'Arial', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #146aa4;
              letter-spacing: -0.25pt;
            "
            >link</span
          ></a
        ></span
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >.&nbsp;<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <b
        ><span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            color: #171717;
            letter-spacing: -0.25pt;
          "
          >Your Choices</span
        ></b
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        ><o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >To opt-out of our e-mail communications or to cancel your subscription,
        you can use the link provided at the bottom of each email message, or if
        applicable, you can change your preferences in your BROWN COW TRAVEL
        account.<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <b
        ><span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            color: #171717;
            letter-spacing: -0.25pt;
          "
          >Updating Your Information</span
        ></b
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        ><o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >You may request to review, change, or update your contact information
        by using the information in the 'Contact' section.<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <b
        ><span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            color: #171717;
            letter-spacing: -0.25pt;
          "
          >Changes to Our Privacy Notice</span
        ></b
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        ><o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >If we decide to change our privacy notice, we will post these changes
        on this page.&nbsp; We encourage you to visit this page periodically to
        learn of updates<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <b
        ><span
          style="
            font-size: 12pt;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            color: #171717;
            letter-spacing: -0.25pt;
          "
          >Contact</span
        ></b
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        ><o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >If you have questions, comments, or concerns about this privacy notice,
        please contact us at:<o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span style="color: black; mso-color-alt: windowtext">&#8234;</span
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        >(704) 251-5008 </span
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
        ><o:p></o:p
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        mso-margin-bottom-alt: auto;
        line-height: normal;
        background: white;
      "
    >
      <span style="color: black; mso-color-alt: windowtext"
        ><a href="https://BrownCowTravel.com/"
          ><span
            style="
              font-size: 12pt;
              font-family: 'Arial', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              letter-spacing: -0.25pt;
            "
            >https://BrownCowTravel.com/</span
          ></a
        ></span
      ><span
        style="
          font-size: 12pt;
          font-family: 'Arial', sans-serif;
          mso-fareast-font-family: 'Times New Roman';
          color: #171717;
          letter-spacing: -0.25pt;
        "
      >
        <o:p></o:p
      ></span>
    </p>

    <p class="MsoNormal"><o:p>&nbsp;</o:p></p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
