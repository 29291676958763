<template>
  <div>
    <div class="row stepsContainer shadow-sm">
      <div class="row d-flex mx-auto position-relative">
        <div class="position-absolute px-5" style="top: 25%; left: 0">
          <div
            class="d-none d-md-block w-75 py-1 mx-auto bg-white shadow-sm"
          ></div>
        </div>
        <div class="col">
          <div class="row">
            <div
              class="col-3 col-md-3 d-flex flex-column align-items-center mb-4"
              style="z-index: 10"
            >
              <div
                class="mb-4 d-flex align-items-center justify-content-center shadow-sm bg-white rounded-circle circles__div"
              >
                <div
                  class="d-flex align-items-center justify-content-center fw-bold rounded-circle"
                  style="width: 90%; height: 90%"
                  :class="{
                    'bct-primary-circle': current == 'step1',
                    'text-white': current == 'step1',
                    'bg-light': current !== 'step1',
                  }"
                  @click="setStep(1)"
                >
                  1
                </div>
              </div>
              <h5 class="fw-bold text-center circles__subheading">
                Create Your Account
              </h5>
            </div>
            <div
              class="col-3 col-md-3 d-flex flex-column align-items-center mb-4"
              style="z-index: 10"
            >
              <div
                class="mb-4 d-flex align-items-center justify-content-center shadow-sm bg-white rounded-circle circles__div"
              >
                <div
                  class="d-flex align-items-center justify-content-center fw-bold rounded-circle"
                  style="width: 90%; height: 90%"
                  :class="{
                    'bct-primary-circle': current == 'step2',
                    'text-white': current == 'step2',
                    'bg-light': current !== 'step2',
                  }"
                  @click="setStep(2)"
                >
                  2
                </div>
              </div>
              <h5 class="fw-bold text-muted text-center circles__subheading">
                Select Package
              </h5>
            </div>
            <div
              class="col-3 col-md-3 d-flex flex-column align-items-center mb-4"
              style="z-index: 10"
            >
              <div
                class="mb-4 d-flex align-items-center justify-content-center shadow-sm bg-white rounded-circle circles__div"
              >
                <div
                  class="d-flex align-items-center justify-content-center fw-bold rounded-circle"
                  style="width: 90%; height: 90%"
                  :class="{
                    'bct-primary-circle': current == 'step3',
                    'text-white': current == 'step3',
                    'bg-light': current !== 'step3',
                  }"
                  @click="setStep(3)"
                >
                  3
                </div>
              </div>
              <h5 class="fw-bold text-muted text-center circles__subheading">
                Start Free Trial
              </h5>
            </div>
            <div
              class="col-3 col-md-3 d-flex flex-column align-items-center mb-4"
              style="z-index: 10"
            >
              <div
                class="mb-4 d-flex align-items-center justify-content-center shadow-sm bg-white rounded-circle circles__div"
              >
                <div
                  class="d-flex align-items-center justify-content-center fw-bold rounded-circle"
                  style="width: 90%; height: 90%"
                  :class="{
                    'bct-primary-circle': current == 'step4',
                    'text-white': current == 'step4',
                    'bg-light': current !== 'step4',
                  }"
                  @click="setStep(4)"
                >
                  4
                </div>
              </div>
              <h5 class="fw-bold text-muted text-center circles__subheading">
                Create Searches
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component :is="current" @checkout="checkout"></component>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <h3 class="fw-bold mb-5">
              Congratulations first_name! Your account all setup - cows dropping
              great deals directly to your inbox!
            </h3>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import step1 from "@/components/signup/step1";
import step2 from "@/components/signup/step2";
import step3 from "@/components/signup/step3";
import step4 from "@/components/signup/step4";

export default {
  name: "Signup",
  components: {
    step1,
    step2,
    step3,
    step4,
  },
  watch: {
    step(value) {
      if (value == 1) {
        this.current = "step1";
      } else if (value == 2) {
        this.current = "step2";
      } else if (value == 3) {
        this.current = "step3";
      } else if (value == 4) {
        this.current = "step4";
      }
    },
  },
  data() {
    return {
      current: "step1",
      step: 0,
      isAuthenticated: false,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      loading: false,

      email: "",
      successURL: ``,
      cancelURL: "http://localhost:3000/register?redirected=true",
    };
  },

  mounted() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

    let isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));
    if (isAuthenticated) {
      this.isAuthenticated = isAuthenticated;
    }
    let step;
    if (this.$route.query.step) {
      step = this.$route.query.step;
    } else if (localStorage.getItem("step")) {
      step = localStorage.getItem("step");
    }

    if (this.$route.query.subscriptionId) {
      let submax = 0;
      let selectedPackage = this.$route.query.package;
      if (selectedPackage.toLowerCase() == "platinum") {
        localStorage.setItem("allowedsaves", 10);
        submax = 20;
      } else {
        localStorage.setItem("allowedsaves", 5);
        submax = 10;
      }
      step = 4;
      this.$store.commit("SetShowNavLinks", true);
      this.$store.commit("SetSubMax", submax);
    }
    if (!step) {
      if (!this.isAuthenticated) {
        this.step = 1;
      } else if (this.isAuthenticated) {
        this.step = 2;
      }
      localStorage.setItem("step", this.step); //value will be either 1 or 2 set to storage
      this.$store.commit("setStep", this.step);

      return;
    } else if (step) {
      if (step <= 1 && !this.isAuthenticated) {
        this.step = 1;
        return;
      }
      if (step <= 1 && this.isAuthenticated) {
        this.step = 2;
        localStorage.setItem("step", this.step);
        this.$store.commit("setStep", this.step);

        return;
      }

      if (step > 1) {
        this.step = step;
        return;
      }
    }
  },

  methods: {
    async checkout(pkgData) {
      let priceId;

      if (pkgData.pkg == "gold") {
        priceId = process.env.VUE_APP_PRICEID_GOLD;
      } else {
        priceId = process.env.VUE_APP_PRICEID_PLATINUM;
      }

      this.email = this.$auth.user.email;
      let data = {
        email: this.email,
        priceId,
        id: this.$auth.user.sub,
        package: pkgData.pkg,
        stripeid:
          this.$auth.user["https://app.browncowtravel.com/SubStripeCust"],
      };

      if (pkgData.coupon) {
        data.coupon = pkgData.coupon;
      }

      if (
        this.$auth &&
        this.$auth.user &&
        this.$auth.user["https://app.browncowtravel.com/SubMax"] &&
        this.$auth.user["https://app.browncowtravel.com/SubMax"] > 0
      ) {
        data.action = "update";
      }

      const res = await axios.post(
        `${process.env.VUE_APP_PAYMENT_API}/payment`,
        data
      );
      this.$store.commit("setStripeData", {
        subscriptionId: res.data.subscriptionId,
        clientSecret: res.data.clientSecret ? res.data.clientSecret : "",
      });

      if (
        data.coupon &&
        res.data.subscriptionId &&
        !res.data.hasOwnProperty("clientSecret")
      ) {
        let step = 4;
        this.$store.commit("setStep", step);
        localStorage.setItem("step", step);
        this.step = step;
        let subMax = this.$auth.user["https://app.browncowtravel.com/SubMax"];
        window.location.href = `/register?package=${pkgData.pkg}&step=${this.step}&subscriptionId=${res.data.subscriptionId}&subMax=${subMax}`;

        // this.$router.replace({
        //   path: "/register",
        //   query: {
        //     package: pkgData.pkg,
        //     subscriptionId: res.data.subscriptionId,
        //     step: this.step,
        //     subMax: subMax,
        //   },
        // });

        return;
      }

      if (data.action && res.data.subscriptionId) {
        this.step = 4;
        this.$store.commit("setStep", this.step);
        localStorage.setItem("step", this.step);
        let subMax = this.$auth.user["https://app.browncowtravel.com/SubMax"];
        // this.$router.replace({
        //   path: "/register",
        //   query: {
        //     package: pkgData.pkg,
        //     subscriptionId: res.data.subscriptionId,
        //     step: this.step,
        //     subMax: subMax,
        //   },
        // });
        window.location.href = `/register?package=${pkgData.pkg}&step=${this.step}&subscriptionId=${res.data.subscriptionId}&subMax=${subMax}`;
      } else if (res.data.subscriptionId) {
        this.step = 3;
        this.$store.commit("setStep", this.step);
        localStorage.setItem("step", this.step);
      }
    },
    setStep(x) {
      if (x == 1 && this.isAuthenticated) {
        return;
      }

      if ((x == 2 || x == 3 || x == 4) && !this.isAuthenticated) {
        return;
      }

      if (this.step == 2 && (x == 3 || x == 4)) {
        return;
      }
      if (this.step == 4 && x == 3) {
        return;
      }
      if (this.step == 4 && x == 2) {
        return;
      }

      this.step = x;
    },
  },
  props: {
    msg: String,
  },
};
</script>

<style lang="scss">
$headingColor: #5f4635;
$secondaryColor: #ff3647;
$primaryColor: #5f4635;

@mixin xs {
  @media (max-width: "575px") {
    @content;
  }
}

@mixin sm {
  @media (max-width: "767px") {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: "991px") {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: "1199px") {
    @content;
  }
}
.bct-primary-circle {
  background-color: $secondaryColor !important;
}
.stepsContainer {
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
}

.circles {
  &__div {
    width: 100px;
    height: 100px;
    @include md {
      width: 80px;
      height: 80px;
    }
    @include sm {
      width: 70px;
      height: 70px;
    }
    @include xs {
      width: 60px;
      height: 60px;
    }
  }

  &__subheading {
    @include md {
      font-size: 1.2rem !important;
    }
    @include sm {
      font-size: 1.1rem !important;
    }
    @include xs {
      font-size: 0.9rem !important;
    }
    @media (max-width: 360px) {
      font-size: 0.8rem !important;
    }
  }
}
</style>
