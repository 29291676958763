<template>
  <div class="billing">
    <h1 class="billing__heading">Billing</h1>
    <div class="billing__container">
      <!-- <h4 class="billing__content">
        Congrats! You are on a Free Beta Plan - No Moola Required
      </h4> -->
      <div
        v-if="$route.query.subMax == 10 || $route.query.subMax == 20"
        class="d-flex flex-md-row flex-column align-items-center justify-content-center"
      >
        <div
          class="rounded shadow-sm me-3 packageCard"
          :class="{ selected: $route.query.subMax == 10 }"
        >
          <h5
            class="text-center"
            :class="{ selectedHeading: $route.query.subMax == 10 }"
            v-if="$route.query.subMax == 10"
          >
            YOUR PLAN
          </h5>
          <h5
            v-else
            :class="{ alternateHeading: $route.query.subMax != 10 }"
          ></h5>
          <div class="px-3 py-xl-4 py-sm-3 py-2 text-center bg-white">
            <h3 class="mb-2 fs-1 fw-bold">Gold</h3>
            <span class="fs-1 fw-bold">$1 per month</span>
            <p class="mt-1 text-muted">billed monthly after FREE trial</p>
            <div
              class="my-sm-4 my-2 my-4 py-sm-2 py-0 d-flex justify-content-center"
            >
              <ul class="list-unstyled text-start text-muted mb-0 packageList">
                <li class="mb-2 d-flex">
                  <svg
                    class="me-xl-2 me-1 list-icon text-success"
                    width="26"
                    height="26"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>7 Day Free Trial</span>
                </li>
                <li class="mb-2 d-flex">
                  <svg
                    class="me-xl-2 me-1 list-icon text-success"
                    width="26"
                    height="26"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>5 Saved Flight Searches</span>
                </li>
                <li class="mb-2 d-flex">
                  <svg
                    class="me-xl-2 me-1 list-icon text-success"
                    width="26"
                    height="26"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>Economy Seats</span>
                </li>
                <li class="mb-2 d-flex">
                  <svg
                    class="me-xl-2 me-1 list-icon text-success"
                    width="26"
                    height="26"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>Domestic and International Flights</span>
                </li>
                <li class="mb-2 d-flex">
                  <svg
                    class="me-xl-2 me-1 list-icon text-success"
                    width="26"
                    height="26"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>Special Deals from Home Airports</span>
                </li>
              </ul>
            </div>
            <!-- <input placeholder="COUPON" type="text" v-model="gold" /> -->

            <button
              v-if="$route.query.subMax == 20"
              class="btn btn-primary px-5 d-block mt-4 ml-0 mr-0"
              @click="$modal.show('downgrademodal')"
            >
              Downgrade Plan
            </button>
          </div>
        </div>
        <div
          class="rounded shadow-sm me-3 packageCard"
          :class="{ selected: $route.query.subMax == 20 }"
        >
          <h5
            class="text-center"
            :class="{ selectedHeading: $route.query.subMax == 20 }"
            v-if="$route.query.subMax == 20"
          >
            YOUR PLAN
          </h5>
          <h5
            v-else
            :class="{ alternateHeading: $route.query.subMax != 20 }"
          ></h5>
          <div class="px-3 py-xl-4 py-sm-3 py-2 text-center bg-white">
            <h3 class="mb-2 fs-1 fw-bold">Platinum</h3>
            <span class="fs-1 fw-bold">$3 per month</span>
            <p class="mt-1">billed monthly after FREE trial</p>
            <div
              class="my-sm-4 my-2 my-4 py-sm-2 py-0 d-flex justify-content-center"
            >
              <ul class="list-unstyled text-muted text-start mb-0 packageList">
                <li class="mb-2 d-flex">
                  <svg
                    class="me-xl-2 me-1 list-icon text-success"
                    width="26"
                    height="26"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>7 Day Free Trial</span>
                </li>
                <li class="mb-2 d-flex">
                  <svg
                    class="me-xl-2 me-1 list-icon text-success"
                    width="26"
                    height="26"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>10 Saved Flight Searches</span>
                </li>
                <li class="mb-2 d-flex">
                  <svg
                    class="me-xl-2 me-1 list-icon text-success"
                    width="26"
                    height="26"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>Economy, Business and First-Class Seats</span>
                </li>
                <li class="mb-2 d-flex">
                  <svg
                    class="me-xl-2 me-1 list-icon text-success"
                    width="26"
                    height="26"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>Domestic and International Flights</span>
                </li>
                <li class="mb-2 d-flex">
                  <svg
                    class="me-xl-2 me-1 list-icon text-success"
                    width="26"
                    height="26"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewbox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>Special Deals from Home Airports</span>
                </li>
              </ul>
            </div>
            <!-- <input placeholder="COUPON" type="text" v-model="platinum" /> -->
            <button
              v-if="$route.query.subMax == 10"
              class="btn btn-primary px-5 d-block mt-4 ml-0 mr-0"
              @click="$modal.show('upgrademodal')"
            >
              Upgrade Plan
            </button>
          </div>
        </div>
      </div>
      <div class="subsPortal">
        <div>
          <h4>
            To change your payment method or cancel your subscription
            auto-renewal, click the buton below to go to our subscription
            Portal, powered by Stripe.
          </h4>
          <form @submit.prevent="submit">
            <!-- <input type="text" hidden name="email" :value="email" />
            <input type="text" hidden name="id" :value="userId" />
            <input type="text" hidden name="stripeid" :value="stripeid" /> -->
            <button type="submit" class="btn btn-primary">
              Subscription Portal
            </button>
          </form>
        </div>
      </div>
    </div>
    <!-- downgrade Modal -->
    <modal
      classes="routes__modalContainer"
      name="downgrademodal"
      :width="'300px'"
      :height="'auto'"
      :adaptive="true"
    >
      <div
        class="position-relative d-flex justify-content-center py-4 px-4 text-center w-100"
      >
        <font-awesome-icon
          class="routes__modal__close"
          @click="$modal.hide('downgrademodal')"
          :icon="['fas', 'times']"
        />
        <div class="routes__modal">
          <h4 class="routes__modal__heading downgrade">
            Are you sure you wish to downgrade your plan? Some of your saved
            routes will be deleted.
          </h4>
          <div
            class="d-flex flex-column justify-content-center align-items-center mt-4"
          >
            <button
              :style="{ width: '160px' }"
              @click="downgradeModal"
              class="btn btn-primary"
            >
              Yes – Downgrade
            </button>
            <button
              :style="{ width: '160px' }"
              @click="$modal.hide('downgrademodal')"
              class="btn btn-primary mx-auto mt-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </modal>
    <!-- upgrade Modal -->
    <modal
      classes="routes__modalContainer"
      name="upgrademodal"
      :width="'300px'"
      :height="'auto'"
      :adaptive="true"
    >
      <div
        class="position-relative d-flex justify-content-center py-4 px-4 text-center w-100"
      >
        <font-awesome-icon
          class="routes__modal__close"
          @click="$modal.hide('upgrademodal')"
          :icon="['fas', 'times']"
        />
        <div class="routes__modal">
          <h4 class="routes__modal__heading upgrade">
            Are you sure you wish to upgrade your plan? After your free trial
            the upgrade fees will be charged.
          </h4>
          <div
            class="d-flex flex-column justify-content-center align-items-center mt-4"
          >
            <button
              :style="{ width: '160px' }"
              @click="upgradeModal"
              class="btn btn-primary"
            >
              Yes – Upgrade
            </button>
            <button
              :style="{ width: '160px' }"
              @click="$modal.hide('upgrademodal')"
              class="btn btn-primary mx-auto mt-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      email: null,
      userId: null,
      stripeid: null,
    };
  },
  mounted() {
    this.stripeid =
      this.$auth.user["https://app.browncowtravel.com/SubStripeCust"];
    if (this.id) {
      this.userId = this.id;
    }
    this.email = this.$auth.user.email;
  },
  methods: {
    downgradeModal() {
      this.selectedPackage("gold");
      this.$modal.hide("downgrademodal");
    },
    upgradeModal() {
      this.selectedPackage("platinum");
      this.$modal.hide("upgrademodal");
    },
    selectedPackage(pkg) {
      let data = {
        pkg,
      };
      this.checkout(data);
    },
    async checkout(pkgData) {
      let priceId =
        pkgData.pkg == "gold"
          ? process.env.VUE_APP_PRICEID_GOLD
          : process.env.VUE_APP_PRICEID_PLATINUM;

      let data = {
        email: this.$auth.user.email,
        priceId,
        id: this.$auth.user.sub,
        package: pkgData.pkg,
        action: "update",
        subscription: this.$store.state.subscriptionId,
        stripeid:
          this.$auth.user["https://app.browncowtravel.com/SubStripeCust"],
      };

      const res = await axios.post(
        `${process.env.VUE_APP_PAYMENT_API}/payment`,
        data
      );

      if (res.data.subscriptionId) {
        this.$store.commit("setStripeData", {
          subscriptionId: res.data.subscriptionId,
          clientSecret: res.data.clientSecret ? res.data.clientSecret : "",
        });
        let subMax = this.$auth.user["https://app.browncowtravel.com/SubMax"];
        let step = 4;
        this.$store.commit("setStep", step);
        localStorage.setItem("step", step);
        localStorage.setItem("allowedsaves", 10);

        window.location.href = `/user?name=billing&subMax=${subMax}&subscriptionId=${res.data.subscriptionId}`;
      }
    },
    async submit() {
      try {
        let data = {
          email: this.email,
          id: this.id,
          stripeid:
            this.$auth.user["https://app.browncowtravel.com/SubStripeCust"],
        };

        const res = await axios.post(
          `${process.env.VUE_APP_PAYMENT_API}/sub-portal`,
          data
        );
        if (res.data.location) {
          window.location.href = res.data.location;
        }
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  computed: {
    id() {
      return this.$store.state.userid;
    },
  },
};
</script>

<style lang="scss" scoped>
$primaryColor: #5f4635;
$secondaryColor: #ff3647;

@mixin xs {
  @media (max-width: "575px") {
    @content;
  }
}

@mixin sm {
  @media (max-width: "767px") {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: "991px") {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: "1199px") {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: "1440px") {
    @content;
  }
}

@mixin customMin($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

@mixin customMax($screen) {
  @media (max-width: $screen) {
    @content;
  }
}

.billing {
  margin: 30px 0;
  &__heading {
    color: $primaryColor;
    font-size: 1.35rem;
  }
  &__content {
    font-size: 1.3rem;
    color: $primaryColor;
  }
  &__container {
    box-shadow: 0px 0px 5px 1px #d8d9dc;

    padding: 20px 0px 20px;
    max-height: 2500px;
  }
}

.selected {
  border: 2px solid $secondaryColor !important;
}
.selectedHeading {
  background: $secondaryColor !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: white !important;
}
.alternateHeading {
  height: 40px !important;
  display: flex !important;
}
.packageCard {
  height: 520px;
  width: 400px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important;
  @include customMax(1400px) {
    width: 360px;
    height: 543px;
  }
  @include lg {
    width: 310px;
  }
  @include sm {
    width: 340px;
    height: 500px;
    margin-right: 0 !important;
    margin-top: 20px !important;
  }
  @include xs {
    width: 80%;
    height: 470px;
  }
  @include customMax(450px) {
    width: 90%;
  }
  @include customMax(340px) {
    width: 93%;
    height: 480px;
  }
  @include customMax(330px) {
    height: 460px;
  }
}

.list-icon {
  flex-basis: 35px;
  max-width: 25px;
  @include lg {
    flex-basis: 40px;
    max-width: 30px;
    margin-right: 0 !important;
  }
}
.packageList li span {
  @include xs {
    font-size: 0.9rem;
  }
  @include customMax(340px) {
    font-size: 0.8rem;
  }
}

.subsPortal {
  padding: 30px 60px;
  display: flex;
  justify-content: center;
  @include customMax(1399px) {
    padding: 30px 40px;
  }
  @include lg {
    padding: 30px 22px;
  }
  @include sm {
    padding: 30px 76px;
  }
  @include xs {
    padding: 30px 46px;
  }
  @include customMax(450px) {
    padding: 30px 20px;
  }
  h4 {
    font-size: 1rem;
    padding-bottom: 10px;
    color: $primaryColor;
  }
}

.routes__modal__heading.downgrade,
.routes__modal__heading.upgrade {
  margin-top: 30px;
}
</style>
