<template>
  <div class="trips__graphicContent">
    <slot>
      <img
        v-if="src"
        class="w-100 img-fluid trips__graphicContent__placeholderImg"
        :src="src"
      />
      <img
        v-else
        class="w-100 img-fluid trips__graphicContent__placeholderImg"
        :src="require('../../assets/images/placeholder.jpg')"
        alt=""
      />
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: [String],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.trips {
  &__graphicContent {
    &__placeholderImg {
      max-height: 368px;
      object-fit: cover;
    }
  }
}
</style>
