<template>
  <div class="deals">
    <div class="text-center w-100 d-sm-none d-block">
      <button
        v-if="GetSubMax < 20"
        @click="show"
        class="btn try-platinum-free-btn"
      >
        Try Platinum Free
      </button>
    </div>
    <Content
      heading="Latest Deals Matching Your Searches"
      :slides="latest"
      :loading="loading"
      refName="heroswiper1"
      swiperClass="swiper1"
      :swiperOption="swiperOption1"
      paginationClass="swiper-pagination1"
      prevClass="swiper-button-prev1"
      nextClass="swiper-button-next1"
      headingAlternate="Latest Deals"
    />
    <Content
      v-if="this.$store.state.subMax == 20"
      heading="Premium Cabin Deals"
      :slides="premium"
      :loading="loading"
      refName="heroswiper4"
      swiperClass="swiper4"
      :swiperOption="swiperOption4"
      paginationClass="swiper-pagination4"
      prevClass="swiper-button-prev4"
      nextClass="swiper-button-next4"
      headingAlternate="Premium Deals"
    >
      <span
        >These deals are in business or first-class and match your
        <router-link
          :to="{ name: 'userprofile', query: { name: 'savedroutes' } }"
          >searches</router-link
        ></span
      >
    </Content>
    <!--For Gold Members -->
    <UpgradePlan
      v-if="this.$store.state.subMax == 10"
      heading="Premium Cabin Deals"
      :slides="upgradePackageArr"
      :loading="loading"
      refName="heroswiper4"
      swiperClass="swiper4"
      :swiperOption="swiperOption4"
      paginationClass="swiper-pagination4"
      prevClass="swiper-button-prev4"
      nextClass="swiper-button-next4"
      headingAlternate="Premium Deals"
    >
      <span
        >These deals are in business or first-class and match your
        <router-link
          :to="{ name: 'userprofile', query: { name: 'savedroutes' } }"
          >searches</router-link
        ></span
      >
    </UpgradePlan>
    <!--  -->
    <Content
      :loading="loading"
      heading="Suggested Deals"
      :slides="suggested"
      refName="heroswiper2"
      swiperClass="swiper2"
      :swiperOption="swiperOption2"
      paginationClass="swiper-pagination2"
      prevClass="swiper-button-prev2"
      nextClass="swiper-button-next2"
      headingAlternate="Suggested Deals"
    >
      <span>These deals are similar to your 
        <router-link
          :to="{ name: 'userprofile', query: { name: 'savedroutes' } }"
          >searches</router-link
        ></span>
    </Content>
    <Content
      :loading="loading"
      heading="Udderly Incredible Deals"
      :slides="all"
      refName="heroswiper3"
      swiperClass="swiper3"
      :swiperOption="swiperOption3"
      paginationClass="swiper-pagination3"
      prevClass="swiper-button-prev3"
      nextClass="swiper-button-next3"
      headingAlternate="Incredible Deals"
    >
      <span>These deals are so good we just have to show them to you</span>
    </Content>
    <modal
      classes="deals__modalContainer"
      name="dealsplanmodal"
      :width="'90%'"
      :height="'650px'"
      :adaptive="true"
    >
      <div
        class="position-relative d-flex justify-content-center p-5 text-center w-100"
      >
        <font-awesome-icon
          class="deals__modal__close"
          @click="hide"
          :icon="['fas', 'times']"
        />

        <div class="text-center text-black bg-white rounded">
          <h3 class="mb-2 fs-1 fw-bold">Platinum</h3>
          <span class="fs-1 fw-bold">$3 per month</span>
          <p class="mt-1">billed monthly after FREE trial</p>
          <div class="my-4 py-2 d-flex justify-content-center">
            <ul class="list-unstyled text-start mb-0">
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>7 Day Free Trial</span>
              </li>
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>10 Saved Flight Searches</span>
              </li>
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>Economy, Business and First-Class Seats</span>
              </li>
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>Domestic and International Flights</span>
              </li>
              <li class="mb-2">
                <svg
                  class="me-2 text-success"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span>Special Deals from Home Airports</span>
              </li>
            </ul>
          </div>
          <div>
            <button
              class="btn btn-primary px-5 d-block mt-4 ml-0 mr-0"
              @click.prevent="selectedPackage('platinum')"
            >
              Select
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { getInstance } from "../auth/authWrapper.js";
import Content from "@/components/deal/Content.vue";
import UpgradePlan from "@/components/deal/UpgradePlan.vue";
export default {
  components: {
    Content,
    UpgradePlan,
  },
  computed: {
    GetSubMax() {
      return this.$store.state.subMax;
    },
  },
  data() {
    return {
      swiperOption1: {
        pagination: {
          el: ".swiper-pagination1",
          clickable: true,
        },

        navigation: {
          nextEl: ".swiper-button-next1",
          prevEl: ".swiper-button-prev1",
        },

        breakpoints: {
          1200: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          575: {
            slidesPerView: 1.15,
            spaceBetween: 5,
          },
          230: {
            slidesPerView: 1.15,
            spaceBetween: 10,
          },
        },
      },
      swiperOption2: {
        pagination: {
          el: ".swiper-pagination2",
          clickable: true,
        },

        navigation: {
          nextEl: ".swiper-button-next2",
          prevEl: ".swiper-button-prev2",
        },

        breakpoints: {
          1200: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          575: {
            slidesPerView: 1.15,
            spaceBetween: 5,
          },
          230: {
            slidesPerView: 1.15,
            spaceBetween: 10,
          },
        },
      },
      swiperOption3: {
        pagination: {
          el: ".swiper-pagination3",
          clickable: true,
        },

        navigation: {
          nextEl: ".swiper-button-next3",
          prevEl: ".swiper-button-prev3",
        },

        breakpoints: {
          1200: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },

          575: {
            slidesPerView: 1.15,
            spaceBetween: 5,
          },
          230: {
            slidesPerView: 1.15,
            spaceBetween: 10,
          },
        },
      },
      swiperOption4: {
        pagination: {
          el: ".swiper-pagination4",
          clickable: true,
        },

        navigation: {
          nextEl: ".swiper-button-next4",
          prevEl: ".swiper-button-prev4",
        },

        breakpoints: {
          1200: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },

          575: {
            slidesPerView: 1.15,
            spaceBetween: 5,
          },
          230: {
            slidesPerView: 1.15,
            spaceBetween: 10,
          },
        },
      },
      latest: [],
      all: [],
      suggested: [],
      premium: [],
      upgradePackageArr: [],
      user: null,
      loading: false,
    };
  },
  created() {
    this.init(this.loadTokenIntoStore);
  },
  methods: {
    selectedPackage(pkg) {
      let data = {
        pkg,
      };
      this.checkout(data);
    },
    async checkout(pkgData) {
      let priceId = process.env.VUE_APP_PRICEID_PLATINUM;

      let data = {
        email: this.$auth.user.email,
        priceId,
        id: this.$auth.user.sub,
        package: pkgData.pkg,
        action: "update",
        stripeid:
          this.$auth.user["https://app.browncowtravel.com/SubStripeCust"],
      };
      const res = await axios.post(
        `${process.env.VUE_APP_PAYMENT_API}/payment`,
        data
      );

      if (res.data.subscriptionId) {
        this.$store.commit("setStripeData", {
          subscriptionId: res.data.subscriptionId,
          clientSecret: res.data.clientSecret ? res.data.clientSecret : "",
        });
        let step = 4;
        this.$store.commit("setStep", step);
        localStorage.setItem("step", step);
        localStorage.setItem("allowedsaves", 10);
        this.$store.commit("SetShowNavLinks", true);
        this.$store.commit("SetSubMax", 20);

        let subMax = this.$auth.user["https://app.browncowtravel.com/SubMax"];

        window.location.href = `/user?name=billing&subMax=${subMax}&subscriptionId=${res.data.subscriptionId}`;
      }
      this.hide();
    },
    init(fn) {
      var instance = getInstance();
      fn(instance);
    },
    async loadTokenIntoStore(instance) {
      await instance.getTokenSilently();
      this.user = encodeURIComponent(this.$auth.user.sub.toString());
      this.getData(this.user);
    },

    async getData(user) {
      try {
        this.loading = true;
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/deals/${user}`
        );
        this.latest = res.data.latest;
        this.suggested = res.data.suggested;
        this.premium = res.data.premium;
        this.upgradePackageArr = res.data.premium;
        this.all = res.data.all;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        throw new Error(error);
      }
    },
    upgradePackage() {
      this.$modal.hide("dealsplanmodal");
    },
    show() {
      console.log("clicked");
      this.$modal.show("dealsplanmodal");
    },
    hide() {
      this.$modal.hide("dealsplanmodal");
    },
  },
};
</script>

<style lang="scss" scoped>
$color1: #ff3647;
$headingColor: #5f4635;
.try-platinum-free-btn {
  background: $color1;
  color: white;
  transition: all 0.5s;
}
.try-platinum-free-btn:hover {
  background: $headingColor;
  color: white;
}
.deals {
  &__modal {
    &__close {
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
      font-size: 1.25rem;
    }

    &__btn {
      color: $headingColor;

      border-color: $headingColor;
    }
  }
}
</style>
