<template>
  <div class="home__section3 container">
    <div class="row justify-content-between align-items-center">
      <div class="col-md-6 col-12 text-start">
        <div class="position-relative">
          <img
            class="img-fluid"
            src="@/assets/images/home/section3img.png"
            alt=""
          />
          <div class="home__section3__card position-absolute">
            <img
              class="img-fluid w-100 home__section3__card__img"
              src="@/assets/images/home/s3mini.png"
              alt=""
            />
            <p class="home__section3__card__title mb-1">Republic of Fiji</p>
            <div class="d-flex align-items-center ms-2">
              <img src="@/assets/home/plan.svg" alt="" />
              <p class="home__section3__card__dest ps-1">From Charlotte, NC</p>
            </div>
            <p class="home__section3__card__months mb-0">Months: May, June</p>
            <div class="d-flex align-items-center justify-content-between">
              <span class="home__section3__card__actualPrice">$347</span>
              <span
                class="home__section3__card__previousPrice text-decoration-line-through"
                >$789</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 mt-md-0 mt-4">
        <div
          class="home__section3__right d-flex d-md-block flex-column align-items-center text-md-start text-center"
        >
          <p class="home__section3__headingSmall">UNLOCK YOUR IMAGINATION</p>
          <h2 class="home__section3__heading my-0 text-capitalize">
            Hit your Favorites <br class="d-sm-block d-none" />
            and Discover <br class="d-sm-block d-none" />
            Some New Ones
          </h2>

          <p class="home__section3__text">
            We'll find the best prices for your upcoming trips AND suggest to
            you some exciting new destinations when we see a great deal.
          </p>
          <button
            class="btn home__section3__btn"
            @click="$router.push('/register?step=1')"
          >
            Sign Up Now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@mixin xs {
  @media (max-width: "575px") {
    @content;
  }
}

@mixin sm {
  @media (max-width: "767px") {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: "991px") {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: "1199px") {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: "1440") {
    @content;
  }
}

@mixin customMin($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

@mixin customMax($screen) {
  @media (max-width: $screen) {
    @content;
  }
}
$headingColor: #2e2e2e;
$primaryColor: #5f4635;
$secondaryColor: #ff3647;
$textColor: #676767;
$whiteColor: #fffafa;
$secondaryLightColor: #f86061;
.home__section3 {
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: "Poppins", sans-serif;
  // @include md {
  //   padding-top: 220px;
  //   padding-bottom: 220px;
  // }
  // @include sm {
  //   padding-top: 140px;
  //   padding-bottom: 140px;
  // }
  &__right {
    padding-left: 110px;
    @include md {
      padding-left: 30px;
    }
    @include md {
      padding-left: 0px;
    }
  }

  &__heading {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    color: $headingColor;
    padding-top: 10px;
    @include lg {
      font-size: 2.3rem;
    }
    @include md {
      font-size: 2.1rem;
    }
    @include xs {
      font-size: 2rem;
    }
  }
  &__heading.alternate {
    color: $secondaryColor;
    padding-top: 0px;
  }
  &__headingSmall {
    font-weight: 500;
    font-size: 1.125rem;
    color: #03a66b;
    margin-bottom: 0;
  }
  &__text {
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    color: $textColor;
    @include xs {
      font-size: 1rem;
    }
  }
  &__btn {
    border-radius: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    text-align: center;
    color: $whiteColor !important;
    width: 201px;
    height: 70px;
    background: $secondaryLightColor;
    border-radius: 12px;
    @include md {
      font-size: 1.15rem;
      width: 180px;
      height: 60px;
    }
    @include sm {
      width: 170px;
    }
  }
  &__btn:hover {
    color: $whiteColor !important;
  }
  &__card {
    width: 204px;
    height: 278px;
    background: #ffffff;
    box-shadow: 0px 60px 100px -10px rgba(86, 0, 0, 0.08);
    border-radius: 12px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    &__img {
      padding: 0px 6px;
      padding-top: 4px;
    }
    &__title {
      font-family: "Inter", sans-serif;
      color: $headingColor;
      font-weight: 700;
      font-size: 1.125rem;
      padding-top: 15px;
      padding-left: 6px;
    }
    &__dest {
      font-family: "Inter", sans-serif;
      color: $headingColor;
      font-size: 1.125rem;
      color: $headingColor;
      margin: 0 !important;
    }
    &__months {
      padding-left: 6px;

      font-family: "Inter", sans-serif;
      font-size: 1.125rem;
      color: $headingColor;
    }
    &__actualPrice {
      font-family: "Inter", sans-serif;
      font-weight: 700;
      font-size: 1.375rem;
      color: $secondaryLightColor;
      padding-left: 20px;
    }
    &__previousPrice {
      font-family: "Inter", sans-serif;
      font-weight: 700;
      font-size: 1.375rem;
      color: $headingColor;
      padding-right: 20px;
    }
  }
}
</style>
