<template>
  <div class="home__section1 container">
    <div class="row justify-content-between align-items-center">
      <div
        class="col-md-6 col-12 order-1 order-md-0 mt-4 mt-md-0 d-flex d-md-block flex-column align-items-center text-md-start text-center"
      >
        <div
          class="d-md-flex home__section1__tag d-none align-items-center justify-content-between mx-md-0 mx-auto"
        >
          <span class="home__section1__tag__text">Pack your bags...</span>
          <img src="@/assets/home/cam.svg" alt="" />
        </div>
        <h2 class="home__section1__heading my-0">
          Udderly sweet <br />
          travel deals
        </h2>
        <h2 class="home__section1__heading alternate my-0">delivered</h2>
        <p class="home__section1__text">
          Tell us where you want to go and when you want to go there. Let us do
          the leg work of searching for the best deal and delivering a booking
          link straight to you! Save up to $100s per flight.
        </p>
        <button
          class="btn home__section1__btn"
          @click="$router.push('/register?step=1')"
        >
          Sign Up Now
        </button>
      </div>

      <div class="col-md-6 col-12 order-0 order-md-1 text-md-end text-center">
        <div
          class="d-md-none home__section1__tag d-flex align-items-center justify-content-between mx-md-0 mx-auto"
        >
          <span class="home__section1__tag__text">Pack your bags...</span>
          <img class="" src="@/assets/home/cam.svg" alt="" />
        </div>
        <img
          class="img-fluid home__section1__cowImg"
          src="@/assets/images/home/cow.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@mixin xs {
  @media (max-width: "575px") {
    @content;
  }
}

@mixin sm {
  @media (max-width: "767px") {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: "991px") {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: "1199px") {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: "1440") {
    @content;
  }
}

@mixin customMin($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

@mixin customMax($screen) {
  @media (max-width: $screen) {
    @content;
  }
}
$headingColor: #2e2e2e;
$primaryColor: #5f4635;
$secondaryColor: #ff3647;
$textColor: #676767;
$whiteColor: #fffafa;
.home__section1 {
  padding-top: 0px;
  padding-bottom: 60px;
  &__tag {
    background: #8e562e;
    border-radius: 0px;
    width: 214px;
    height: 46px;
    color: white;
    padding-left: 21px;
    padding-right: 24px;
  }
  &__heading {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 4.625rem;
    color: $headingColor;
    padding-top: 20px;
    @include lg {
      font-size: 3.625rem;
    }
    @include md {
      font-size: 2.625rem;
    }
    @include xs {
      font-size: 2.225rem;
    }
  }
  &__heading.alternate {
    color: $secondaryColor;
    padding-top: 0px;
  }
  &__text {
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    color: $textColor;
    @include md {
      font-size: 1.025rem;
    }
    @include xs {
      font-size: 1rem;
    }
  }
  &__btn {
    width: 186px;
    height: 70px;
    background: $secondaryColor;
    border-radius: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    text-align: center;
    color: $whiteColor !important;
    @include lg {
      width: 176px;
      height: 60px;
      font-size: 1.15rem;
    }
    @include xs {
      width: 172px;
      height: 50px;
      font-size: 1.05rem;
    }
  }
  &__btn:hover {
    color: $whiteColor !important;
  }
  &__cowImg {
    @include sm {
      max-width: 65%;
      margin: auto;
      margin-top: 30px;
    }
    @include xs {
      max-width: 80%;
    }
  }
}
</style>
