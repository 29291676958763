<template>
  <section class="py-3 py-sm-4">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-5 mx-auto">
          <div class="p-5 bg-light rounded text-center">
            <span class="text-muted">Sign Up</span>
            <h3 class="fw-bold mb-5">Create new account</h3>
            <form action="#">
              <label class="mb-3">
                <small class="text-muted"
                  >By signing up, you agree to our
                  <a href="#">Terms, Data Policy</a> and
                  <a href="#">Cookies Policy</a>.</small
                >
              </label>
              <button
                class="btn btn-secondary py-2 w-100 mb-3 background-color: #8064A2 !important;"
                @click.prevent="signup"
              >
                Sign Up
              </button>
            </form>
            <p class="text-muted">
              <small
                ><span>Already have an account?</span>
                <span class="sign-in-button ms-1" @click.prevent="login"
                  >Sign In</span
                ></small
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    signup() {
      this.$auth.loginWithRedirect({
        screen_hint: "signup",
      });
    },
    login() {
      this.$auth.loginWithRedirect();
      // this.$auth.loginWithRedirect({
      //   redirect_uri: process.env.VUE_APP_REDIRECT_URL,
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-in-button {
  color: rgba(121, 52, 25, 0.74) !important;
  cursor: pointer;
  font-weight: 600;
}
</style>
